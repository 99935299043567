import React, { useState, useRef, useEffect } from "react";
import { Button, Grid, Typography, IconButton, Divider } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import playButton from "../images/Vector (1).png";
import Slider from "react-slick";
import BannerImage from "../images/Banner.png";
import "./recentSlider.css";
import { home_web } from "./homeState";
import { Navigate, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

const CustomPrevArrow = ({ onClick, disabled }) => (
  <IconButton
    onClick={onClick}
    disabled={disabled}
    style={{
      position: "absolute",
      top: "50%",
      left: "-30px",
      zIndex: 1,
      transform: "translateY(-50%)",
      backgroundColor: "transparent",
      color: disabled ? "gray" : "white",
    }}
  >
    <ArrowBackIosIcon style={{ fontSize: "40px" }} />
  </IconButton>
);

const CustomNextArrow = ({ onClick, disabled }) => (
  <IconButton
    onClick={onClick}
    disabled={disabled}
    style={{
      position: "absolute",
      top: "50%",
      right: "-40px",
      zIndex: 1,
      transform: "translateY(-50%)",
      backgroundColor: "transparent",
      color: disabled ? "gray" : "white",
    }}
  >
    <ArrowForwardIosIcon style={{ fontSize: "40px" }} />
  </IconButton>
);

const RecentlyAdded = () => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  useEffect(() => {
    const checkArrows = () => {
      if (!sliderRef.current) return;
      setIsPrevDisabled(sliderRef.current.innerSlider.state.currentSlide === 0);
      setIsNextDisabled(
        sliderRef.current.innerSlider.state.currentSlide >= images.length - 7
      );
    };

    checkArrows();
  }, []);
  const [hoverStates, setHoverStates] = useState({});
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  const handleHover = (index, isHovered) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [index]: isHovered,
    }));
  };

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);
  const [promoImage, setPromoImage] = useState(null);
  const [images, setImages] = useState([]);
  useEffect(() => {
    home_web()
      .then((res) => {
        console.log(res);
        // Assuming the response structure is correct, extract the list of images
        const data = res.data;
        if (data && data.length > 0) {
          const recentSection = data.find(
            (section) => section.title === "Recently Added"
          );

          if (recentSection && recentSection.list) {
            setImages(recentSection.list);
          }
          const promoSection = data.find(
            (section) => section.template_type === "promo_template_2"
          );
          if (promoSection && promoSection.list && promoSection.list.length > 0) {
            setPromoImage(promoSection.list[0]); // Assuming there's only one promo image
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };


  const handleClick = (content_id, content_type_id) => {
    // Construct the query string
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;
    
    // Encrypt the query string
    const encryptedQuery = encryptQueryString(queryString);

    // Navigate to the new page with the encrypted query string
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };

  // const handleClick = (content_id, content_type_id) => {
  //   console.log(content_id, content_type_id, "dataa");
  //   navigate(`/videoPlayer?content_id=${content_id}&content_type_id=${content_type_id}`);
  // };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: (
      <CustomPrevArrow
        onClick={() => sliderRef.current.slickPrev()}
        disabled={isPrevDisabled}
      />
    ),
    nextArrow: (
      <CustomNextArrow
        onClick={() => sliderRef.current.slickNext()}
        disabled={isNextDisabled}
      />
    ),
    beforeChange: (current, next) => {
      setIsPrevDisabled(next === 0);
      setIsNextDisabled(next >= images.length - 7);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div>
      <Grid
        sx={{
          position: "relative",
          paddingTop: "5rem",
          paddingBottom: "5rem",
          paddingLeft: { xs: "2rem", sm: "2rem", md: "10rem" },
          paddingRight: { xs: "2rem", sm: "2rem", md: "10rem" },
        }}
      >
        <div style={{position:"relative"}}>
        <Grid classes="home_button_gradients"></Grid>
        </div>
      

        <Grid classes="recently_gradient"></Grid>

        {/* <Button variant="containedhome1">
          <img
            src={playButton}
            alt="playButton"
            style={{ marginRight: "10px" }}
          />
          Watch
        </Button> */}

        <Grid className="content_gradient_sub"></Grid>
        

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid item xs={12} sm={6} md={6} sx={{paddingBottom:"1.5rem"}}>
            <Typography variant="subTitle1">Recently Added</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            
              <Typography variant="subTitle2"></Typography>
           
          </Grid>
        </Grid>
        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
          }}
        />

        <div className="slider-container">
          <Slider ref={sliderRef} {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  sx={{ padding: "0.5rem", paddingTop: "32px" }}
                >
                  {/* <Grid item xs={12} sx={{
                    borderRadius:"8px"
                  }}>
                    <img
                      // src={image.src}
                      // alt={image.alt}
                      src={image.thumbnail}
                      alt={`Thumbnail ${index}`}
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        position: "relative",
                        height: "313px",
                        cursor:"pointer",
                        borderRadius: "8px",
                        
                      }}
                      onClick={() => handleClick(image.content_id, image.content_type_id)} 
                    />
                  </Grid> */}
                   <div
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      cursor: "pointer",
                      borderRadius: "8px",
                      width: "100%",
                      height: "0",
                      paddingBottom: "150.25%", // This maintains a 16:9 aspect ratio
                    }}
                    onMouseEnter={() => handleHover(index, true)}
                    onMouseLeave={() => handleHover(index, false)}
                    onClick={() =>
                      handleClick(image.content_id, image.content_type_id)
                    }
                  >
                    <img
                      src={image.thumbnail}
                      alt={`Thumbnail ${index}`}
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        objectFit: "cover",
                        transition: "transform 0.3s ease-in-out",
                        transform: hoverStates[index]
                          ? "scale(1.05)"
                          : "scale(1)", // Slight zoom
                      }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "100%", // Full height of the container
                        // background: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%)", // Black gradient
                        background:
                          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 100%)", // Custom gradient

                        color: "white",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end", // Align content to the bottom
                        padding: "0.5rem",
                        opacity: hoverStates[index] ? 1 : 0,
                        transition:
                          "opacity 0.3s ease-in-out, background 0.3s ease-in-out",

                        pointerEvents: "none", // Prevents the overlay from blocking clicks
                        boxSizing: "border-box", // Ensure padding does not affect height calculation
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "8px", // Small screens
                            sm: "8px", // Medium screens
                            md: "8px", // Large screens
                            lg: "12px",
                            xl: "20px",
                          },
                          lineHeight: {
                            xs: "8px", // Small screens
                            sm: "16px", // Medium screens
                            md: "30px", // Large screens
                            lg: "34px",
                            xl: "34px",
                          },

                          color: "white",
                          padding: 0,
                          margin: 0,
                          fontFamily: "inter !important",
                          textOverflow: "ellipsis", // Handle text overflow
                          whiteSpace: "nowrap", // Prevent text wrapping
                          overflow: "hidden", // Hide overflowed text
                        }}
                      >
                        {image.content_title}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "8px", // Small screens
                            sm: "6px", // Medium screens
                            md: "6px", // Large screens
                            lg: "10px",
                            xl: "12px",
                          },
                          lineHeight: {
                            xs: "8px", // Small screens
                            sm: "8px", // Medium screens
                            md: "6px", // Large screens
                            lg: "8px",
                            xl: "20px",
                          },
                          padding: 0,
                          margin: "0",
                          fontFamily: "inter !important",
                          color: "white",
                          textOverflow: "ellipsis", // Handle text overflow
                          whiteSpace: "nowrap", // Prevent text wrapping
                          overflow: "hidden", // Hide overflowed text
                          display: '-webkit-box',          // Enable flexbox for truncation
    WebkitLineClamp: 3,              // Limit to 3 lines
    WebkitBoxOrient: 'vertical',     // Set box orientation
    overflow: 'hidden',    
                        }}
                      >
                        {image.content_description}
                      </Typography>
                    
                    </div>
                  </div>
                </Grid>
              </div>
            ))}
          </Slider>
        </div>
      </Grid>

    
{promoImage && (
        <div
         
          onClick={() =>
            handleClick(promoImage.content_id, promoImage.content_type_id)
          }
        >
          <img
            src={promoImage.promo_poster_web}
            alt="Promo Banner"
            style={{
              width: "100%",
              position: "relative",
              cursor:"pointer",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default RecentlyAdded;
