import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import pause from "../../pages/images/pause frame icon.png";
import forwardIcon from "../../pages/images/forwrad frame icon.png";
import backwardButton from "../../pages/images/Icon Frame.png";
import bigscreenIcon from "../images/bigsize.png";
import SoundOff from "../../pages/images/soundoff.png";
import soundUp from "../../pages/images/soundup.png";
import CustomSnackbar from "../../components/Snackbar/snackbar";
import wishlisttoast from "../../pages/images/wishlisttoast.png";

import screenfull from "screenfull";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import Resize from "../../pages/images/resize.png";
import playButton from "../../pages/images/Vector (1).png";

import Add from "../images/Add.png";
import wishlistAdded from "../../pages/images/wishlist Added.png";
import {
  get_movie_video,
  get_tv_video,
  get_video_data,
} from "./videoPlayerState";
import { Navigate, useLocation, useParams } from "react-router-dom";
import Header from "../../components/layout/header";
import axiosInstance from "../../axiosInstance";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
const secretKey = "your-secret-key"; // Replace with a secure key

// Encrypt the query string
const encryptQueryString = (queryString) => {
  const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
  return encodeURIComponent(encrypted); // Make it URL-safe
};

// Decrypt the query string
const decryptQueryString = (encryptedQuery) => {
  const decrypted = CryptoJS.AES.decrypt(
    decodeURIComponent(encryptedQuery),
    secretKey
  ).toString(CryptoJS.enc.Utf8);
  return decrypted;
};

const VideoPlayer = () => {
  const [playing, setPlaying] = useState(true);
  const [showControls, setShowControls] = useState(false);
  const [played, setPlayed] = useState(0);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const playerRef = useRef(null);
  const videoRef = useRef(null);
  const scrubberRef = useRef(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const containerRef = useRef(null);

  const [seasonId, setSeasonId] = useState(null);
  const [episodeId, setEpisodeId] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const progressBarRef = useRef(null);
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [icon, setIcon] = useState(null);

  const [state, setState] = React.useState({
    open: false,
    vertical: "center", // Center vertically
    horizontal: "center", // Center horizontally
  });
  const { vertical, horizontal, open } = state;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarImage, setSnackbarImage] = React.useState(""); // Dynamic image URL
  const [snackbarIcon, setSnackbarIcon] = useState(null); // For dynamic icon
  const [hovering, setHovering] = useState(false);
  // Create a ref for the video player container

  // Function to scroll to the video player
  // const scrollToVideoPlayer = () => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // }
  const scrollToVideoPlayer = () => {
    if (containerRef.current) {
      const elementPosition =
        containerRef.current.getBoundingClientRect().top + window.pageYOffset; // Get element's position relative to page
      window.scrollTo({
        top: 0, // Scroll to the element's top position
        behavior: "smooth", // Smooth scrolling effect
      });
    }
  };

  const handleClickSnackbar = (message, imgSrc) => {
    setSnackbarMessage(message);
    setSnackbarImage(imgSrc); // Set the dynamic image
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false); // Close the snackbar
  };

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };
  const handleFullscreen = () => {
    const element = containerRef.current;

    // Check if the element exists before trying to make it fullscreen
    if (element) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        // Firefox
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        // Chrome, Safari, and Opera
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        // IE/Edge
        element.msRequestFullscreen();
      }
    } else {
      console.error("Element is null, cannot request fullscreen.");
    }
  };

  const toggleFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(containerRef.current);
    }
  };

  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(screenfull.isFullscreen);
    };

    if (screenfull.isEnabled) {
      screenfull.on("change", onFullscreenChange);
    }

    return () => {
      if (screenfull.isEnabled) {
        screenfull.off("change", onFullscreenChange);
      }
    };
  }, []);
  const handleScrub = (e) => {
    const rect = progressBarRef.current.getBoundingClientRect();
    const posX = e.clientX - rect.left;
    const newPlayed = posX / rect.width;
    playerRef.current.seekTo(newPlayed);
    setPlayed(newPlayed);
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 1) {
      handleScrub(e);
    }
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const handleProgress = (progress) => {
    setPlayed(progress.played);
    setCurrentTime(progress.playedSeconds);
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  const handleVolumeToggle = () => {
    setMuted(!muted);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current) {
        const currentTime = playerRef.current.getCurrentTime();
        const duration = playerRef.current.getDuration();
        setPlayed(currentTime / duration);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // const handleMoreLikeThisClick = () => {
  //   setShowMoreLikeThis(!showMoreLikeThis);
  // };

  const [activeSection, setActiveSection] = useState("episodes");

  const handleSectionToggle = (section) => {
    setActiveSection(section);
  };

  const handlePlayPause = () => {
    setPlaying(!playing);
  };

  const handleFullscreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleScrubberChange = (e) => {
    const scrubberRect = scrubberRef.current.getBoundingClientRect();
    const offsetX = e.clientX - scrubberRect.left;
    const newPlayed = offsetX / scrubberRect.width;
    playerRef.current.seekTo(newPlayed * playerRef.current.getDuration());
  };

  const handleForward = () => {
    const player = playerRef.current;
    if (player) {
      const currentTime = player.getCurrentTime();
      const duration = player.getDuration();
      if (currentTime !== null && duration !== null) {
        const newTime = Math.min(currentTime + 15, duration);
        player.seekTo(newTime);
        console.log("Forward - New Time:", newTime);
      } else {
        console.error("Forward - Current time or duration is null");
      }
    } else {
      console.error("Forward - Player ref is null");
    }
  };

  const handleBackward = () => {
    const player = playerRef.current;
    if (player) {
      const currentTime = player.getCurrentTime();
      if (currentTime !== null) {
        const newTime = Math.max(currentTime - 15, 0);
        player.seekTo(newTime);
        console.log("Backward - New Time:", newTime);
      } else {
        console.error("Backward - Current time is null");
      }
    } else {
      console.error("Backward - Player ref is null");
    }
  };
  useEffect(() => {
    if (playerRef.current) {
      console.log("Player is initialized");
    }
  }, [playerRef.current]);

  const [activeTab, setActiveTab] = useState("Episodes");

  // Handler for tab clicks
  const handleEpisodesClick = () => {
    setActiveTab("Episodes");
    setShowMoreLikeThis(false);
  };

  const [movieVideoUrl, setMovieVideoUrl] = useState("");

  const [movie, setMovie] = useState([]);
  const [episode, setEpisode] = useState([]);

  const handleClick = (url) => {
    console.log("Selected Video URL:", url);
    setMovieVideoUrl(url);
  };

  const [showMoreLikeThis, setShowMoreLikeThis] = useState(false);

  const handleMoreLikeThisClick = () => {
    setShowMoreLikeThis(true);
  };

  const [videoUrl, setVideoUrl] = useState("");
  const location = useLocation();

  useEffect(() => {
    let timeout;
    if (!hovering) {
      // Set a timeout to hide the controls after 10 seconds when not hovering
      timeout = setTimeout(() => {
        setShowControls(false);
      }, 10000); // 10 seconds
    } else {
      // If hovering, always show the controls
      setShowControls(true);
      clearTimeout(timeout); // Clear timeout when hovering again
    }

    // Cleanup function to clear the timeout if the component unmounts or hovering changes
    return () => clearTimeout(timeout);
  }, [hovering]);

  const [moreLikeThis, setMoreLikeThis] = useState([]);
  

  ///////////////////////////////////working one ///////////////////////////////

  const [trailerUrl, setTrailerUrl] = useState(""); // URL for the trailer
  const [mainVideoUrl, setMainVideoUrl] = useState(null); // URL for the main video
  const [isAdded, setIsAdded] = useState(false);
  const [isTrailerPlaying, setIsTrailerPlaying] = useState(true); // New state for trailer control
  const [contentId, setContentId] = useState("");
  const [contentTypeId, setContentTypeId] = useState("");
  const [episodeIdd, setEpisodeIdd] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const encryptedData = query.get("data");

    if (encryptedData) {
      const decryptedQueryString = decryptQueryString(encryptedData);
      const params = new URLSearchParams(decryptedQueryString);

      setContentId(params.get("content_id"));
      setContentTypeId(params.get("content_type_id"));
      setEpisodeIdd(params.get("episode_id"));

      console.log(contentId, contentTypeId, episodeId, "bysgusya");
    }
  }, [location.search]);

  useEffect(() => {
    if (contentId && contentTypeId) {
      const fetchData = async () => {
        try {
          let response;
          if (contentTypeId === "2") {
            console.log("Fetching TV video data...");
            response = await get_tv_video(contentId, contentTypeId, episodeIdd);
          }
          if (contentTypeId === "7") {
            console.log("Fetching TV video data...");
            response = await get_tv_video(contentId, contentTypeId);
          } else if (contentTypeId === "1") {
            console.log("Fetching movie video data...");
            response = await get_movie_video(contentId, contentTypeId);
          }

          console.log("API Response:", response);
          if (!response || !response.data) {
            throw new Error("Invalid response structure");
          }

          const { data } = response;
          const title =
            data?.details?.content_title || data?.details?.series_title;
          const description =
            data?.details?.content_description || data?.seasons?.description;
          const videoDetails = data?.details?.video_details;
          const hlsUrl = videoDetails?.url_hls;
          const trailerUrl = data?.details?.trailer_url;
          const languages = data?.details?.languages_available || [];
          const addWishList = data?.details?.add_wish_list || false; // Extract wishlist status
          console.log("Wishlist Status:", addWishList);

          console.log("Title:", title);
          console.log("Description:", description);
          console.log("HLS URL:", hlsUrl);
          console.log("Trailer URL:", trailerUrl);
          console.log("Languages:", languages);

          setTitle(title);
          setDescription(description);
          setAvailableLanguages(languages);
          setIsAdded(addWishList); // Set wishlist status
          console.log(availableLanguages, "lamguages");
          // Set the video URL based on whether there's a trailer or just the main HLS URL
          if (hlsUrl) {
            console.log("Playing trailer:", hlsUrl);
            setTrailerUrl(hlsUrl);
            setVideoUrl(hlsUrl);
            setHistoryData({
              content_id: contentId,
              season_id: seasonId,
              episode_id: episodeId,
              // total_duration: totalDuration,
            });
            setIsTrailerPlaying(true);
          } else if (hlsUrl) {
            console.log("Playing main video:", hlsUrl);
            setVideoUrl(hlsUrl);
            setIsTrailerPlaying(false);
          }

          // Set main video URL in case we need to switch back from the trailer
          if (trailerUrl) {
            setMainVideoUrl(trailerUrl);
          }

          if (data?.episodes) {
            console.log("Episodes:", data.episodes);
            setEpisode(data.episodes);
            setShowMoreLikeThis(false);
          }

          if (data?.more) {
            console.log("More Like This:", data.more);
            setMoreLikeThis(data.more);
          }
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };

      fetchData();
    }
  }, [contentId, contentTypeId, episodeIdd]);

  // This useEffect is to log any updates to the video URL
  useEffect(() => {
    console.log("Updated Video URL:", videoUrl);
  }, [videoUrl]);

  // When "Watch Trailer" or "Watch" button is clicked
  const handleWatchClick = () => {
    if (!isTrailerPlaying && trailerUrl) {
      setVideoUrl(trailerUrl); // Switch to the trailer URL
      setIsTrailerPlaying(true); // Mark that the trailer is playing
    } else if (isTrailerPlaying && videoUrl) {
      setVideoUrl(mainVideoUrl); // Switch back to the main video
      setIsTrailerPlaying(false); // Mark that the main video is playing
    } else {
      console.error("URL not available for switching");
    }
  };

  const handleVideoClick = (item) => {
    const { content_id, content_type_id, episode_id } = item;
    console.log(item, "its");
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}&episode_id=${episode_id}`;
    const encryptedQuery = encryptQueryString(queryString);
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };

  const handleEpisodeClick = async (item) => {
    console.log(item, "itemss");
    scrollToVideoPlayer(); // Scroll to video player

    const { episode_id, content_id, content_type_id } = item;

    try {
      // Construct the URL with episode_id
      const url = `/videoPlayer?data=${encryptQueryString(
        `content_id=${content_id}&content_type_id=${content_type_id}&episode_id=${episode_id}`
      )}`;

      // Navigate to the new URL
      navigate(url);
      window.location.reload(); // Optional: refresh to load new data
    } catch (err) {
      console.error("Error fetching episode video details:", err);
    }
  };

  // Handle more like this with encryption
  const handleMoreLike = async (item) => {
    const { content_id, content_type_id } = item;

    try {
      const response =
        content_type_id === "2"
          ? await get_tv_video(content_id, content_type_id)
          : await get_movie_video(content_id, content_type_id);

      const videoDetails = response.data?.details?.video_details;
      const hlsUrl = videoDetails?.url_hls;

      if (hlsUrl) {
        setVideoUrl(hlsUrl);
        setIsTrailerPlaying(false); // Ensure it's not playing the trailer
      }

      const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;
      const encryptedQuery = encryptQueryString(queryString);
      const url = `/videoPlayer?data=${encryptedQuery}`;

      // Navigate to the new URL
      navigate(url);
    } catch (err) {
      console.error("Error fetching more like this video details:", err);
    }
  };

  useEffect(() => {
    console.log("Updated Video URL:", videoUrl);
  }, [videoUrl]);

  const [historyData, setHistoryData] = useState(null);

  console.log(videoUrl);

  useEffect(() => {
    scrollToVideoPlayer();
    console.log("Video URL has changed to:", videoUrl);
    // Logic to load or update the video player with new URL
  }, [videoUrl]);

  console.log(videoUrl);

  const handleAddToWishlist = async () => {
    if (!contentId) {
      console.error("Missing contentId");
      return;
    }

    const payload = {
      content_id: contentId,
      ...(seasonId && { season_id: seasonId }),
      ...(episodeIdd && { episode_id: episodeIdd }),
    };

    console.log("Wishlist API payload:", payload);

    try {
      const response = await axiosInstance.post(
        "/api/app_add_wishlist", // The endpoint path relative to the baseURL
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            // Authorization header will be set by axiosInstance interceptor
          },
        }
      );

      console.log("Wishlist API response:", response.data);
      if (response.status === 201) {
        // Update the state to reflect that the item has been added to the wishlist
        setIsAdded(true);
        window.location.reload();

        // Optionally show a snackbar or other notification
        handleClickSnackbar("Wishlist added successfully!", wishlisttoast);
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error);
    }
  };

  // const handleAddToWishlist = async () => {
  //   if (!contentId) {
  //     console.error("Missing contentId");
  //     return;
  //   }

  //   const payload = {
  //     content_id: contentId,
  //     ...(seasonId && { season_id: seasonId }),
  //     ...(episodeId && { episode_id: episodeId }),
  //   };

  //   console.log("Wishlist API payload:", payload);

  //   try {
  //     const response = await axiosInstance.post(
  //       "/api/app_add_wishlist", // The endpoint path relative to the baseURL
  //       payload,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           // Authorization header will be set by axiosInstance interceptor
  //         },
  //       }
  //     );

  //     console.log("Wishlist API response:", response.data);
  //     if (response.status === 201) {
  //     // handleClickSnackbar('Wishlist Added'); // Display Snackbar with message
  //     handleClickSnackbar('Wishlist added successfully!', wishlisttoast);

  //     }
  //     // Optionally handle the response (e.g., show a notification)
  //   } catch (error) {
  //     console.error("Error adding to wishlist:", error);
  //   }
  // };

  useEffect(() => {
    // Logic to initialize or update the video player with the new URL
    const videoElement = document.getElementById("videoPlayer");
    if (videoElement && videoUrl) {
      videoElement.src = videoUrl;
      // videoElement.play();
      videoElement.play().catch((error) => {
        console.error("Error attempting to play:", error);
      });
    }
  }, [videoUrl]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };
  const [currentTime, setCurrentTime] = useState(0); // Current time in seconds
  const [duration, setDuration] = useState(0); // Duration in seconds

  const [availableLanguages, setAvailableLanguages] = useState([]);

  const handleLanguageClick = (contentId, contentTypeId) => {
    console.log("Button clicked", contentId, contentTypeId); // Debugging log
    if (contentId && contentTypeId) {
      // Construct the query string
      const queryString = `content_id=${contentId}&content_type_id=${contentTypeId}`;

      // Encrypt the query string
      const encryptedQuery = encryptQueryString(queryString);

      // Construct the URL with encrypted query
      const url = `/videoPlayer?data=${encryptedQuery}`;

      console.log("Request URL:", url); // Debugging log

      // Navigate to the constructed URL
      window.location.href = url;
    } else {
      console.error("Content ID or Content Type ID is missing");
    }
  };

  // const handleLanguageClick = (contentId, contentTypeId) => {
  //   console.log('Button clicked', contentId, contentTypeId);  // Debugging log
  //   if (contentId && contentTypeId) {
  //     const url = `/videoPlayer?content_id=${contentId}&content_type_id=${contentTypeId}`;
  //     console.log("Request URL:", url);  // Debugging log
  //     window.location.href = url;  // Navigate to the constructed URL
  //   } else {
  //     console.error('Content ID or Content Type ID is missing');
  //   }
  // };

  return (
    <>
      <Grid
        sx={{
          maxWidth: "100vw",
          minHeight: "100vh",
          backgroundColor: "#1b0a28",
          position: "relative",
          overflow: "hidden",

          paddingTop: { xs: "8rem", sm: "12rem", md: "12rem" },

          paddingLeft: { xs: "1rem", sm: "2rem", md: "2rem", lg: "5rem" },
          paddingRight: { xs: "1rem", sm: "2rem", md: "2rem", lg: "5rem" },
        }}
      >
        <Grid classes="home_gradient" />
        <Grid classes="live_bottom_gradient"></Grid>
        <Grid classes="center_gradient" />
        <Grid
          container
          //    spacing={5}
          spacing={{
            xs: 2, // 8px for extra-small screens and up
            sm: 3, // 16px for small screens and up
            md: 3, // 32px for medium screens and up
            lg: 4, // 40px for large screens and up
            xl: 5, // 48px for extra-large screens
          }}
          sx={{
            display: {
              xs: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
          }}
        >
          <Grid
            item
            xs={12}
            md={7}
            sm={12}
            lg={7.8}
            xl={8.8}
            // sx={{ paddingBottom: "5rem" }}
            ref={containerRef}
            style={{
              paddingTop: isFullscreen ? "0px" : "40px",
              paddingBottom: isFullscreen ? "0px" : "5rem",
            }}
          >
            <div
              className="custom-scrollbar"
              style={{
                position: "relative",
                paddingTop: "56.25%", // 16:9 aspect ratio
                borderRadius: "25.271px 25.271px 0px 0px",
                backgroundColor: "#000",
                overflow: "hidden", // Ensure the border radius is applied correctly
              }}
              ref={videoRef}
              onMouseEnter={() => setShowControls(true)}
              onMouseLeave={() => setShowControls(false)}
            >
              {videoUrl ? (
                <ReactPlayer
                  ref={playerRef}
                  url={videoUrl}
                  width="100%"
                  height="100%"
                  playing={playing} // This should be true to autoplay
                  controls={false}
                  volume={muted ? 0 : volume}
                  onProgress={handleProgress}
                  onDuration={handleDuration}
                  onReady={() => console.log("Player is ready")}
                  onError={(error) => console.error("Player error:", error)}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              ) : (
                <p>Loading video...</p>
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: "20px",
                  left: "20px",
                  zIndex: 1,
                  color: "white",
                  fontSize: "16px",
                  lineHeight: "1px",
                  marginBottom: "1rem",
                }}
              >
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
              {showControls && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "67.85px",
                      height: "67.85px",
                      borderRadius: "50%",
                      position: "relative",
                      background: "rgba(0, 0, 0, 0.25)",
                      backdropFilter: "blur(10.76px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      margin: "auto",
                    }}
                    onClick={handleBackward}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        borderRadius: "50%",
                        width: "67.85px",
                        height: "67.85px",
                        top: "0",
                        left: "0",
                        padding: "1.5px",
                        zIndex: "20",
                        background:
                          "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                        borderImageSlice: "1",
                        mixBlendMode: "overlay",
                        WebkitMask:
                          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                        WebkitMaskComposite: "xor",
                        maskComposite: "exclude",
                      }}
                    />
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      onClick={handleBackward}
                    >
                      <img
                        src={backwardButton}
                        alt="backwardButton"
                        style={{
                          width: "50%", // Adjust the size of the image as needed
                          height: "50%", // Ensure the height matches the width
                          objectFit: "contain",
                          color: "white",
                        }}
                        onClick={handleBackward}
                      />
                    </Box>
                  </Box>
                  {playing ? (
                    <Box
                      sx={{
                        width: "110px",
                        height: "110px",
                        borderRadius: "50%",
                        position: "relative",
                        background: "rgba(0, 0, 0, 0.25)",
                        backdropFilter: "blur(10.76px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        margin: "auto",
                        // Ensure the box is centered properly within its parent
                      }}
                      onClick={handlePlayPause}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          borderRadius: "50%",
                          width: "110px",
                          height: "110px",
                          top: "0",
                          left: "0",
                          padding: "1.5px",
                          zIndex: "20",
                          background:
                            "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                          borderImageSlice: "1",
                          mixBlendMode: "overlay",
                          WebkitMask:
                            "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                          WebkitMaskComposite: "xor",
                          maskComposite: "exclude",
                          // Removed WebkitMask and maskComposite as they're not needed
                        }}
                      />
                      <Box
                        sx={{
                          position: "relative",
                          zIndex: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={pause}
                          alt="Pause Icon"
                          style={{
                            width: "50%",
                            height: "50%",
                            objectFit: "contain",
                            color: "white",
                          }}
                          onClick={handlePlayPause}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "110px",
                        height: "110px",
                        borderRadius: "50%",
                        position: "relative",
                        background: "rgba(0, 0, 0, 0.25)",
                        backdropFilter: "blur(10.76px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        margin: "auto",
                        // Ensure the box is centered properly within its parent
                      }}
                      onClick={handlePlayPause}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          borderRadius: "50%",
                          width: "110px",
                          height: "110px",
                          top: "0",
                          left: "0",
                          padding: "1.5px",
                          zIndex: "20",
                          background:
                            "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                          borderImageSlice: "1",
                          mixBlendMode: "overlay",
                          WebkitMask:
                            "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                          WebkitMaskComposite: "xor",
                          maskComposite: "exclude",
                          // Removed WebkitMask and maskComposite as they're not needed
                        }}
                      />
                      <Box
                        sx={{
                          position: "relative",
                          zIndex: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <PlayArrowIcon
                          fontSize="large"
                          style={{
                            fontSize: "4rem",
                            color: "white",
                            cursor: "pointer",
                            margin: "0 15px",
                          }}
                          onClick={handlePlayPause}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: "67.85px",
                      height: "67.85px",
                      borderRadius: "50%",
                      position: "relative",
                      background: "rgba(0, 0, 0, 0.25)",
                      backdropFilter: "blur(10.76px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      margin: "auto",
                    }}
                    onClick={handleForward}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        borderRadius: "50%",
                        width: "67.85px",
                        height: "67.85px",
                        top: "0",
                        left: "0",
                        padding: "1.5px",
                        zIndex: "20",
                        background:
                          "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                        borderImageSlice: "1",
                        mixBlendMode: "overlay",
                        WebkitMask:
                          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                        WebkitMaskComposite: "xor",
                        maskComposite: "exclude",
                      }}
                    />
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={forwardIcon}
                        alt="forwardIcon"
                        style={{
                          width: "50%", // Adjust the size of the image as needed
                          height: "50%", // Ensure the height matches the width
                          objectFit: "contain",
                          color: "white",
                        }}
                      />
                    </Box>
                  </Box>
                </div>
              )}

              <div
                style={{
                  position: "absolute",
                  bottom: "20px",
                  right: "20px",
                  zIndex: 1,
                }}
              >
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "white",
                  }}
                  onClick={handleVolumeToggle}
                >
                  {muted ? (
                    <img src={SoundOff} alt="SoundOff" fontSize="large" />
                  ) : (
                    <img src={soundUp} alt="soundUp" fontSize="large" />
                  )}
                </button>
              </div>

              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "20px",
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    width: "43.85px",
                    height: "43.85px",
                    borderRadius: "50%",
                    position: "relative",
                    background: "rgba(0, 0, 0, 0.25)",
                    // backdropFilter: 'blur(10.76px)',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    margin: "auto",
                    cursor: "pointer",
                  }}
                  onClick={handleFullscreen}
                  // onClick={toggleFullscreen}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      borderRadius: "50%",
                      width: "43.85px",
                      height: "43.85px",
                      top: "0",
                      left: "0",
                      padding: "1.5px",
                      zIndex: "20",
                      background:
                        "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                      cursor: "pointer",
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                  >
                    {/* <button
     onClick={handleFullscreen}
     style={{
       background: "none",
       border: "none",
       cursor: "pointer",
     }}
   > */}
                    <img
                      // src={bigscreenIcon}
                      // alt="bigscreenIcon"

                      src={isFullscreen ? Resize : bigscreenIcon}
                      alt={isFullscreen ? "Resize" : "bigscreenIcon"}
                      fontSize="large"
                      style={{ color: "white" }}
                    />
                    {/* </button> */}
                  </Box>
                </Box>
              </div>
            </div>

            <div
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              <div
                ref={progressBarRef}
                onClick={handleScrub}
                onMouseMove={handleMouseMove}
                style={{
                  position: "absolute",
                  bottom: "0", // Aligns the progress bar at the bottom
                  left: "0",
                  width: "100%",
                  height: "6.43px",
                  borderRadius: "25.27px",
                  background: "#F9F9F980",
                  cursor: "pointer",
                  zIndex: 10, // Ensures it stays above the video element
                  // Ensure the progress bar is visible in both full screen and normal modes
                  ...(isFullscreen
                    ? {
                        height: "10px", // Adjust the height for fullscreen if needed
                      }
                    : {}),
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: 0,
                    height: "100%",
                    width: `${played * 100}%`,
                    background: "#E650FF",
                    borderRadius: "25.27px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "-11px", // Position the scrubber above the progress bar
                    left: `${played * 100}%`,
                    transform: "translate(-50%, 0)",
                    width: "29.46px",
                    height: "29.46px",
                    borderRadius: "31.01px",
                    border: "2.33px solid #F9F9F9",
                    background: "#F9F9F9",
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                />
              </div>
            </div>

            {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <div style={{ paddingTop: "5rem", position: "relative" }}>
              <Grid
                container
                alignItems="center"
                spacing={5}
                sx={{ position: "relative" }}
              >
                <Grid item sx={{ position: "relative" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "31.65px",
                      fontWeight: 700,
                      lineHeight: "38.3px",
                      letterSpacing: "-0.47349244356155396px",
                      textAlign: "left",
                      position: "relative",
                      color: "white",
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="left"
                spacing={3}
                sx={{ marginTop: "0.5rem" }}
              >
                <button
                  style={{
                    display: "flex",
                    padding: "7.576px 11.364px",
                    // flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "18.94px",
                    borderRadius: "41.76px",
                    background: "#6927C2", // Keep background highlighted for the current language
                    color: "#FFFFFF",
                    fontFamily: "Inter",
                    fontSize: "22.73px",
                    fontWeight: 400,
                    lineHeight: "27.51px",
                    textAlign: "left",
                    border: "none",
                    marginTop: "1.5rem",
                    width: "140px",
                    height: "43.15px",
                    cursor: "not-allowed", // No click event for the current language
                  }}
                  disabled
                >
                  Languages
                  {/* Highlight only "Original" in a different color */}
                </button>
                {availableLanguages.map((lang) => (
                  <Grid item key={lang.language_id}>
                    {lang.current_language ? (
                      <button
                        style={{
                          display: "flex",
                          padding: "7.576px 11.364px",
                          // flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "18.94px",
                          borderRadius: "41.76px",
                          background: "#F4F4F447", // Keep background highlighted for the current language
                          color: "#FFFFFF",
                          fontFamily: "Inter",
                          fontSize: "22.73px",
                          fontWeight: 400,
                          lineHeight: "27.51px",
                          textAlign: "left",
                          border: "none",
                          cursor: "not-allowed", // No click event for the current language
                        }}
                        disabled
                      >
                        {/* Highlight only "Original" in a different color */}
                        {lang.language}{" "}
                        {lang.original_language && (
                          <span
                            style={{
                              color: "#fff",
                              fontWeight: 400,
                              fontSize: "22.73px",
                            }}
                          >
                            (Original)
                          </span>
                        )}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          console.log(
                            "Language button clicked for:",
                            lang.language
                          ); // Debugging log
                          handleLanguageClick(lang.content_id, contentTypeId); // Pass contentId and contentTypeId to handleLanguageClick
                        }}
                        style={{
                          display: "flex",
                          padding: "7.576px 11.364px",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "18.94px",
                          borderRadius: "15.152px",
                          background: "rgba(244, 244, 244, 0.12)", // Keep this background for non-current languages
                          color: "#FFFFFF", // White text for all buttons
                          fontFamily: "Inter",
                          fontSize: "22.73px",
                          fontWeight: 400,
                          lineHeight: "27.51px",
                          textAlign: "left",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        {/* Show language and highlight "Original" text if applicable */}
                        {lang.language}{" "}
                        {lang.original_language && (
                          <span
                            style={{ color: "#FFD700", fontWeight: "bold" }}
                          >
                            (Original)
                          </span>
                        )}
                      </button>
                    )}
                  </Grid>
                ))}
              </Grid>

              {/* Watch Button and AddMore Section */}
              <Grid
                container
                alignItems="center"
                spacing={3}
                sx={{ marginTop: "2rem" }}
              >
                <Grid item sx={{ position: "relative" }}>
                  <Button
                    sx={{
                      display: {
                        xs: "none",
                        sm: "flex",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                      },
                    }}
                    variant="contained3"
                    onClick={handleWatchClick}
                  >
                    <img
                      src={playButton}
                      alt="playButton"
                      style={{ marginRight: "10px" }}
                    />
                    {/* Dynamically change the button text based on what is currently playing */}
                    {isTrailerPlaying ? "Trailer" : "Watch"}
                  </Button>
                </Grid>

                {/* Space between the Watch button and + icon */}
                <Grid item sx={{ marginLeft: "2rem" }}>
                  <Grid container direction="column" alignItems="center">
                    {/* <Grid item>
                      <img
                        src={Add}
                        alt="Add Wish List "
                        onClick={handleAddToWishlist}
                        style={{
                          cursor: "pointer"
                        }}
                      />
                    </Grid> */}
                    {/* <Grid item>
                      <img
                        src={wishlistAdded}
                        alt="Add Wish List"
                        onClick={wishlistAdded}
                        style={{
                          cursor: "pointer"
                        }}
                      />
                    </Grid> */}

                    <Grid item>
                      {isAdded ? (
                        <img
                          src={Add}
                          alt="Add Wish List"
                          onClick={handleAddToWishlist}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <img
                          src={wishlistAdded}
                          alt="Wish List Added"
                          style={{ cursor: "default" }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/* Truncated Text Section */}
                <Grid container sx={{ marginTop: "2rem" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      color: "white",
                    }}
                  >
                    {description}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>

          {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          <Grid item xs={12} md={5} sm={7} lg={4.2} xl={3.2}>
            <Divider
              sx={{
                width: "126.55px",
                height: "9.04px",
                background: "#D226A2",
                marginBottom: "1rem",
                position: "relative",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {episode.length > 0 && (
                <Typography
                  onClick={handleEpisodesClick}
                  sx={{
                    color: showMoreLikeThis ? "#737373" : "#E6E6E6",
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontWeight: "700",
                    letterSpacing: "-0.565px",
                    paddingTop: "1rem",
                    cursor: "pointer",
                    textDecoration: "none", // Remove underline
                  }}
                >
                  Episodes
                </Typography>
              )}
              <Typography
                onClick={episode.length > 0 ? handleMoreLikeThisClick : null}
                sx={{
                  color:
                    episode.length > 0
                      ? showMoreLikeThis
                        ? "#E6E6E6"
                        : "#737373"
                      : "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: "22px",
                  fontWeight: "700",
                  letterSpacing: "-0.565px",
                  paddingTop: "1rem",
                  cursor: episode.length > 0 ? "pointer" : "default",
                  textDecoration: "none", // Remove underline
                }}
              >
                More Like This
              </Typography>
            </Box>

            {!showMoreLikeThis && episode.length > 0 ? (
              <>
                {episode.length > 0 && (
                  <Box
                    sx={{
                      position: "relative",
                      paddingTop: "2.1rem",
                      // maxHeight: "100vh", // Set max height for scrollable area
                    }}
                  >
                    {episode.map((item) => (
                      <Box
                        key={item.content_id}
                        onClick={() => {
                          console.log(item, "eddd"); // Logs the item
                          handleEpisodeClick(item);
                        }}
                        sx={{
                          width: "100%",
                          height: "auto",
                          display: "flex",
                          gap: "9.412px",
                          borderRadius: "9.412px",
                          position: "relative",
                          marginBottom: "1rem",
                          overflow: "hidden",
                          Width: "184.71px",
                          Height: "104.71px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={item.thumbnail}
                          alt={item.content_title}
                          style={{
                            display: "flex",
                            width: "184.71px",
                            height: "104.71px",
                            objectFit: "cover",
                          }}
                        />
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#FFF",
                              fontFamily: "Inter",
                              fontSize: {
                                xs: "0.65rem",
                                md: "0.75rem",
                                lg: "14px",
                                xl: "15.508px",
                              },
                              fontWeight: 600,
                              lineHeight: "150%",
                              paddingBottom: "0",
                              paddingTop: "0.5rem",
                            }}
                          >
                            {item.episode_title}
                            <br />
                          </Typography>
                          {/* <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: {
                                xs: "0.65rem",
                                md: "0.75rem",
                                lg: "12px",
                                xl: "14px",
                              },
                              fontWeight: 500,
                              lineHeight: "21.86px",
                              letterSpacing: "-0.578px",
                              textAlign: "left",
                              color: "rgba(140, 140, 140, 1)",
                              padding: "0",
                            }}
                          >
                            {item.duration}
                          </Typography> */}
                        </Grid>
                        <Button
                          sx={{
                            width: "auto",
                            height: "auto",
                            minWidth: "59.64px",
                            padding: "4.627px 15.038px",
                            position: "absolute",
                            bottom: "2.412px",
                            right: "9.412px",
                            background: "rgba(101, 76, 117, 1)",
                            color: "white",
                            textTransform: "initial",
                            fontFamily: "Inter",
                            fontSize: { xs: "0.65rem", md: "0.75rem" },
                            borderRadius: "4.627px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          Duration {item.duration}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ position: "relative", paddingTop: "2.1rem" }}>
                {moreLikeThis &&
                  moreLikeThis.length > 0 &&
                  moreLikeThis.map((item) => (
                    <Box
                      key={item.content_id}
                      onClick={() => {
                        handleMoreLike(item); // Call the handleVideoClick function with the item
                        console.log(item, "itemss"); // Log the item to the console when clicked
                      }}
                      sx={{
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        gap: "9.412px",
                        borderRadius: "9.412px",
                        position: "relative",
                        marginBottom: "1rem",
                        overflow: "hidden",
                        Width: "184.71px",
                        Height: "104.71px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={item.thumbnail}
                        alt={item.content_title}
                        style={{
                          display: "flex",
                          width: "184.71px",
                          height: "104.71px",
                          objectFit: "cover",
                        }}
                      />
                      <Grid item>
                        <Typography
                          sx={{
                            color: "#FFF",
                            fontFamily: "Inter",
                            fontSize: {
                              xs: "0.65rem",
                              md: "0.75rem",
                              lg: "14px",
                              xl: "15.508px",
                            },
                            fontWeight: 600,
                            lineHeight: "150%",
                            paddingBottom: "0",
                            paddingTop: "0.5rem",
                          }}
                        >
                          {item.content_title}
                          <br />
                        </Typography>
                        {/* <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: {
                              xs: "0.65rem",
                              md: "0.75rem",
                              lg: "12px",
                              xl: "14px",
                            },
                            fontWeight: 500,
                            lineHeight: "21.86px",
                            letterSpacing: "-0.578px",
                            textAlign: "left",
                            color: "rgba(140, 140, 140, 1)",
                            padding: "0",
                          }}
                        >
                          {item.duration}
                        </Typography> */}
                      </Grid>
                      <Button
                        sx={{
                          width: "auto",
                          height: "auto",
                          minWidth: "59.64px",
                          padding: "4.627px 15.038px",
                          position: "absolute",
                          bottom: "2.412px",
                          right: "9.412px",
                          background: "rgba(101, 76, 117, 1)",
                          color: "white",
                          textTransform: "initial",
                          fontFamily: "Inter",
                          fontSize: { xs: "0.65rem", md: "0.75rem" },
                          borderRadius: "4.627px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Duration {item.duration}
                      </Button>
                    </Box>
                  ))}
              </Box>
            )}
          </Grid>
        </Grid>
        {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        <Grid
          container
          spacing={5}
          sx={{
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <Grid item xs={12} md={9}>
          <div
              className="custom-scrollbar"
              style={{
                position: "relative",
                paddingTop: "56.25%", // 16:9 aspect ratio
                borderRadius: "25.271px 25.271px 0px 0px",
                backgroundColor: "#000",
                overflow: "hidden", // Ensure the border radius is applied correctly
              }}
              ref={videoRef}
              onMouseEnter={() => setShowControls(true)}
              onMouseLeave={() => setShowControls(false)}
            >
              {videoUrl ? (
                <ReactPlayer
                  ref={playerRef}
                  url={videoUrl}
                  width="100%"
                  height="100%"
                  playing={playing} // This should be true to autoplay
                  controls={false}
                  volume={muted ? 0 : volume}
                  onProgress={handleProgress}
                  onDuration={handleDuration}
                  onReady={() => console.log("Player is ready")}
                  onError={(error) => console.error("Player error:", error)}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                />
              ) : (
                <p>Loading video...</p>
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: "20px",
                  left: "20px",
                  zIndex: 1,
                  color: "white",
                  fontSize: "16px",
                  lineHeight: "1px",
                  marginBottom: "1rem",
                }}
              >
                {formatTime(currentTime)} / {formatTime(duration)}
              </div>
              {showControls && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1,
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      width: "67.85px",
                      height: "67.85px",
                      borderRadius: "50%",
                      position: "relative",
                      background: "rgba(0, 0, 0, 0.25)",
                      backdropFilter: "blur(10.76px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      margin: "auto",
                    }}
                    onClick={handleBackward}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        borderRadius: "50%",
                        width: "67.85px",
                        height: "67.85px",
                        top: "0",
                        left: "0",
                        padding: "1.5px",
                        zIndex: "20",
                        background:
                          "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                        borderImageSlice: "1",
                        mixBlendMode: "overlay",
                        WebkitMask:
                          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                        WebkitMaskComposite: "xor",
                        maskComposite: "exclude",
                      }}
                    />
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                      onClick={handleBackward}
                    >
                      <img
                        src={backwardButton}
                        alt="backwardButton"
                        style={{
                          width: "50%", // Adjust the size of the image as needed
                          height: "50%", // Ensure the height matches the width
                          objectFit: "contain",
                          color: "white",
                        }}
                        onClick={handleBackward}
                      />
                    </Box>
                  </Box>
                  {playing ? (
                    <Box
                      sx={{
                        width: "110px",
                        height: "110px",
                        borderRadius: "50%",
                        position: "relative",
                        background: "rgba(0, 0, 0, 0.25)",
                        backdropFilter: "blur(10.76px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        margin: "auto",
                        // Ensure the box is centered properly within its parent
                      }}
                      onClick={handlePlayPause}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          borderRadius: "50%",
                          width: "110px",
                          height: "110px",
                          top: "0",
                          left: "0",
                          padding: "1.5px",
                          zIndex: "20",
                          background:
                            "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                          borderImageSlice: "1",
                          mixBlendMode: "overlay",
                          WebkitMask:
                            "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                          WebkitMaskComposite: "xor",
                          maskComposite: "exclude",
                          // Removed WebkitMask and maskComposite as they're not needed
                        }}
                      />
                      <Box
                        sx={{
                          position: "relative",
                          zIndex: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <img
                          src={pause}
                          alt="Pause Icon"
                          style={{
                            width: "50%",
                            height: "50%",
                            objectFit: "contain",
                            color: "white",
                          }}
                          onClick={handlePlayPause}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: "110px",
                        height: "110px",
                        borderRadius: "50%",
                        position: "relative",
                        background: "rgba(0, 0, 0, 0.25)",
                        backdropFilter: "blur(10.76px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        margin: "auto",
                        // Ensure the box is centered properly within its parent
                      }}
                      onClick={handlePlayPause}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          borderRadius: "50%",
                          width: "110px",
                          height: "110px",
                          top: "0",
                          left: "0",
                          padding: "1.5px",
                          zIndex: "20",
                          background:
                            "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                          borderImageSlice: "1",
                          mixBlendMode: "overlay",
                          WebkitMask:
                            "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                          WebkitMaskComposite: "xor",
                          maskComposite: "exclude",
                          // Removed WebkitMask and maskComposite as they're not needed
                        }}
                      />
                      <Box
                        sx={{
                          position: "relative",
                          zIndex: 30,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <PlayArrowIcon
                          fontSize="large"
                          style={{
                            fontSize: "4rem",
                            color: "white",
                            cursor: "pointer",
                            margin: "0 15px",
                          }}
                          onClick={handlePlayPause}
                        />
                      </Box>
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: "67.85px",
                      height: "67.85px",
                      borderRadius: "50%",
                      position: "relative",
                      background: "rgba(0, 0, 0, 0.25)",
                      backdropFilter: "blur(10.76px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      margin: "auto",
                    }}
                    onClick={handleForward}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        borderRadius: "50%",
                        width: "67.85px",
                        height: "67.85px",
                        top: "0",
                        left: "0",
                        padding: "1.5px",
                        zIndex: "20",
                        background:
                          "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                        borderImageSlice: "1",
                        mixBlendMode: "overlay",
                        WebkitMask:
                          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                        WebkitMaskComposite: "xor",
                        maskComposite: "exclude",
                      }}
                    />
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 30,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={forwardIcon}
                        alt="forwardIcon"
                        style={{
                          width: "50%", // Adjust the size of the image as needed
                          height: "50%", // Ensure the height matches the width
                          objectFit: "contain",
                          color: "white",
                        }}
                      />
                    </Box>
                  </Box>
                </div>
              )}

              <div
                style={{
                  position: "absolute",
                  bottom: "20px",
                  right: "20px",
                  zIndex: 1,
                }}
              >
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    color: "white",
                  }}
                  onClick={handleVolumeToggle}
                >
                  {muted ? (
                    <img src={SoundOff} alt="SoundOff" fontSize="large" />
                  ) : (
                    <img src={soundUp} alt="soundUp" fontSize="large" />
                  )}
                </button>
              </div>

              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "20px",
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    width: "43.85px",
                    height: "43.85px",
                    borderRadius: "50%",
                    position: "relative",
                    background: "rgba(0, 0, 0, 0.25)",
                    // backdropFilter: 'blur(10.76px)',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    margin: "auto",
                    cursor: "pointer",
                  }}
                  onClick={handleFullscreen}
                  // onClick={toggleFullscreen}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      borderRadius: "50%",
                      width: "43.85px",
                      height: "43.85px",
                      top: "0",
                      left: "0",
                      padding: "1.5px",
                      zIndex: "20",
                      background:
                        "linear-gradient(202.36deg, rgba(255, 255, 255, 0.2) 8.26%, rgba(255, 255, 255, 0) 85.43%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                      cursor: "pointer",
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 30,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      cursor: "pointer",
                    }}
                  >
                    {/* <button
     onClick={handleFullscreen}
     style={{
       background: "none",
       border: "none",
       cursor: "pointer",
     }}
   > */}
                    <img
                      // src={bigscreenIcon}
                      // alt="bigscreenIcon"

                      src={isFullscreen ? Resize : bigscreenIcon}
                      alt={isFullscreen ? "Resize" : "bigscreenIcon"}
                      fontSize="large"
                      style={{ color: "white" }}
                    />
                    {/* </button> */}
                  </Box>
                </Box>
              </div>
            </div>

            <div
              style={{
                position: "relative",
                width: "100%",
              }}
            >
              <div
                ref={progressBarRef}
                onClick={handleScrub}
                onMouseMove={handleMouseMove}
                style={{
                  position: "absolute",
                  bottom: "0", // Aligns the progress bar at the bottom
                  left: "0",
                  width: "100%",
                  height: "6.43px",
                  borderRadius: "25.27px",
                  background: "#F9F9F980",
                  cursor: "pointer",
                  zIndex: 10, // Ensures it stays above the video element
                  // Ensure the progress bar is visible in both full screen and normal modes
                  ...(isFullscreen
                    ? {
                        height: "10px", // Adjust the height for fullscreen if needed
                      }
                    : {}),
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: 0,
                    height: "100%",
                    width: `${played * 100}%`,
                    background: "#E650FF",
                    borderRadius: "25.27px",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "-11px", // Position the scrubber above the progress bar
                    left: `${played * 100}%`,
                    transform: "translate(-50%, 0)",
                    width: "29.46px",
                    height: "29.46px",
                    borderRadius: "31.01px",
                    border: "2.33px solid #F9F9F9",
                    background: "#F9F9F9",
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                />
              </div>
            </div>
            <div style={{ paddingTop: "5rem", position: "relative" }}>
              <Grid
                container
                alignItems="center"
                spacing={5}
                sx={{ position: "relative" }}
              >
                <Grid item sx={{ position: "relative" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "31.65px",
                      fontWeight: 700,
                      lineHeight: "38.3px",
                      letterSpacing: "-0.47349244356155396px",
                      textAlign: "left",
                      position: "relative",
                      color: "white",
                    }}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent="left"
                spacing={3}
                sx={{ marginTop: "0.5rem" }}
              >
                <button
                  style={{
                    display: "flex",
                    padding: "7.576px 11.364px",
                    // flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "18.94px",
                    borderRadius: "41.76px",
                    background: "#6927C2", // Keep background highlighted for the current language
                    color: "#FFFFFF",
                    fontFamily: "Inter",
                    fontSize: "22.73px",
                    fontWeight: 400,
                    lineHeight: "27.51px",
                    textAlign: "left",
                    border: "none",
                    marginTop: "1.5rem",
                    width: "140px",
                    height: "43.15px",
                    cursor: "not-allowed", // No click event for the current language
                  }}
                  disabled
                >
                  Languages
                  {/* Highlight only "Original" in a different color */}
                </button>
                {availableLanguages.map((lang) => (
                  <Grid item key={lang.language_id}>
                    {lang.current_language ? (
                      <button
                        style={{
                          display: "flex",
                          padding: "7.576px 11.364px",
                          // flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "18.94px",
                          borderRadius: "41.76px",
                          background: "#F4F4F447", // Keep background highlighted for the current language
                          color: "#FFFFFF",
                          fontFamily: "Inter",
                          fontSize: "22.73px",
                          fontWeight: 400,
                          lineHeight: "27.51px",
                          textAlign: "left",
                          border: "none",
                          cursor: "not-allowed", // No click event for the current language
                        }}
                        disabled
                      >
                        {/* Highlight only "Original" in a different color */}
                        {lang.language}{" "}
                        {lang.original_language && (
                          <span
                            style={{
                              color: "#fff",
                              fontWeight: 400,
                              fontSize: "22.73px",
                            }}
                          >
                            (Original)
                          </span>
                        )}
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          console.log(
                            "Language button clicked for:",
                            lang.language
                          ); // Debugging log
                          handleLanguageClick(lang.content_id, contentTypeId); // Pass contentId and contentTypeId to handleLanguageClick
                        }}
                        style={{
                          display: "flex",
                          padding: "7.576px 11.364px",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "18.94px",
                          borderRadius: "15.152px",
                          background: "rgba(244, 244, 244, 0.12)", // Keep this background for non-current languages
                          color: "#FFFFFF", // White text for all buttons
                          fontFamily: "Inter",
                          fontSize: "22.73px",
                          fontWeight: 400,
                          lineHeight: "27.51px",
                          textAlign: "left",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        {/* Show language and highlight "Original" text if applicable */}
                        {lang.language}{" "}
                        {lang.original_language && (
                          <span
                            style={{ color: "#FFD700", fontWeight: "bold" }}
                          >
                            (Original)
                          </span>
                        )}
                      </button>
                    )}
                  </Grid>
                ))}
              </Grid>

              {/* Watch Button and AddMore Section */}
              <Grid
                container
                alignItems="center"
                spacing={3}
                sx={{ marginTop: "2rem" }}
              >
                <Grid item sx={{ position: "relative" }}>
                  <Button
                    sx={{
                      display: {
                        xs: "none",
                        sm: "flex",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                      },
                    }}
                    variant="contained3"
                    onClick={handleWatchClick}
                  >
                    <img
                      src={playButton}
                      alt="playButton"
                      style={{ marginRight: "10px" }}
                    />
                    {/* Dynamically change the button text based on what is currently playing */}
                    {isTrailerPlaying ? "Trailer" : "Watch"}
                  </Button>
                </Grid>

                {/* Space between the Watch button and + icon */}
                <Grid item sx={{ marginLeft: "2rem" }}>
                  <Grid container direction="column" alignItems="center">
                    {/* <Grid item>
                      <img
                        src={Add}
                        alt="Add Wish List "
                        onClick={handleAddToWishlist}
                        style={{
                          cursor: "pointer"
                        }}
                      />
                    </Grid> */}
                    {/* <Grid item>
                      <img
                        src={wishlistAdded}
                        alt="Add Wish List"
                        onClick={wishlistAdded}
                        style={{
                          cursor: "pointer"
                        }}
                      />
                    </Grid> */}

                    <Grid item>
                      {isAdded ? (
                        <img
                          src={Add}
                          alt="Add Wish List"
                          onClick={handleAddToWishlist}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <img
                          src={wishlistAdded}
                          alt="Wish List Added"
                          style={{ cursor: "default" }}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {/* Truncated Text Section */}
                <Grid container sx={{ marginTop: "2rem" }}>
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "18px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      color: "white",
                      paddingLeft:"40px"
                    }}
                  >
                    {description}
                  </Typography>
                </Grid>
              </Grid>
            </div>
            {/* <Grid
              container
              justifyContent="left"
              spacing={3}
              sx={{ marginTop: "0.5rem" }}
            >
              {[
                "Languages",
                "Tamil (original)",
                "Hindi",
                "Telugu",
                "Kannada",
              ].map((language, index) => (
                <Grid item key={language}>
                  <button
                    style={{
                      display: "flex",
                      padding: "7.576px 11.364px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "18.94px",
                      borderRadius: "15.152px",
                      background:
                        index === 0 ? "#6927C2" : "rgba(244, 244, 244, 0.12)", // Specific style for the first button
                      color: "#FFFFFF", // White text for all buttons
                      fontFamily: "Inter",
                      fontSize: "22.73px",
                      fontWeight: 400,
                      lineHeight: "27.51px",
                      textAlign: "left",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    {language}
                  </button>
                </Grid>
              ))}
            </Grid> */}
           <Grid
                container
                alignItems="center"
                spacing={3}
                sx={{ marginTop: "2rem" }}
              >
                <Grid item sx={{ position: "relative" }}>
                  <Button
                    sx={{
                      display: {
                        xs: "flex",
                        sm: "flex",
                        md: "flex",
                        lg: "flex",
                        xl: "flex",
                      },
                    }}
                    variant="contained3"
                    onClick={handleWatchClick}
                  >
                    <img
                      src={playButton}
                      alt="playButton"
                      style={{ marginRight: "10px" }}
                    />
                    {/* Dynamically change the button text based on what is currently playing */}
                    {isTrailerPlaying ? "Trailer" : "Watch"}
                  </Button>
                </Grid>

                {/* Space between the Watch button and + icon */}
              
                {/* Truncated Text Section */}
             
              </Grid>
          </Grid>

          <Grid item xs={12} md={3}>
          <Divider
              sx={{
                width: "126.55px",
                height: "9.04px",
                background: "#D226A2",
                marginBottom: "1rem",
                position: "relative",
              }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {episode.length > 0 && (
                <Typography
                  onClick={handleEpisodesClick}
                  sx={{
                    color: showMoreLikeThis ? "#737373" : "#E6E6E6",
                    fontFamily: "Inter",
                    fontSize: "22px",
                    fontWeight: "700",
                    letterSpacing: "-0.565px",
                    paddingTop: "1rem",
                    cursor: "pointer",
                    textDecoration: "none", // Remove underline
                  }}
                >
                  Episodes
                </Typography>
              )}
              <Typography
                onClick={episode.length > 0 ? handleMoreLikeThisClick : null}
                sx={{
                  color:
                    episode.length > 0
                      ? showMoreLikeThis
                        ? "#E6E6E6"
                        : "#737373"
                      : "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: "22px",
                  fontWeight: "700",
                  letterSpacing: "-0.565px",
                  paddingTop: "1rem",
                  cursor: episode.length > 0 ? "pointer" : "default",
                  textDecoration: "none", // Remove underline
                }}
              >
                More Like This
              </Typography>
            </Box>

            {!showMoreLikeThis && episode.length > 0 ? (
              <>
                {episode.length > 0 && (
                  <Box
                    sx={{
                      position: "relative",
                      paddingTop: "2.1rem",
                      // maxHeight: "100vh", // Set max height for scrollable area
                    }}
                  >
                    {episode.map((item) => (
                      <Box
                        key={item.content_id}
                        onClick={() => {
                          console.log(item, "eddd"); // Logs the item
                          handleEpisodeClick(item);
                        }}
                        sx={{
                          width: "100%",
                          height: "auto",
                          display: "flex",
                          gap: "9.412px",
                          borderRadius: "9.412px",
                          position: "relative",
                          marginBottom: "1rem",
                          overflow: "hidden",
                          Width: "184.71px",
                          Height: "104.71px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={item.thumbnail}
                          alt={item.content_title}
                          style={{
                            display: "flex",
                            width: "184.71px",
                            height: "104.71px",
                            objectFit: "cover",
                          }}
                        />
                        <Grid item>
                          <Typography
                            sx={{
                              color: "#FFF",
                              fontFamily: "Inter",
                              fontSize: {
                                xs: "0.65rem",
                                md: "0.75rem",
                                lg: "14px",
                                xl: "15.508px",
                              },
                              fontWeight: 600,
                              lineHeight: "150%",
                              paddingBottom: "0",
                              paddingTop: "0.5rem",
                            }}
                          >
                            {item.episode_title}
                            <br />
                          </Typography>
                          {/* <Typography
                            sx={{
                              fontFamily: "Inter",
                              fontSize: {
                                xs: "0.65rem",
                                md: "0.75rem",
                                lg: "12px",
                                xl: "14px",
                              },
                              fontWeight: 500,
                              lineHeight: "21.86px",
                              letterSpacing: "-0.578px",
                              textAlign: "left",
                              color: "rgba(140, 140, 140, 1)",
                              padding: "0",
                            }}
                          >
                            {item.duration}
                          </Typography> */}
                        </Grid>
                        <Button
                          sx={{
                            width: "auto",
                            height: "auto",
                            minWidth: "59.64px",
                            padding: "4.627px 15.038px",
                            position: "absolute",
                            bottom: "2.412px",
                            right: "9.412px",
                            background: "rgba(101, 76, 117, 1)",
                            color: "white",
                            textTransform: "initial",
                            fontFamily: "Inter",
                            fontSize: { xs: "0.65rem", md: "0.75rem" },
                            borderRadius: "4.627px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          Duration {item.duration}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                )}
              </>
            ) : (
              <Box sx={{ position: "relative", paddingTop: "2.1rem" }}>
                {moreLikeThis &&
                  moreLikeThis.length > 0 &&
                  moreLikeThis.map((item) => (
                    <Box
                      key={item.content_id}
                      onClick={() => {
                        handleMoreLike(item); // Call the handleVideoClick function with the item
                        console.log(item, "itemss"); // Log the item to the console when clicked
                      }}
                      sx={{
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        gap: "9.412px",
                        borderRadius: "9.412px",
                        position: "relative",
                        marginBottom: "1rem",
                        overflow: "hidden",
                        Width: "184.71px",
                        Height: "104.71px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        src={item.thumbnail}
                        alt={item.content_title}
                        style={{
                          display: "flex",
                          width: "184.71px",
                          height: "104.71px",
                          objectFit: "cover",
                        }}
                      />
                      <Grid item>
                        <Typography
                          sx={{
                            color: "#FFF",
                            fontFamily: "Inter",
                            fontSize: {
                              xs: "0.65rem",
                              md: "0.75rem",
                              lg: "14px",
                              xl: "15.508px",
                            },
                            fontWeight: 600,
                            lineHeight: "150%",
                            paddingBottom: "0",
                            paddingTop: "0.5rem",
                          }}
                        >
                          {item.content_title}
                          <br />
                        </Typography>
                        {/* <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: {
                              xs: "0.65rem",
                              md: "0.75rem",
                              lg: "12px",
                              xl: "14px",
                            },
                            fontWeight: 500,
                            lineHeight: "21.86px",
                            letterSpacing: "-0.578px",
                            textAlign: "left",
                            color: "rgba(140, 140, 140, 1)",
                            padding: "0",
                          }}
                        >
                          {item.duration}
                        </Typography> */}
                      </Grid>
                      <Button
                        sx={{
                          width: "auto",
                          height: "auto",
                          minWidth: "59.64px",
                          padding: "4.627px 15.038px",
                          position: "absolute",
                          bottom: "2.412px",
                          right: "9.412px",
                          background: "rgba(101, 76, 117, 1)",
                          color: "white",
                          textTransform: "initial",
                          fontFamily: "Inter",
                          fontSize: { xs: "0.65rem", md: "0.75rem" },
                          borderRadius: "4.627px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Duration {item.duration}
                      </Button>
                    </Box>
                  ))}
              </Box>
            )}
          </Grid>
          </Grid>
      </Grid>
      {historyData && <Header historyData={historyData} />}
      {/* <Snackbar
        // anchorOrigin={{ vertical, horizontal }}
        // open={open}
        // onClose={handleCloseSnackbar}
        // message="Wishlist Added" // Updated message
        // key={vertical + horizontal}
        // autoHideDuration={3000} // Snackbar will automatically close after 3 seconds

        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message="This Snackbar will be dismissed in 5 seconds."
      /> */}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={2000}
        imgSrc={snackbarImage} // Dynamically pass the image URL
      />
    </>
  );
};

export default VideoPlayer;
