// import axiosInstance from '../../axiosInstance';
import { apiEndpoint } from "../../components/data/config";
import axiosInstance from '../../axiosInstance';
import axios from "axios";


  
//   export const live_tv = async () => {
//     const config = {
//       method: "get",
//       url: `${apiEndpoint}/api/app_get_livetv`,
    
//     };
//     return axiosInstance(config);
//   };





 
export const live_tv = async () => {
  const config = {
    method: "get",
    url: `${apiEndpoint}/api/app_get_livetv`,
    headers: {
      Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};
