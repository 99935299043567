import { createSlice } from '@reduxjs/toolkit';

const videoSlice = createSlice({
  name: 'video',
  initialState: {
    content_id: null,
    content_type_id: null,
    episode_id: null,
  },
  reducers: {
    setVideoData: (state, action) => {
      state.content_id = action.payload.content_id;
      state.content_type_id = action.payload.content_type_id;
      state.episode_id = action.payload.episode_id || null;
    },
    clearVideoData: (state) => {
      state.content_id = null;
      state.content_type_id = null;
      state.episode_id = null;
    },
  },
});

export const { setVideoData, clearVideoData } = videoSlice.actions;
export default videoSlice.reducer;
