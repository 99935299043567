import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import nowishlist from "../images/nowishlist.png";

const wishlistNoData=()=> {
  return (
   

    <div
      style={{
        maxWidth: "100vw",
        height:'100%',
        // minHeight: "100vh",
        backgroundColor: "#1b0a28",
        position: "relative",
        overflowX: "hidden",
        overflowY: "hidden",
        
      }}
    >
      <Grid
        sx={{
          position: "relative",
          paddingTop: { xs: "8rem", sm: "10rem", md: "12rem", lg: "12rem" },
          paddingLeft: { xs: "2rem", sm: "2rem", md: "10rem", lg: "10rem" },
          paddingRight: { xs: "2rem", sm: "2rem", md: "10rem", lg: "10rem" },
        }}
      >
        <Grid className="content_gradient_sub"></Grid>
        <Grid className="recent_gradient"></Grid>

     

        <Grid classes="recent_gradient"></Grid>
        <Grid classes="home_gradients_nodata" />
        {/* <Grid classes="home_gradients" /> */}
        <Grid classes="recent_gradient_sub"></Grid>

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid item xs={12} sm={6} md={6} sx={{ position: "relative" }}>
            <Typography variant="subTitle1">Wishlist</Typography>
          </Grid>
        </Grid>

        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
            marginTop: "2rem",
        
          }}
        />

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            paddingBottom: { xs: "8rem", sm: "10rem", md: "10rem", lg: "12rem" },
            paddingTop: { xs: "8rem", sm: "10rem", md: "10rem", lg: "12rem" },
          }}
        >
          <Grid xs={4} sm={1} md={2} lg={4} />

          <Grid xs={4} sm={10} md={8} lg={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "10vh",
                width: "100%",
              }}
            >
              <img
                src={nowishlist}
                alt="Feedback Logo"
                style={{
                  maxWidth: "90%",
                  height: "auto",
                }}
              />
            </Box>

            {/* Wishlist Text */}
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "30.65px",
                fontWeight: 700,
                lineHeight: "37.09px",
                letterSpacing: "0.005em",
                textAlign: "center",
                color: "rgba(255, 255, 255, 1)",
                // padding: "0.5rem 1rem",
                borderRadius: "8px",
              }}
            >
        You have no Wishlist
            </Typography>

            {/* Wishlist Added Text */}
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "23px",
                fontWeight: 500,
                textAlign: "center",
                color: "rgba(255, 255, 255, 1)",
                padding:'0'
              }}
            >
                Wishlist added will appear here
          
            </Typography>
          </Grid>

          <Grid xs={4} sm={1} md={2} lg={4} />
        </Grid>
      </Grid>
    </div>
  );
}

export default wishlistNoData;
