
import { createSlice } from '@reduxjs/toolkit'; // Import createSlice
const searchSlice = createSlice({
  name: 'search',
  initialState: {
    isSearchMode: false,
  },
  reducers: {
    toggleSearchMode: (state, action) => {
      // Set the isSearchMode state based on the payload value or toggle if no payload
      if (typeof action.payload === 'boolean') {
        state.isSearchMode = action.payload;
      } else {
        state.isSearchMode = !state.isSearchMode;
      }
    },
  },
});

export const { toggleSearchMode } = searchSlice.actions;
export default searchSlice.reducer;


