import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";

import { useSelector } from "react-redux";

import Mimage from "../images/mimage1.png";
import NoData from '../images/No search Data.png'
import five from '../images/five.png'
import { useLocation, useNavigate } from "react-router-dom";
import { live_tv } from "../livePage/liveState";
import CryptoJS from 'crypto-js';

const images = [{ src: Mimage, alt: "Mimage",
  src: Mimage, alt: "Mimage",
 }];

const LiveTvSearch = () => {
  const isSearchMode = useSelector((state) => state.search.isSearchMode);
  const [activeButton, setActiveButton] = useState("All");
  const [channels, setChannels] = useState([]);
  const [error500, setError500] = useState(false); // Add error state for 500 status
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get('search') || '';
 
  const buttonStyle = {
    background: "#40374D",
    color: "#FFB7FF",
    width: "195px",
    height: "40px",
    borderRadius: "12px",
    textTransform: "initial",
    fontSize: "16px",
    fontFamily: "Inter !important",
    padding: "19.06px 20.65px 19.06px 20.65px",
    "&:hover": {
      background: "#40374D",
    },
  };

  const activeButtonStyle = {
    background: "#BD25C0",
    color: "#FFB7FF",
    "&:hover": {
      background: "#BD25C0",
    },
  };


  useEffect(() => {
    live_tv()
      .then((res) => {
        console.log(res);
        setChannels(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        if (err.response && err.response.status === 500) {
          setError500(true);
        }
      });
  }, []);

    // Filter data based on the search query
    const filteredData = channels.filter(item =>
      item.content_title.toLowerCase().includes(searchQuery.toLowerCase()) 
      // ||
      // item.subtitle.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const navigate = useNavigate();
    // const handleClick = (content_id, content_type_id,episode_id) => {
    //   console.log(content_id, content_type_id,"dataa")
    //   navigate(`/videoPlayer?content_id=${content_id}&content_type_id=${content_type_id}`);
    // };
    const encryptQueryString = (queryString) => {
      const secretKey = "your-secret-key"; // Use a secure key here
      const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
      return encodeURIComponent(encrypted); // Make it URL-safe
    };
  
  
    const handleClick = (content_id, content_type_id) => {
      // Construct the query string
      const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;
      
      // Encrypt the query string
      const encryptedQuery = encryptQueryString(queryString);
  
      // Navigate to the new page with the encrypted query string
      navigate(`/videoPlayer?data=${encryptedQuery}`);
    };


    if (error500) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Full viewport height to center the image vertically
          }}
        >
          <img src={five} alt="Error 500" style={{ maxWidth: '100%', height: 'auto' }} />
        </div>
      );
    }
  
  
  
  return (
    <div
      style={{
        maxWidth: "100vw",
        minHeight: "100vh", // Changed height to minHeight to ensure the content can overflow if needed
        backgroundColor: "#1b0a28",
        position: "relative",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Grid classes="data"></Grid>
      <Grid
        sx={{
          position: "relative",
          // paddingTop: "18rem",
          paddingTop: { xs: "12rem", sm: "12rem", md: "12rem", lg: "12rem" },
          paddingBottom: "5rem",
          // paddingLeft: "10rem",
          // paddingRight: "10rem",
          paddingLeft: { xs: "2rem", sm: "2rem", md: "10rem" },
          paddingRight: { xs: "2rem", sm: "2rem", md: "10rem" },
        }}
      >
        <Grid classes="content_gradient_sub"></Grid>
        <Grid classes="recent_gradient"></Grid>

        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "2rem" }}>
            <Typography variant="subTitle1">Live Tv Search</Typography>
          </Grid>
        </Grid>
        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
          }}
        />

{filteredData.length === 0 && !error500 ? (
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5rem", // Adjust spacing below divider
            paddingBottom: "5rem", // Add some spacing below the image
          }}
        >
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

              }}
            >
              <img
                src={NoData}
                alt="No Data"
                // style={{ maxWidth: "50%", height: "auto" }}
              />
            </div>
          </Grid>
        </Grid>
      ) : (


      
          <Grid
            container
             spacing={3} 
            columnSpacing={{ xs: 2, sm: 4, md: 10, lg: 1.5 }}
            columns={{ xs: 4, sm: 10, md: 14, lg: 15 }}
            pt={5}
            sx={{position:'relative'}}
          >
            <Grid classes="home_gradient" />
            <Grid classes="home_gradients" />
            <Grid classes="recent_gradient_sub"></Grid>

            {filteredData &&
              filteredData.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  key={index}
                  sx={{ paddingBottom: "2rem",position:'relative',cursor:"pointer" }}
                  onClick={() => handleClick(item.content_id, item.content_type_id,
                  
                  )}
                >
                  <img
                     src={item.thumbnail}
                     alt={`Thumbnail ${index}`}
                    style={{
                      width: "100%",
                      position: "relative",
                    }}
                    onClick={() => handleClick(item.content_id, item.content_type_id,
                  
                    )}
                  />
                  <Typography
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      color: "#F9F9F9",
                      fontFamily: "Inter",
                      fontSize: "22.797px",
                      fontWeight: 400,
                      marginLeft:'5px',
                    }}
                  >
                    {item.content_title}
                  </Typography>
                  <Typography
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      color:'rgba(249, 249, 249, 0.8)',

                      fontFamily: "Inter",
                      fontSize: "16.797px",
                      fontWeight: 400,
                      marginLeft:'5px',
                    }}
                  >
                    {item.subtitle}
                  </Typography>
                </Grid>
              ))}
          </Grid>
      )}
        {/* )} */}
      </Grid>
    </div>
  );
};

export default LiveTvSearch;
