import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from "@mui/material/styles";
import theme from "../src/theme/theme";
import { Provider } from 'react-redux';
import store from './redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    // <GoogleOAuthProvider clientId="555756710588-ds7ujjqahght6f8ordvsu2e67qgr1l6o.apps.googleusercontent.com">
     <Provider store={store}>
     <ThemeProvider theme={theme}>
      <BrowserRouter>
      {/* <Router /> */}


      <App />
      </BrowserRouter>

  
      </ThemeProvider>
      </Provider>
    //   </GoogleOAuthProvider>
  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
