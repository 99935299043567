

import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { get_historylist, Delete_history ,Delete_History_All} from "./profileState";
import DeleteIcon from "../../../../pages/images/deleteicon.png";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import five from '../../../../pages/images/five.png'
import CustomSnackbar from "../../../Snackbar/snackbar";
import Deletetoast from '../../../../pages/images/deletetoast.png'
import NoHistory from '../../../../pages/images/NohistoryData.png'

const History = () => {
  const location = useLocation(); // Get current location
  const [hoveredIndex, setHoveredIndex] = useState(null); // State to track hover
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true); // Manage loader visibility
  const [error500, setError500] = useState(false); // Add error state for 500 status
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarImage, setSnackbarImage] = React.useState(''); // Dynamic image URL


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await get_historylist();
        console.log(res, "response");
        setHistory(res.data);
      } catch (err) {
        console.error("Error fetching data:", err);
        if (err.response && err.response.status === 500) {
          setError500(true);
        }
      } finally {
        setLoading(false); // Set loading to false after API call is done
      }
    };

    fetchData();
  }, []);

  const handleClickSnackbar = (message, imgSrc) => {
    setSnackbarMessage(message);
    setSnackbarImage(imgSrc); // Set the dynamic image
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false); // Close the snackbar
  };
  const handleDelete = (watchhistory_id) => {
    Delete_history(watchhistory_id)
      .then((response) => {
        console.log('Item deleted:', response.data);
        setHistory((prevList) => prevList.filter(item => item.watchhistory_id !== watchhistory_id));
        handleClickSnackbar('History deleted successfully!', Deletetoast);
      })
      .catch((error) => {
        console.error('Error deleting item:', error.response ? error.response.data : error.message);
        setSnackbarMessage("Failed to remove item from History");
        setSnackbarOpen(true);
      });
  };



  const handleDeleteAll = () => {
    Delete_History_All()
      .then((response) => {
        console.log('Items deleted:', response.data);
        // Refresh the page to reflect changes
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error deleting items:', error);
        // Handle error (e.g., show an error message to the user)
      });
  };
  const navigate = useNavigate();
  // const handleClick = (content_id, content_type_id, episode_id) => {
  //   console.log(content_id, content_type_id, "dataa")
  //   navigate(`/videoPlayer?content_id=${content_id}&content_type_id=${content_type_id}`);
  // };

 
  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };


  const handleClick = (content_id, content_type_id) => {
    // Construct the query string
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;
    
    // Encrypt the query string
    const encryptedQuery = encryptQueryString(queryString);

    // Navigate to the new page with the encrypted query string
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };
    
  if (error500) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Full viewport height to center the image vertically
        }}
      >
        <img src={five} alt="Error 500" style={{ maxWidth: '100%', height: 'auto' }} />
      </div>
    );
  }

  return (
    <>

      <div
        style={{
          maxWidth: "100vw",
          minHeight: "100vh", // Changed height to minHeight to ensure the content can overflow if needed
          backgroundColor: "#1b0a28",
          position: "relative",
          overflowX: "hidden",
          overflowY: "hidden",
        }}
      >
        <Grid classes="data"></Grid>

        <Grid
          sx={{
            position: "relative",
            paddingTop: { xs: "8rem", sm: "10rem", md: "12rem", lg: "12rem" },
            paddingLeft: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
            paddingRight: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
          }}


        >
          <Grid classes="content_gradient_sub"></Grid>
          <Grid classes="recent_gradient"></Grid>
          <Grid classes="home_gradients_wishlist" />
          <Grid classes="recent_gradient_sub"></Grid>

          <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ position: "relative" }}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={12} sm={6} md={6}>
                <Typography variant="subTitle1">History</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} sx={{ position: "relative",paddingBottom:"1rem",textAlign:'end' }} >
                <Button 
                sx={{
                  color:'#CD59E2',
  fontWeight: 600,
  fontSize: "22px",
  lineHeight: "26px",
  fontFamily: "Inter !important",
  float:'right',
textTransform:'initial',
  position:'relative', 
                }}
                
                onClick={handleDeleteAll}>Clear all</Button>
                {/* <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteAll}
            style={{ cursor: 'pointer' }}
          >
            Clear all
          </Button> */}

              </Grid>
            </Grid>
          </Grid>

          <Divider
            style={{
              border: "1px solid #64748B",
              position: "relative",
            }}
          />

          
{history.length === 0 && !error500 ? (
        <Grid
          container
          spacing={3}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "5rem", // Adjust spacing below divider
            paddingBottom: "5rem", // Add some spacing below the image
          }}
        >
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

              }}
            >
              <img
                src={NoHistory}
                alt="No Data"
                // style={{ maxWidth: "50%", height: "auto" }}
              />
            </div>
          </Grid>
        </Grid>
      ) : (

          <Grid
            container
            spacing={{ xs: 2, sm: 4, md: 3 }}
            rowSpacing={{ xs: 2, sm: 6, md: 5 }}
            columnSpacing={{ xs: 2, sm: 1, md: 1, lg: 3 }}
            columns={{ xs: 4, sm: 12, md: 14, lg: 14 }}
            justifyContent={"space-evenly"}
            sx={{
              paddingTop: "3rem",
              paddingBottom: "3rem",
              position: "relative",

            }}

          >
            {history?.map((item, index) => (
              <React.Fragment key={index}>
                <Grid
                  item xs={12} sm={3} md={2.6} lg={2.6} xl={2.6}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  onClick={() => handleClick(item.content_id, item.content_type_id)}
                  style={{
                    marginBottom:"1rem",

                    position: "relative",
                    transition: "background-color 0.3s ease",
                    backgroundColor: hoveredIndex === index ? "rgba(255, 255, 255, 0.1)" : "transparent", // Background with light transparency
                  }}
                >

                  <div
                    style={{
                      width: "calc(100% + 20px)", // Increase width slightly to give a larger background
                      height: "calc(100% + 20px)", // Increase height slightly
                      backgroundColor: hoveredIndex === index ? "rgba(255, 255, 255, 0.1)" : "transparent",
                      position: "absolute",
                      top: "-10px",
                      left: "-10px",
                      zIndex: -1, // Ensure the background stays behind the content
                      transition: "all 0.3s ease",
                    }}
                  />
                  <img
                    src={item.thumbnail}
                    alt={`Thumbnail ${index}`}
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                </Grid>

                {/* Text Content Grid Item */}
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={6}
                  lg={6}
                  xl={9}
                  // sx={{ marginTop: { xl: "1.5rem" } }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  style={{
                    marginBottom:"1rem",
                    position: "relative",
                    transition: "background-color 0.3s ease",
                    backgroundColor: hoveredIndex === index ? "rgba(255, 255, 255, 0.1)" : "transparent", // Background with light transparency
                  }}
                >

                  <div
                    style={{
                      width: "calc(100% + 20px)", // Increase width slightly to give a larger background
                      height: "calc(100% + 20px)", // Increase height slightly
                      backgroundColor: hoveredIndex === index ? "rgba(255, 255, 255, 0.1)" : "transparent",
                      position: "absolute",
                      top: "-10px",
                      left: "-10px",
                      zIndex: -1, // Ensure the background stays behind the content
                      transition: "all 0.3s ease",
                      marginBottom:"30px"
                    }}
                  />
                  <Typography
                    variant="subTitle1"
                    sx={{
                      fontSize: {
                        xs: "18px",
                        sm: "20px",
                        md: "22px",
                        lg: "28px",
                      },
                      lineHeight: {
                        xs: "22px",
                        sm: "24px",
                        md: "26px",
                        lg: "28px",
                      },
                      paddingRight: { sm: "1rem", lg: "1rem", md: "1rem" },
                    }}
                  >
                    {item.content_title}
                  </Typography>

                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: {
                        xs: "16px",
                        sm: "18px",
                        md: "18px",
                        lg: "25.237px",
                      },
                      lineHeight: {
                        xs: "20px",
                        sm: "22px",
                        md: "24px",
                        lg: "26.336px",
                      },
                      paddingTop: {
                        xs: "0.5rem",
                        sm: "1rem",
                        md: "1rem",
                        lg: "1.5rem",
                      },
                      color: "var(--White-White-80, rgba(249, 249, 249, 0.80))",
                      fontFamily: "Inter",
                      fontWeight: 400,
                    }}
                  >
                    {item.content_type_name}
                  </Typography>
                </Grid>

                {/* Button Grid Item */}
                <Grid
                  item
                  xs={12}
                  sm={4}
                  md={5.4}
                  lg={5.4}
                  xl={5.4}
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", sm: "flex-end" },
                    flexDirection: "column",
                    alignItems: "end",
                    paddingTop: {
                      xs: "1rem",
                      sm: "2rem",
                      md: "3rem",
                      lg: "5rem",
                    },
                  }}
                  onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  style={{
                    marginBottom:"1rem",
                    position: "relative",
                    transition: "background-color 0.3s ease",
                    backgroundColor: hoveredIndex === index ? "rgba(255, 255, 255, 0.1)" : "transparent", // Background with light transparency
                  }}
                >

                  <div
                    style={{
                      width: "calc(100% + 20px)", // Increase width slightly to give a larger background
                      height: "calc(100% + 20px)", // Increase height slightly
                      backgroundColor: hoveredIndex === index ? "rgba(255, 255, 255, 0.1)" : "transparent",
                      position: "absolute",
                      top: "-10px",
                      left: "-10px",
                      zIndex: -1, // Ensure the background stays behind the content
                      transition: "all 0.3s ease",
                    }}
                  />
                  <Grid item>
                    {hoveredIndex === index && (
                      <img
                        src={DeleteIcon}
                        alt="delete"
                        style={{ marginBottom: "3rem", cursor: "pointer",
                     
                          marginRight:"24px",
                         }} // Optional margin to separate from the button
                        onClick={() => handleDelete(item.watchhistory_id)}
                      />
                    )}
                  </Grid>

                  <Button
                    sx={{
                      height: "49px",
                      padding: "11px 18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "11px",
                      background: "#654C75",
                      textTransform: "initial",
                      fontSize: { xs: "16px", sm: "18px", md: "20px" },
                      fontWeight: 400,
                      fontFamily: "Inter",
                      color: "#FFF",
                      marginBottom:"10px",
                      marginRight:"24px",
                      "&:hover": {
                        background: "#654C75",
                      },
                    }}
                  >
                    {item.content_duration}
                  </Button>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
      )}
        </Grid>

             
<CustomSnackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={5000}
        imgSrc={snackbarImage} // Dynamically pass the image URL
      

      />
      </div>


    </>
  );
};

export default History;
