import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
  AppBar,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputBase,
  TextField,
  Typography,
} from "@mui/material";
import GroupImage from "../../pages/images/Group.png";
import logo from "../../pages/images/Logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import { useNavigate, useLocation } from "react-router-dom";
import search from "../../pages/images/searchIcon.png";
import profile from "../../pages/images/profile.png";

import { useDispatch, useSelector } from "react-redux";


import { toggleSearchMode } from "../../redux/searchSlice";
import inputsearch from "../../pages/images/inputbase search.png";
import { searchContent } from "../../pages/search/searchState";

import logout from "../../pages/images/Logout Square.png";
import Accout from "../../pages/images/person.png";
import wishlist from "../../pages/images/wishlistMenu.png";
import History from "../../pages/images/HistoryMenu.png";
import Contact from "../../pages/images/contact.png";

//Dialog box

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axios from "axios";

import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";

import { savePlaybackHistory } from "../../components/layout/Footer/profile/profileState";
import AuthDialog from "../AuthDialog";

// import { auth } from "../../fireBase/setup";  // Ensure the path is correct
const pages = ["Home", "Search", "Live Tv", "Movies"];

const Header = ({ historyData }) => {
  const auth = getAuth(); // Ensure auth is correctly initialized
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [code, setCode] = React.useState(null);
  const [activeButton, setActiveButton] = useState("/");

  const [searchText, setSearchText] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const dispatch = useDispatch();
  const isSearchMode = useSelector((state) => state.search.isSearchMode);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);
  const handleCloseCode = () => setCode(false);

  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem("auth_token");
      setIsLoggedIn(!!token); // Update state based on whether token exists
    };

    checkLoginStatus();
  }, []);

  const handleMenuClickProfile = async (menuItem) => {
    setIsProfileDropdownOpen(false);

    if (menuItem === "History" && historyData) {
      savePlaybackHistory(historyData); // Trigger savePlaybackHistory if "History" is clicked
    }

    switch (menuItem) {
      case "Account":
        navigate("/account");
        break;
      case "Wishlist":
        navigate("/wishlist");
        break;
      case "History":
        navigate("/history");
        break;
      case "Contact":
        navigate("/contact");
        break;
      case "Sign Out":
        await handleLogout(); // Call the logout function when "Sign Out" is clicked
        break;
      default:
        break;
    }
  };

  const handleLogout = async () => {
    try {
      // Get the stored token (assuming it's in localStorage)
      const token = localStorage.getItem("auth_token");

      if (token) {
        // Make API request to logout
        const response = await axios.get(
          "https://backend.charismainfotainment.com/api/app_user_logout",
          {
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          }
        );

        if (response.status === 200) {
          console.log("Logout successful");

          // Clear the token from localStorage
          localStorage.removeItem("auth_token");

          // Optionally clear other user data if needed
          // localStorage.removeItem('userData');

          // Navigate to the home or login page

          navigate("/");
          window.location.reload();
        } else {
          console.error("Failed to log out: ", response.status, response.data);
        }
      } else {
        console.warn("No token found");
        navigate("/"); // Redirect to home page if no token exists
      }
    } catch (error) {
      console.error(
        "Error during logout:",
        error.response ? error.response.data : error.message
      );
    }
  };

  const [error, setError] = useState("");

  // Handle OTP Verification
  const [phone, setPhone] = useState("+91");
  const [hasFilled, setHasFilled] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [resendTimer, setResendTimer] = useState(55);

  // const [otp, setOtp] = useState("");
  const [otp, setOtp] = useState(Array(verificationCode.length).fill(""));

  const generateRecaptcha = () => {
    // Ensure the recaptchaVerifier is only initialized once
    if (!window.recaptchaVerifier) {
      const recaptchaContainer = document.getElementById("recaptcha-container");
      if (!recaptchaContainer) {
        console.error("recaptcha-container element not found in DOM.");
        return;
      }

      if (!auth) {
        console.error("Firebase auth instance not initialized.");
        return;
      }

      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            console.log("reCAPTCHA solved", response);
          },
        },
        auth
      );

      window.recaptchaVerifier
        .render()
        .then((widgetId) => {
          window.recaptchaWidgetId = widgetId;
        })
        .catch((error) => {
          console.error("Error rendering reCAPTCHA widget:", error);
        });
    } else {
      console.log("reCAPTCHA already rendered.");
    }
  };
  useEffect(() => {
    generateRecaptcha();
  }, []);

  const handleSend = (event) => {
    event.preventDefault();
    setCode(true);
    setHasFilled(true);
    generateRecaptcha();
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        setCode(true);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };

  const verifyOtp = async () => {
    try {
      // Use the phone state instead of hardcoding the phone number
      const payload = {
        phone_number: phone, // The phone variable is dynamically updated from the input field
      };
      // Post user data to your custom backend API
      const response = await axios.post(
        "https://backend.charismainfotainment.com/api/app_otp_login",
        payload
      );
      console.log("API Response Status:", response.status);
      console.log("API Response Data:", response.data);
      if (response.status === 200) {
        navigate("/homePage");
        // Access the token from the response data
        const { token: backendToken } = response.data.data; // Adjust based on actual response structure
        navigate("/homePage");
        if (backendToken) {
          // Store the token in localStorage
          localStorage.setItem("auth_token", backendToken);
          console.log("Token stored in local storage:", backendToken);
          // Handle successful login response
          // You can add further actions here, e.g., navigate to a different page
        } else {
          console.error("No token found in response data.");
          alert("Login failed: No token returned.");
        }
      } else {
        console.error("Failed to login", response.status, response.data);
        alert("Login failed. Please check your details.");
      }
    } catch (error) {
      // Handle errors during API call
      console.error("Error during API call:", error);
      alert("Error during API call. Please try again.");
    }
  };

  const handleInputChange = (index, e) => {
    const value = e.target.value;

    if (value.length === 1) {
      // Update OTP array and move focus to next input
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      focusNext(index);
    } else if (value.length === 0) {
      // Update OTP array and move focus to previous input
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      focusPrevious(index);
    }
  };

  const handleVerify = (event) => {
    event.preventDefault();
    const code = document.getElementById("otp-input").value; // Assuming you have an input field for OTP
    if (window.confirmationResult) {
      window.confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          const user = result.user;
          console.log("User signed in successfully:", user);
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          console.error("Error during OTP verification:", error);
        });
    } else {
      console.error("No confirmation result available");
    }
  };
  const inputRefs = useRef([]);

  // Function to focus the next input field
  const focusNext = (index) => {
    if (index < verificationCode.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Function to focus the previous input field
  const focusPrevious = (index) => {
    if (index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const [message, setMessage] = useState(""); // State to store the dynamic message
  const [messageType, setMessageType] = useState("info"); // State to handle message type (success, error, etc.)

  // Function to send the verification code
  const handleSendOtpSuccess = () => {
    setMessage(
      "Verification code has been sent to the registered mobile number."
    );
    setMessageType("success");
  };
  const handleResendCode = () => {
    // Logic to resend OTP
    console.log("Resending OTP...");
    // Reset the timer
    setResendTimer(55);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setActiveButton(location.pathname);
  }, [location]);

  const handleButtonClick = (value) => {
    const token = localStorage.getItem("auth_token"); // Retrieve the stored token

    // If the user is trying to navigate to Movies, Live TV, or TV Series
    if (
      value === "/movies" ||
      value === "/tvSeriesPage" ||
      value === "/livetv" ||
      value === "/songs" ||
      value === "/homePage"
    ) {
      // Check if the user is logged in
      if (!token) {
        console.log("User not logged in. Showing login modal.");
        setOpen(true); // Show login modal
        return; // Prevent navigation
      }
    }

    // If the value is "/" (home page), toggle search mode off
    if (value === "/") {
      dispatch(toggleSearchMode(false)); // Set isSearchMode to false on landing page
    }

    setActiveButton(value); // Set the active button
    navigate(value); // Navigate to the clicked page
  };

  useEffect(() => {
    if (location.pathname === "/") {
      dispatch(toggleSearchMode(false)); // Reset isSearchMode when on the landing page
    }
  }, [location, dispatch]);

  const handleKeyDown = (index, event) => {
    // Handle backspace key
    if (event.key === "Backspace" && !event.target.value) {
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const buttonStyle = {
    minWidth: "95px",
    height: "32px",
    borderRadius: "99px",
    color: "white",
    fontWeight: 500,
    fontSize: "15.9px",
    lineHeight: "20px",
    textTransform: "inherit",
    fontFamily: "Inter",
    background: "transparent",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
    },

    "@media (max-width: 1200px)": {
      minWidth: "70px",
      // height: "24px",
      fontSize: "12px",
    },
  };

  // Style for active buttons
  const activeButtonStyle = {
    borderRadius: "99px",
    minWidth: "99px",
    border: "2px solid rgba(255, 255, 255, 0.13)",
    background:
      "linear-gradient(to left,#280F48, #280F48) padding-box,linear-gradient(260deg, #885CC0,#442E60) border-box",
    boxShadow: `
      0px 1px 0px 0px rgba(0, 0, 0, 0.05),
      0px 4px 4px 0px rgba(0, 0, 0, 0.05),
      0px 10px 10px 0px rgba(0, 0, 0, 0.10)
    `,
    backdropFilter: "blur(10px)",
    borderImageSource: `
      linear-gradient(0deg, rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.13)),
      linear-gradient(86.25deg, rgba(255, 255, 255, 0) 6.77%, #FFFFFF 88.01%)
    `,
    textTransform: "inherit",
  };

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleCloseDialog = () => {
  //   setOpen(false);
  // };

  // const handleCloseCode = () => {
  //   setCode(false);
  // };

  // Effect to handle countdown
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);
  //Profile

  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const handleProfileClick = () => {
    setIsProfileDropdownOpen(!isProfileDropdownOpen);
  };

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsProfileDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const login = async () => {
    const provider = new GoogleAuthProvider();

    try {
      // Trigger Google Sign-In popup
      const result = await signInWithPopup(auth, provider);

      // Get user info from Google result
      const user = result.user;
      const token = await user.getIdToken(); // Firebase token if needed for future use

      const userData = {
        name: user.displayName,
        email: user.email,
        phone_number: user.phoneNumber,
        birth_date: "1990-01-01", // Example birth date, change as required
      };

      // Post user data to your custom backend API
      const response = await axios.post(
        "https://backend.charismainfotainment.com/api/app_social_login",
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Optional: if you want to pass the Firebase token
          },
        }
      );

      if (response.status === 200) {
        // Check the structure of response.data
        console.log("Login response data:", response.data);

        // Access the token from the response data
        const { token: backendToken } = response.data.data; // Adjust based on actual response structure

        if (backendToken) {
          // Store the token in localStorage
          localStorage.setItem("auth_token", backendToken);
          console.log("Token stored in local storage:", backendToken);

          // Handle successful login response
          setOpen(false);
          navigate("/homePage");
          // alert("Login is successful");
        } else {
          console.error("No token found in response data.");
        }
      } else {
        // Handle non-200 status
        console.error("Failed to login", response.status, response.data);
      }
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
    }
  };

  ///////////////////////////////////////////////otp login////////////////////////////////////////

  useEffect(() => {
    // Timer for resending OTP
    if (resendTimer > 0) {
      const timerId = setTimeout(() => setResendTimer(resendTimer - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [resendTimer]);

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (historyData) {
      savePlaybackHistory(historyData);
    }
  }, [historyData]);

  const handleSearchChange = async (event) => {
    setSearchText(event.target.value); // Update searchText with user input
  };

  const [showNavigation, setShowNavigation] = useState(true);
  const [isInitialSearch, setIsInitialSearch] = useState(true);

  const handleSearchClick = async () => {
    if (isInitialSearch) {
      dispatch(toggleSearchMode());

      try {
        const results = await searchContent(searchText);
        console.log(results, "results");

        let navigateTo = "";

        if (location.pathname.includes("movies")) {
          navigateTo = `/explore/movies?search=${encodeURIComponent(
            searchText
          )}`;
        } else if (location.pathname.includes("tvSeries")) {
          navigateTo = `/explore/tvSeries?search=${encodeURIComponent(
            searchText
          )}`;
        } else if (location.pathname.includes("livetv")) {
          navigateTo = `/explore/livetv?search=${encodeURIComponent(
            searchText
          )}`;
        } else if (location.pathname.includes("songs")) {
          navigateTo = `/explore/songssearch?search=${encodeURIComponent(
            searchText
          )}`;
        }else if (location.pathname.includes("homePage")) {
          navigateTo = `/explore/popularsearch?search=${encodeURIComponent(
            searchText
          )}`;
        }
        
        
        
        else if (location.pathname.includes("homePage")) {
          navigateTo = `/explore/popularsearch?search=${encodeURIComponent(
            searchText
          )}`;
        }

        if (navigateTo) {
          navigate(navigateTo, { state: { searchResults: results.data } });
          setShowNavigation(false); // Hide navigation items during search
        }
        setIsInitialSearch(false); // Set the flag to false after redirection
      } catch (error) {
        console.error("Error performing search:", error);
      }
    } else {
      // Subsequent search clicks: perform search operation on the current page
      try {
        const results = await searchContent(searchText);
        console.log(results, "results");
        // Handle the search results as needed here
      } catch (error) {
        console.error("Error performing search:", error);
      }
    }
  };
  const [isInputVisible, setIsInputVisible] = useState(true);

  // const handleKeyPress = (event) => {
  //   if (event.key === "Enter") {
  //     event.preventDefault(); // Prevent default form submission
  //     handleSearchClick();
  //   }
  // };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearchClick();
    } else if (event.key === "Escape") {
      setSearchText("");
      setIsInputVisible(false); // Hide the input field
    }
  };
  // const handleInputChange = (index, value) => {
  //   const newCode = [...verificationCode];
  //   newCode[index] = value;
  //   setVerificationCode(newCode);

  //   // Combine the individual digits into a single OTP string
  //   const otpString = newCode.join('');
  //   setOtp(otpString);
  // };

  // const handleInputChange = (index, value) => {
  //   if (/^[0-9]$/.test(value) || value === "") {
  //     // Ensure only numbers are accepted
  //     const newCode = [...verificationCode];
  //     newCode[index] = value;
  //     setVerificationCode(newCode);

  //     // Automatically move focus to the next input if the current input is filled
  //     if (value !== "" && index < verificationCode.length - 1) {
  //       document.getElementById(`otp-input-${index + 1}`).focus();
  //     }

  //     // Trigger OTP verification if all inputs are filled
  //     if (newCode.every((digit) => digit !== "")) {
  //       verifyOtp({ target: { value: newCode.join("") } });
  //     }
  //   }
  // };

  useEffect(() => {
    // Reset search state on location change
    setSearchText("");
    setIsInitialSearch(true);
  }, [location]);

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          zIndex: 1100,
          top: "30px",
        }}
      >
        <Grid classes="data"></Grid>

        <Container>
          <Grid
            container
            justifyContent="center"
            spacing={5}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "flex",
              },
            }}
          >
            <Grid
              item
              md={2}
              lg={2}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <img
                src={logo}
                alt="logo"
                onClick={() => handleButtonClick("/")}
                style={{
                  top: "20px",
                  position: "relative",

                  marginLeft: "-100px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid
              item
              md={10}
              lg={10}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    position: "relative",
                    height: "52px",
                    top: "30px",
                    minWidth: "813px",
                    borderRadius: "99px",
                    mixBlendMode: "initial",
                    background: "rgba(154, 135, 166, 0.54)",
                    boxShadow: `
                  0px 1px 0px 0px rgba(0, 0, 0, 0.05),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.05),
                  0px 10px 10px 0px rgba(0, 0, 0, 0.10)
                `,
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      padding: "1.1px",
                      borderRadius: "99px",
                      minWidth: "813px",
                      height: "52px",
                      borderColor: "transparent",
                      background:
                        "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), linear-gradient(5.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                    }}
                  />

                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12, xl: 12, lg: 12 }}
                    justifyContent="center"
                    alignItems="flex-end"
                    sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <Grid item xs={1} lg={1} md={1} sm={1}>
                      <Box
                        sx={{ paddingTop: "6px" }}
                        onClick={() => handleButtonClick("/")}
                      >
                        <img
                          src={GroupImage}
                          alt="GroupImage"
                          style={{
                            width: "31px",
                            height: "31px",
                            cursor: "pointer",
                            alignItems: "flex-end",
                            position: "relative",
                            bottom: "-5px",
                          }}
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={8} lg={9} md={8} sm={8}>
                      {!isSearchMode ? (
                        <Box
                          sx={{
                            width: "392px",
                            height: "32px",
                            gap: "5px",
                            display: "flex",
                          }}
                        >
                          <Button
                            onClick={() => handleButtonClick("/homePage")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/homePage"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Home
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/movies")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/movies"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Movies
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/tvSeriesPage")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/tvSeriesPage"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            TV Series
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/songs")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/songs"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Songs
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/livetv")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/livetv"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Live TV
                          </Button>
                        </Box>
                      ) : (
                        isInputVisible && (
                          <Box
                            sx={{
                              width: "392px",
                              height: "32px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              position: "relative",
                            }}
                          >
                            <InputBase
                              value={searchText}
                              onChange={(e) => setSearchText(e.target.value)}
                              onKeyDown={handleKeyPress}
                              placeholder="Search..."
                              classes="searchInput"
                              sx={{
                                paddingTop: "0",
                                paddingBottom: "0",
                              }}
                            />
                            {/* <Button onClick={handleSearchClick}>
          <img src={search} alt="searchIcon" />
        </Button> */}
                          </Box>
                        )
                      )}
                    </Grid>

                    <Grid item xs={3} lg={2} md={3} sm={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                        }}
                      >
                        {[
                          "/homePage",
                          "/movies",
                          "/tvSeriesPage",
                          "/livetv",
                          "/songs",
                          "/explore/movies",
                          "/explore/tvSeries",
                          "/explore/songs",
                          "/explore/livetv",
                          "/explore/songssearch",
                          "/explore/popularsearch",
                        ].includes(activeButton) ? (
                          <>
                            <Button
                              style={{
                                width: "32px",
                                height: "100%",
                                display: "flex",
                                justifyContent: "end",
                              }}
                              // onClick={handleSearchClick}
                              onClick={handleSearchClick}
                            >
                              <img src={search} alt="searchIcon" />
                            </Button>
                            <Button
                              style={{
                                height: "34px",
                                display: "flex",
                                justifyContent: "end",
                                // alignItems: "flex-end",
                                padding: "0 !important",
                              }}
                              onClick={handleProfileClick}
                              // onClick={handleClickOpen}
                              // onClick={() => {
                              //   // Handle avatar icon click
                              // }}
                            >
                              <img src={profile} alt="profile" />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Box>
                              {isLoggedIn ? (
                                <Button
                                  style={{
                                    height: "34px",
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "0 !important",
                                  }}
                                  onClick={handleProfileClick}
                                >
                                  <img src={profile} alt="profile" />
                                </Button>
                              ) : (
                                <Button
                                  // onClick={handleClickOpen}
                                  onClick={handleOpenDialog}
                                  style={{
                                    ...buttonStyle,
                                    width: "83px",
                                    padding:
                                      "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                                    gap: "var(--Buttongap)",
                                    borderRadius: "99px",
                                    border: "1px solid transparent",
                                    fontFamily: "Inter",
                                    background:
                                      "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                                    boxShadow:
                                      "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                                    fontSize: "14px",
                                    borderImage:
                                      "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                                    textTransform: "initial",
                                    backdropFilter: "blur(10px)",
                                    marginTop: "2px",
                                  }}
                                >
                                  Sign in
                                </Button>
                              )}
                            </Box>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid md={2} lg={2}></Grid>
          </Grid>
        </Container>
        <Grid
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "flex",
            },
          }}
        >
          {isProfileDropdownOpen && (
            <Box
              sx={{
                height: { xs: "auto", md: "304.85px" }, // Height adjusts for smaller screens
                borderRadius: "13.21px",
                position: "relative",
                background:
                  "linear-gradient(180deg, #5D4D69 43.91%, rgba(178, 159, 232, 0) 100%)",
                width: { xs: "100%", sm: "300px", md: "264.27px" }, // Adjust width for different screen sizes
                left: { xs: 0, md: "58%" }, // Center the dropdown on smaller screens
                top: { xs: "0px", md: "10px", lg: "50px", sm: "50px" }, // Position the dropdown below the trigger on smaller screens
                backdropFilter: "blur(13.21367073059082px)",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  borderRadius: "13.21px",
                  height: "100%",
                  top: "0px",
                  left: "0px",
                  right: "0px",
                  padding: "2px",
                  zIndex: "20",
                  overflow: "hidden",
                  background:
                    "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%)",
                  borderImageSlice: "1",
                  mixBlendMode: "overlay",
                  WebkitMask:
                    "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",
                  boxShadow: `
          0px 6.607px 13.214px 0px rgba(0, 0, 0, 0.10), 0px 19.821px 39.641px 0px rgba(0, 0, 0, 0.10), 0px 26.427px 52.855px 0px rgba(0, 0, 0, 0.15)
        `,
                }}
              />
              <Box
                sx={{
                  position: "relative",
                  zIndex: 30,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                  fontFamily: "Inter important",
                  fontWeight: 700,
                  padding: { xs: "16px", md: "24px", lg: "24px", sm: "16px" }, // Adjust padding for different screen sizes
                }}
              >
                {[
                  {
                    name: "Account",
                    icon: (
                      <img
                        src={Accout}
                        alt="account"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  },
                  {
                    name: "Wishlist",
                    icon: (
                      <img
                        src={wishlist}
                        alt="wishlist"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  },
                  {
                    name: "History",
                    icon: (
                      <img
                        src={History}
                        alt="history"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  },
                  {
                    name: "Contact",
                    icon: (
                      <img
                        src={Contact}
                        alt="contact"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  },
                  {
                    name: "Sign Out",
                    icon: (
                      <img
                        src={logout}
                        alt="sign out"
                        style={{ width: "24px", height: "24px" }}
                      />
                    ),
                  },
                ].map((item, index) => (
                  <Box key={item.name}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "12px",
                        borderLeft: "1.321px solid transparent",
                        marginLeft: "8px",
                        background: "transparent",
                        transition: "background 0.3s ease",
                        cursor: "pointer",
                        paddingLeft: "8px", // Adds space between the border and content
                        "&:hover": {
                          background:
                            "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                          borderLeft: "1.321px solid rgba(255, 255, 255, 1)",
                        },
                        "&:active": {
                          background:
                            "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                          borderLeft: "1.321px solid rgba(255, 255, 255, 1)",
                        },
                      }}
                      onClick={() => handleMenuClickProfile(item.name)}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Inter",
                          fontSize: { xs: "16px", md: "18.499px" }, // Adjust font size for different screen sizes
                          fontWeight: 500,
                          color:
                            item.name === "Sign Out"
                              ? "rgba(255, 93, 239, 1) "
                              : "rgba(255, 255, 255, 1)",
                          paddingTop: "0px",
                          paddingBottom: "0px",
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Box
                        sx={{
                          marginLeft: "auto", // Pushes the icon to the right
                          color:
                            item.name === "Sign Out"
                              ? "rgba(219, 0, 126, 1)"
                              : "rgba(255, 255, 255, 1)",
                        }}
                      >
                        {item.icon}
                      </Box>
                    </Box>
                    {index < 4 && (
                      <Box
                        sx={{
                          borderRadius: "13.214px",
                          background:
                            "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                          height: "1.321px",
                          alignSelf: "stretch",
                          margin: { xs: "4px 8px", md: "8px 16px" }, // Adjust margin for different screen sizes
                        }}
                      />
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Grid>

        <Container>
          <Grid
            container
            justifyContent="center"
            spacing={5}
            columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "flex",
                xl: "none",
              },
            }}
          >
            <Grid
              item
              lg={3}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={logo}
                alt="logo"
                onClick={() => handleButtonClick("/")}
                style={{
                  top: "20px",
                  position: "relative",

                  // marginLeft: "-100px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid
              item
              lg={9}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    position: "relative",
                    height: "52px",
                    top: "30px",
                    minWidth: "670px",
                    borderRadius: "99px",
                    mixBlendMode: "initial",
                    background: "rgba(154, 135, 166, 0.54)",
                    boxShadow: `
                  0px 1px 0px 0px rgba(0, 0, 0, 0.05),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.05),
                  0px 10px 10px 0px rgba(0, 0, 0, 0.10)
                `,
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      padding: "1.1px",
                      borderRadius: "99px",
                      minWidth: "670px",
                      height: "52px",
                      borderColor: "transparent",
                      background:
                        "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), linear-gradient(5.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                    }}
                  />

                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12, xl: 12, lg: 12 }}
                    justifyContent="center"
                    alignItems="flex-end"
                    sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <Grid item xs={1} lg={1} md={1} sm={1}>
                      <Box
                        sx={{ paddingTop: "6px" }}
                        onClick={() => handleButtonClick("/")}
                      >
                        <img
                          src={GroupImage}
                          alt="GroupImage"
                          style={{
                            width: "31px",
                            height: "31px",
                            cursor: "pointer",
                            alignItems: "flex-end",
                            position: "relative",
                            bottom: "-5px",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={8} lg={9} md={8} sm={8}>
                      {!isSearchMode ? (
                        <Box
                          sx={{
                            width: "392px",
                            height: "100%",
                            // gap: "18px",
                            display: "flex",
                          }}
                        >
                          <Button
                            onClick={() => handleButtonClick("/homePage")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/homePage"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Home
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/movies")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/movies"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Movies
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/tvSeriesPage")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/tvSeriesPage"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            TV Series
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/songs")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/songs"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Songs
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/livetv")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/livetv"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Live TV
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "392px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative", // Add this to position the icon correctly
                          }}
                        >
                          <InputBase
                            value={searchText}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            classes="searchInput"
                            sx={{
                              paddingTop: "0",
                              paddingBottom: "0",
                            }}
                          />

                          <img
                            src={inputsearch}
                            alt="inputsearch"
                            style={{
                              position: "absolute",
                              left: "10px",
                            }}
                          />
                          {/* <SearchIcon
                      sx={{
                        position: 'absolute',
                        left: '10px', // Adjust this value to position the icon within the box
                        color: 'rgba(0, 0, 0, 0.54)', // Adjust the color if necessary
                      }}
                    /> */}
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={3} lg={2} md={3} sm={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                        }}
                      >
                        {[
                          "/homePage",
                          "/movies",
                          "/tvSeriesPage",
                          "/livetv",
                          "/explore/movies",
                          "/explore/tvSeries",
                          "/explore/livetv",
                          "/explore/popularsearch",
                        ].includes(activeButton) ? (
                          <>
                            <Button
                              style={{
                                width: "32px",
                                height: "100%",
                                display: "flex",
                                justifyContent: "end",
                              }}
                              // onClick={handleSearchClick}
                              onClick={handleSearchClick}
                            >
                              <img src={search} alt="searchIcon" />
                            </Button>
                            <Button
                              style={{
                                height: "34px",
                                display: "flex",
                                justifyContent: "end",
                                // alignItems: "flex-end",
                                padding: "0 !important",
                              }}
                              onClick={handleProfileClick}
                              // onClick={handleClickOpen}
                              // onClick={() => {
                              //   // Handle avatar icon click
                              // }}
                            >
                              <img src={profile} alt="profile" />
                            </Button>
                          </>
                        ) : (
                          <>
                            {/* <Button
                              style={{
                                ...buttonStyle,
                                width: "72px",
                                background: "transparent",
                                boxShadow: "none",
                              }}
                            >
                              Log in
                            </Button> */}
                            {/* <Button
                              onClick={handleClickOpen}
                              style={{
                                ...buttonStyle,
                                width: "83px",
                                padding:
                                  "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                                gap: "var(--Buttongap)",
                                borderRadius: "99px",
                                border: "1px solid transparent",
                                fontFamily: "Inter",
                                background:
                                  "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                                boxShadow:
                                  "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                                fontSize: "14px",
                                borderImage:
                                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                                textTransform: "initial",
                                backdropFilter: "blur(10px)",
                                marginTop: "2px",
                              }}
                            >
                              Sign In
                            </Button> */}
                            <Box>
                              {isLoggedIn ? (
                                <Button
                                  style={{
                                    height: "34px",
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "0 !important",
                                  }}
                                  onClick={handleProfileClick}
                                >
                                  <img src={profile} alt="profile" />
                                </Button>
                              ) : (
                                <Button
                                  onClick={handleClickOpen}
                                  style={{
                                    ...buttonStyle,
                                    width: "83px",
                                    padding:
                                      "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                                    gap: "var(--Buttongap)",
                                    borderRadius: "99px",
                                    border: "1px solid transparent",
                                    fontFamily: "Inter",
                                    background:
                                      "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                                    boxShadow:
                                      "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                                    fontSize: "14px",
                                    borderImage:
                                      "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                                    textTransform: "initial",
                                    backdropFilter: "blur(10px)",
                                    marginTop: "2px",
                                  }}
                                >
                                  Sign in
                                </Button>
                              )}
                            </Box>
                          </>
                        )}

                        {isProfileDropdownOpen && (
                          <Box
                            sx={{
                              height: { xs: "auto", md: "304.85px" },
                              borderRadius: "13.21px",
                              position: "absolute", // Changed to absolute positioning
                              background:
                                "linear-gradient(180deg, #5D4D69 43.91%, rgba(178, 159, 232, 0) 100%)",
                              width: {
                                xs: "100%",
                                sm: "300px",
                                md: "264.27px",
                              },
                              left: {
                                xs: 0,
                                md: "auto",
                                lg: "auto",
                                sm: "auto",
                              },
                              right: { xs: 0, md: "0", lg: "0", sm: "0" }, // Positioning adjustments
                              top: {
                                xs: "50px",
                                md: "50px",
                                lg: "90px",
                                sm: "50px",
                              },
                              backdropFilter: "blur(13.21367073059082px)",
                              zIndex: 9999, // Ensure it overlays other content
                            }}
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                borderRadius: "13.21px",
                                height: "100%",
                                top: "0px",
                                left: "0px",
                                right: "0px",
                                padding: "2px",
                                zIndex: "20",
                                overflow: "hidden",
                                background:
                                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%)",
                                borderImageSlice: "1",
                                mixBlendMode: "overlay",
                                WebkitMask:
                                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                WebkitMaskComposite: "xor",
                                maskComposite: "exclude",
                                boxShadow: `
          0px 6.607px 13.214px 0px rgba(0, 0, 0, 0.10), 0px 19.821px 39.641px 0px rgba(0, 0, 0, 0.10), 0px 26.427px 52.855px 0px rgba(0, 0, 0, 0.15)
        `,
                              }}
                            />
                            <Box
                              sx={{
                                position: "relative",
                                zIndex: 30,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                                fontFamily: "Inter important",
                                fontWeight: 700,
                                padding: {
                                  xs: "16px",
                                  md: "24px",
                                  lg: "24px",
                                  sm: "16px",
                                },
                              }}
                            >
                              {[
                                {
                                  name: "Account",
                                  icon: (
                                    <img
                                      src={Accout}
                                      alt="account"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Wishlist",
                                  icon: (
                                    <img
                                      src={wishlist}
                                      alt="wishlist"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "History",
                                  icon: (
                                    <img
                                      src={History}
                                      alt="history"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Contact",
                                  icon: (
                                    <img
                                      src={Contact}
                                      alt="contact"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Sign Out",
                                  icon: (
                                    <img
                                      src={logout}
                                      alt="sign out"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                              ].map((item, index) => (
                                <Box key={item.name}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      gap: "12px",
                                      borderLeft: "1.321px solid transparent",
                                      marginLeft: "8px",
                                      background: "transparent",
                                      transition: "background 0.3s ease",
                                      cursor: "pointer",
                                      paddingLeft: "8px", // Adds space between the border and content
                                      "&:hover": {
                                        background:
                                          "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                        borderLeft:
                                          "1.321px solid rgba(255, 255, 255, 1)",
                                      },
                                      "&:active": {
                                        background:
                                          "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                        borderLeft:
                                          "1.321px solid rgba(255, 255, 255, 1)",
                                      },
                                    }}
                                    onClick={() =>
                                      handleMenuClickProfile(item.name)
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "Inter",
                                        fontSize: {
                                          xs: "16px",
                                          md: "18.499px",
                                        }, // Adjust font size for different screen sizes
                                        fontWeight: 500,
                                        color:
                                          item.name === "Sign Out"
                                            ? "rgba(255, 93, 239, 1) "
                                            : "rgba(255, 255, 255, 1)",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                    <Box
                                      sx={{
                                        marginLeft: "auto", // Pushes the icon to the right
                                        color:
                                          item.name === "Sign Out"
                                            ? "rgba(219, 0, 126, 1)"
                                            : "rgba(255, 255, 255, 1)",
                                      }}
                                    >
                                      {item.icon}
                                    </Box>
                                  </Box>
                                  {index < 4 && (
                                    <Box
                                      sx={{
                                        borderRadius: "13.214px",
                                        background:
                                          "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                                        height: "1.321px",
                                        alignSelf: "stretch",
                                        margin: {
                                          xs: "4px 8px",
                                          md: "8px 16px",
                                        }, // Adjust margin for different screen sizes
                                      }}
                                    />
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid md={2} lg={2}></Grid>
          </Grid>
        </Container>

        {/* <Container> */}
        <Container>
          <Grid
            container
            justifyContent="center"
            spacing={5}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "none",
                xl: "none",
              },
            }}
          >
            <Grid
              item
              md={2.9}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <img
                src={logo}
                alt="logo"
                onClick={() => handleButtonClick("/")}
                style={{
                  top: "20px",
                  position: "relative",

                  // marginLeft: "-100px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid
              item
              lg={8}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    position: "relative",
                    height: "52px",
                    top: "30px",
                    minWidth: "580px",
                    borderRadius: "99px",
                    mixBlendMode: "initial",
                    background: "rgba(154, 135, 166, 0.54)",
                    boxShadow: `
                  0px 1px 0px 0px rgba(0, 0, 0, 0.05),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.05),
                  0px 10px 10px 0px rgba(0, 0, 0, 0.10)
                `,
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      padding: "1.1px",
                      borderRadius: "99px",
                      minWidth: "580px",
                      height: "52px",
                      borderColor: "transparent",
                      background:
                        "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), linear-gradient(5.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                    }}
                  />

                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12, xl: 12, lg: 12 }}
                    justifyContent="center"
                    alignItems="flex-end"
                    sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <Grid item xs={1} lg={1} md={1} sm={1}>
                      <Box
                        sx={{ paddingTop: "6px" }}
                        onClick={() => handleButtonClick("/")}
                      >
                        <img
                          src={GroupImage}
                          alt="GroupImage"
                          style={{
                            width: "31px",
                            height: "31px",
                            cursor: "pointer",
                            alignItems: "flex-end",
                            position: "relative",
                            bottom: "-5px",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={8} lg={9} md={8} sm={8}>
                      {!isSearchMode ? (
                        <Box
                          sx={{
                            width: "392px",
                            height: "100%",
                            // gap: "18px",
                            display: "flex",
                          }}
                        >
                          <Button
                            onClick={() => handleButtonClick("/homePage")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/homePage"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Home
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/movies")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/movies"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Movies
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/tvSeriesPage")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/tvSeriesPage"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            TV Series
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/songs")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/songs"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Songs
                          </Button>
                          <Button
                            onClick={() => handleButtonClick("/livetv")}
                            sx={{
                              ...buttonStyle,
                              ...(activeButton === "/livetv"
                                ? activeButtonStyle
                                : {}),
                            }}
                          >
                            Live TV
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            width: "392px",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "relative", // Add this to position the icon correctly
                          }}
                        >
                          <InputBase
                            value={searchText}
                            onChange={handleSearchChange}
                            placeholder="Search..."
                            classes="searchInput"
                            sx={{
                              paddingTop: "0",
                              paddingBottom: "0",
                            }}
                          />

                          <img
                            src={inputsearch}
                            alt="inputsearch"
                            style={{
                              position: "absolute",
                              left: "10px",
                            }}
                          />
                          {/* <SearchIcon
                      sx={{
                        position: 'absolute',
                        left: '10px', // Adjust this value to position the icon within the box
                        color: 'rgba(0, 0, 0, 0.54)', // Adjust the color if necessary
                      }}
                    /> */}
                        </Box>
                      )}
                    </Grid>

                    <Grid item xs={3} lg={2} md={3} sm={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "flex-end",
                        }}
                      >
                        {[
                          "/homePage",
                          "/movies",
                          "/songs",
                          "/tvSeriesPage",
                          "/livetv",
                          "/explore/movies",
                          "/explore/tvSeries",
                          "/explore/livetv",
                          "/explore/songssearch",

                          "/explore/popularsearch",
                        ].includes(activeButton) ? (
                          <>
                            <Button
                              style={{
                                width: "32px",
                                height: "100%",
                                display: "flex",
                                justifyContent: "end",
                              }}
                              // onClick={handleSearchClick}
                              onClick={handleSearchClick}
                            >
                              <img src={search} alt="searchIcon" />
                            </Button>
                            <Button
                              style={{
                                height: "34px",
                                display: "flex",
                                justifyContent: "end",
                                // alignItems: "flex-end",
                                padding: "0 !important",
                              }}
                              onClick={handleProfileClick}
                              // onClick={handleClickOpen}
                              // onClick={() => {
                              //   // Handle avatar icon click
                              // }}
                            >
                              <img src={profile} alt="profile" />
                            </Button>
                          </>
                        ) : (
                          <>
                            {/* <Button
                              style={{
                                ...buttonStyle,
                                width: "72px",
                                background: "transparent",
                                boxShadow: "none",
                              }}
                            >
                              Log in
                            </Button> */}
                            <Box>
                              {isLoggedIn ? (
                                <Button
                                  style={{
                                    height: "34px",
                                    display: "flex",
                                    justifyContent: "end",
                                    padding: "0 !important",
                                  }}
                                  onClick={handleProfileClick}
                                >
                                  <img src={profile} alt="profile" />
                                </Button>
                              ) : (
                                <Button
                                  onClick={handleClickOpen}
                                  style={{
                                    ...buttonStyle,
                                    width: "83px",
                                    padding:
                                      "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                                    gap: "var(--Buttongap)",
                                    borderRadius: "99px",
                                    border: "1px solid transparent",
                                    fontFamily: "Inter",
                                    background:
                                      "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                                    boxShadow:
                                      "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                                    fontSize: "14px",
                                    borderImage:
                                      "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                                    textTransform: "initial",
                                    backdropFilter: "blur(10px)",
                                    marginTop: "2px",
                                  }}
                                >
                                  Sign in
                                </Button>
                              )}
                            </Box>
                          </>
                        )}

                        {isProfileDropdownOpen && (
                          <Box
                            sx={{
                              height: { xs: "auto", md: "304.85px" },
                              borderRadius: "13.21px",
                              position: "absolute", // Changed to absolute positioning
                              background:
                                "linear-gradient(180deg, #5D4D69 43.91%, rgba(178, 159, 232, 0) 100%)",
                              width: {
                                xs: "100%",
                                sm: "300px",
                                md: "264.27px",
                              },
                              left: {
                                xs: 0,
                                md: "auto",
                                lg: "auto",
                                sm: "auto",
                              },
                              right: { xs: 0, md: "0", lg: "0", sm: "0" }, // Positioning adjustments
                              top: {
                                xs: "50px",
                                md: "90px",
                                lg: "90px",
                                sm: "50px",
                              },
                              backdropFilter: "blur(13.21367073059082px)",
                              zIndex: 9999, // Ensure it overlays other content
                            }}
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                borderRadius: "13.21px",
                                height: "100%",
                                top: "0px",
                                left: "0px",
                                right: "0px",
                                padding: "2px",
                                zIndex: "20",
                                overflow: "hidden",
                                background:
                                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%)",
                                borderImageSlice: "1",
                                mixBlendMode: "overlay",
                                WebkitMask:
                                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                                WebkitMaskComposite: "xor",
                                maskComposite: "exclude",
                                boxShadow: `
          0px 6.607px 13.214px 0px rgba(0, 0, 0, 0.10), 0px 19.821px 39.641px 0px rgba(0, 0, 0, 0.10), 0px 26.427px 52.855px 0px rgba(0, 0, 0, 0.15)
        `,
                              }}
                            />
                            <Box
                              sx={{
                                position: "relative",
                                zIndex: 30,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                                fontFamily: "Inter important",
                                fontWeight: 700,
                                padding: {
                                  xs: "16px",
                                  md: "24px",
                                  lg: "24px",
                                  sm: "16px",
                                },
                              }}
                            >
                              {[
                                {
                                  name: "Account",
                                  icon: (
                                    <img
                                      src={Accout}
                                      alt="account"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Wishlist",
                                  icon: (
                                    <img
                                      src={wishlist}
                                      alt="wishlist"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "History",
                                  icon: (
                                    <img
                                      src={History}
                                      alt="history"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Contact",
                                  icon: (
                                    <img
                                      src={Contact}
                                      alt="contact"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                                {
                                  name: "Sign Out",
                                  icon: (
                                    <img
                                      src={logout}
                                      alt="sign out"
                                      style={{ width: "24px", height: "24px" }}
                                    />
                                  ),
                                },
                              ].map((item, index) => (
                                <Box key={item.name}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      gap: "12px",
                                      borderLeft: "1.321px solid transparent",
                                      marginLeft: "8px",
                                      background: "transparent",
                                      transition: "background 0.3s ease",
                                      cursor: "pointer",
                                      paddingLeft: "8px", // Adds space between the border and content
                                      "&:hover": {
                                        background:
                                          "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                        borderLeft:
                                          "1.321px solid rgba(255, 255, 255, 1)",
                                      },
                                      "&:active": {
                                        background:
                                          "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                        borderLeft:
                                          "1.321px solid rgba(255, 255, 255, 1)",
                                      },
                                    }}
                                    onClick={() =>
                                      handleMenuClickProfile(item.name)
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        fontFamily: "Inter",
                                        fontSize: {
                                          xs: "16px",
                                          md: "18.499px",
                                        }, // Adjust font size for different screen sizes
                                        fontWeight: 500,
                                        color:
                                          item.name === "Sign Out"
                                            ? "rgba(255, 93, 239, 1) "
                                            : "rgba(255, 255, 255, 1)",
                                        paddingTop: "0px",
                                        paddingBottom: "0px",
                                      }}
                                    >
                                      {item.name}
                                    </Typography>
                                    <Box
                                      sx={{
                                        marginLeft: "auto", // Pushes the icon to the right
                                        color:
                                          item.name === "Sign Out"
                                            ? "rgba(219, 0, 126, 1)"
                                            : "rgba(255, 255, 255, 1)",
                                      }}
                                    >
                                      {item.icon}
                                    </Box>
                                  </Box>
                                  {index < 4 && (
                                    <Box
                                      sx={{
                                        borderRadius: "13.214px",
                                        background:
                                          "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                                        height: "1.321px",
                                        alignSelf: "stretch",
                                        margin: {
                                          xs: "4px 8px",
                                          md: "8px 16px",
                                        }, // Adjust margin for different screen sizes
                                      }}
                                    />
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid md={2} lg={2}></Grid>
          </Grid>
        </Container>
        {/* </Container> */}

        <Grid
          container
          sx={{
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
          justifyContent="center"
        >
          <Box
            sx={{
              margin: "auto",
              position: "relative",
              height: "42px",
              top: "30px",
              // width: "400px", // Adjust as needed
              width: "359px", // Adjust as needed
              WebkitMaskComposite: "xor",
              maskComposite: "exclude",
              borderRadius: "99px",
              mixBlendMode: "initial",
              background: "rgba(154, 135, 166, 0.54)",

              boxShadow:
                "0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
              left: 0,
              // backdrop-filter: blur(10px);
              backdropFilter: "blur(10px)",

              right: 0,
            }}
          >
            <Box
              style={{
                position: "absolute",
                overflow: "hidden !important",
                padding: "1.1px", // Adjust padding size
                borderRadius: "99px",
                // width: "400px", // Adjust as needed
                width: "360px", // Adjust as needed
                height: "42px",
                borderColor: "transparent",

                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                borderImageSlice: "1",
                mixBlendMode: "overlay",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              }}
            />

            <Grid
              container
              sx={{ display: { xs: "flex", md: "none", lg: "none" } }}
              columns={12}
            >
              <Grid item xs={6}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  startIcon={<MenuIcon />}
                  sx={{ color: "white", padding: "10px" }}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    "& .MuiPaper-root": {
                      backgroundColor: "#5E4E6C", // Set your desired background color here
                      width: "150px", // Set your desired width here
                      backdropFilter: "blur(10px)",
                      color: "white",
                      borderRadius: "9px",
                    },
                    "& .MuiMenuItem-root": {
                      color: "white", // Set the text color of MenuItem
                    },
                  }}
                >
                  {/* {pages.map((page) => (
                    <MenuItem key={page} onClick={handleClose}>
                      {page}
                    </MenuItem>
                  ))} */}

                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/homePage")}
                      sx={{
                        ...buttonStyle,
                        ...(activeButton === "/homePage"
                          ? activeButtonStyle
                          : {}),
                      }}
                    >
                      Home
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/movies")}
                      sx={{
                        ...buttonStyle,
                        ...(activeButton === "/movies"
                          ? activeButtonStyle
                          : {}),
                      }}
                    >
                      Movies
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/tvSeriesPage")}
                      sx={{
                        ...buttonStyle,
                        ...(activeButton === "/tvSeriesPage"
                          ? activeButtonStyle
                          : {}),
                      }}
                    >
                      TV Series
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/songs")}
                      sx={{
                        ...buttonStyle,
                        ...(activeButton === "/songs" ? activeButtonStyle : {}),
                      }}
                    >
                      Songs
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/livetv")}
                      sx={{
                        ...buttonStyle,
                        ...(activeButton === "/livetv"
                          ? activeButtonStyle
                          : {}),
                      }}
                    >
                      Live TV
                    </Button>
                  </MenuItem>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "flex-end",
                    }}
                  ></Box>
                </Menu>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                // onClick={() => handleButtonClick("/")}
              >
                <Box>
                  {isLoggedIn ? (
                    <Button
                      style={{
                        height: "43px",
                        display: "flex",
                        justifyContent: "end",
                      }}
                      onClick={handleProfileClick}
                    >
                      <img src={profile} alt="profile" />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleClickOpen}
                      style={{
                        ...buttonStyle,
                        width: "83px",
                        padding:
                          "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                        gap: "var(--Buttongap)",
                        borderRadius: "99px",
                        border: "1px solid transparent",
                        fontFamily: "Inter",
                        background:
                          "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                        boxShadow:
                          "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                        fontSize: "14px",
                        borderImage:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                        textTransform: "initial",
                        backdropFilter: "blur(10px)",
                        marginTop: "5px",
                      }}
                    >
                      Sign in
                    </Button>
                  )}
                </Box>

                {isProfileDropdownOpen && (
                  <Box
                    sx={{
                      height: { xs: "auto", md: "304.85px" },
                      borderRadius: "13.21px",
                      position: "absolute", // Changed to absolute positioning
                      background:
                        "linear-gradient(180deg, #5D4D69 43.91%, rgba(178, 159, 232, 0) 100%)",
                      width: {
                        xs: "100%",
                        sm: "300px",
                        md: "264.27px",
                      },
                      left: {
                        xs: 0,
                        md: "auto",
                        lg: "auto",
                        sm: "auto",
                      },
                      right: { xs: 0, md: "0", lg: "0", sm: "0" }, // Positioning adjustments
                      top: {
                        xs: "50px",
                        md: "50px",
                        lg: "90px",
                        sm: "50px",
                      },
                      backdropFilter: "blur(13.21367073059082px)",
                      zIndex: 9999, // Ensure it overlays other content
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        borderRadius: "13.21px",
                        height: "100%",
                        top: "0px",
                        left: "0px",
                        right: "0px",
                        padding: "2px",
                        zIndex: "20",
                        overflow: "hidden",
                        background:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%)",
                        borderImageSlice: "1",
                        mixBlendMode: "overlay",
                        WebkitMask:
                          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                        WebkitMaskComposite: "xor",
                        maskComposite: "exclude",
                        boxShadow: `
          0px 6.607px 13.214px 0px rgba(0, 0, 0, 0.10), 0px 19.821px 39.641px 0px rgba(0, 0, 0, 0.10), 0px 26.427px 52.855px 0px rgba(0, 0, 0, 0.15)
        `,
                      }}
                    />
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 30,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                        fontFamily: "Inter important",
                        fontWeight: 700,
                        padding: {
                          xs: "16px",
                          md: "24px",
                          lg: "24px",
                          sm: "16px",
                        },
                      }}
                    >
                      {[
                        {
                          name: "Account",
                          icon: (
                            <img
                              src={Accout}
                              alt="account"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Wishlist",
                          icon: (
                            <img
                              src={wishlist}
                              alt="wishlist"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "History",
                          icon: (
                            <img
                              src={History}
                              alt="history"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Contact",
                          icon: (
                            <img
                              src={Contact}
                              alt="contact"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Sign Out",
                          icon: (
                            <img
                              src={logout}
                              alt="sign out"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                      ].map((item, index) => (
                        <Box key={item.name}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "12px",
                              borderLeft: "1.321px solid transparent",
                              marginLeft: "8px",
                              background: "transparent",
                              transition: "background 0.3s ease",
                              cursor: "pointer",
                              paddingLeft: "8px", // Adds space between the border and content
                              "&:hover": {
                                background:
                                  "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                borderLeft:
                                  "1.321px solid rgba(255, 255, 255, 1)",
                              },
                              "&:active": {
                                background:
                                  "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                borderLeft:
                                  "1.321px solid rgba(255, 255, 255, 1)",
                              },
                            }}
                            onClick={() => handleMenuClickProfile(item.name)}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Inter",
                                fontSize: {
                                  xs: "16px",
                                  md: "18.499px",
                                }, // Adjust font size for different screen sizes
                                fontWeight: 500,
                                color:
                                  item.name === "Sign Out"
                                    ? "rgba(255, 93, 239, 1) "
                                    : "rgba(255, 255, 255, 1)",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: "auto", // Pushes the icon to the right
                                color:
                                  item.name === "Sign Out"
                                    ? "rgba(219, 0, 126, 1)"
                                    : "rgba(255, 255, 255, 1)",
                              }}
                            >
                              {item.icon}
                            </Box>
                          </Box>
                          {index < 4 && (
                            <Box
                              sx={{
                                borderRadius: "13.214px",
                                background:
                                  "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                                height: "1.321px",
                                alignSelf: "stretch",
                                margin: {
                                  xs: "4px 8px",
                                  md: "8px 16px",
                                }, // Adjust margin for different screen sizes
                              }}
                            />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid
          container
          sx={{
            display: {
              xs: "none",
              sm: "flex",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
          justifyContent="center"
        >
          <Box
            sx={{
              margin: "auto",
              position: "relative",
              height: "42px",
              top: "30px",
              width: "500px", // Adjust as needed
              WebkitMaskComposite: "xor",
              maskComposite: "exclude",
              borderRadius: "99px",
              mixBlendMode: "initial",
              background: "rgba(154, 135, 166, 0.54)",

              boxShadow:
                "0px 1px 0px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.05), 0px 10px 10px 0px rgba(0, 0, 0, 0.10)",
              left: 0,
              // backdrop-filter: blur(10px);
              backdropFilter: "blur(10px)",

              right: 0,
            }}
          >
            <Box
              style={{
                position: "absolute",
                overflow: "hidden !important",
                padding: "1.1px", // Adjust padding size
                borderRadius: "99px",
                width: "500px", // Adjust as needed
                height: "42px",
                borderColor: "transparent",

                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                borderImageSlice: "1",
                mixBlendMode: "overlay",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
              }}
            />

            <Grid
              container
              sx={{ display: { xs: "flex", md: "none", lg: "none" } }}
              columns={12}
            >
              <Grid item xs={6}>
                <Button
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleMenuClick}
                  startIcon={<MenuIcon />}
                  sx={{ color: "white", padding: "10px" }}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  sx={{
                    "& .MuiPaper-root": {
                      backgroundColor: "#5E4E6C", // Set your desired background color here
                      width: "150px", // Set your desired width here
                      backdropFilter: "blur(10px)",
                      color: "white",
                      borderRadius: "9px",
                    },
                    "& .MuiMenuItem-root": {
                      color: "white", // Set the text color of MenuItem
                    },
                  }}
                >
                  {/* {pages.map((page) => (
                    <MenuItem key={page} onClick={handleClose}>
                      {page}
                    </MenuItem>
                  ))} */}

                  {/* <Box
                        sx={{
                          width: "392px",
                          height: "32px",
                          gap: "20px",
                          // display: "flex",
                        }}
                      > */}
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/homePage")}
                      sx={{
                        ...buttonStyle,
                        ...(activeButton === "/homePage"
                          ? activeButtonStyle
                          : {}),
                      }}
                    >
                      Home
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/movies")}
                      sx={{
                        ...buttonStyle,
                        ...(activeButton === "/movies"
                          ? activeButtonStyle
                          : {}),
                      }}
                    >
                      Movies
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/tvSeriesPage")}
                      sx={{
                        ...buttonStyle,
                        ...(activeButton === "/tvSeriesPage"
                          ? activeButtonStyle
                          : {}),
                      }}
                    >
                      TV Series
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/songs")}
                      sx={{
                        ...buttonStyle,
                        ...(activeButton === "/songs" ? activeButtonStyle : {}),
                      }}
                    >
                      Songs
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button
                      onClick={() => handleButtonClick("/livetv")}
                      sx={{
                        ...buttonStyle,
                        ...(activeButton === "/livetv"
                          ? activeButtonStyle
                          : {}),
                      }}
                    >
                      Live TV
                    </Button>
                  </MenuItem>
                </Menu>
              </Grid>

              <Grid
                item
                xs={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                // onClick={() => handleButtonClick("/")}
              >
                <Box>
                  {isLoggedIn ? (
                    <Button
                      style={{
                        height: "43px",
                        display: "flex",
                        justifyContent: "end",
                        // padding: "0 !important",
                      }}
                      onClick={handleProfileClick}
                    >
                      <img src={profile} alt="profile" />
                    </Button>
                  ) : (
                    <Button
                      onClick={handleClickOpen}
                      style={{
                        ...buttonStyle,
                        width: "83px",
                        padding:
                          "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                        gap: "var(--Buttongap)",
                        borderRadius: "99px",
                        border: "1px solid transparent",
                        fontFamily: "Inter",
                        background:
                          "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                        boxShadow:
                          "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                        fontSize: "14px",
                        borderImage:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                        textTransform: "initial",
                        backdropFilter: "blur(10px)",
                        marginTop: "5px",
                      }}
                    >
                      Sign in
                    </Button>
                  )}
                </Box>
                {isProfileDropdownOpen && (
                  <Box
                    sx={{
                      height: { xs: "auto", md: "304.85px" },
                      borderRadius: "13.21px",
                      position: "absolute", // Changed to absolute positioning
                      background:
                        "linear-gradient(180deg, #5D4D69 43.91%, rgba(178, 159, 232, 0) 100%)",
                      width: {
                        xs: "100%",
                        sm: "300px",
                        md: "264.27px",
                      },
                      left: {
                        xs: 0,
                        md: "auto",
                        lg: "auto",
                        sm: "auto",
                      },
                      right: { xs: 0, md: "0", lg: "0", sm: "0" }, // Positioning adjustments
                      top: {
                        xs: "50px",
                        md: "50px",
                        lg: "90px",
                        sm: "50px",
                      },
                      backdropFilter: "blur(13.21367073059082px)",
                      zIndex: 9999, // Ensure it overlays other content
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        borderRadius: "13.21px",
                        height: "100%",
                        top: "0px",
                        left: "0px",
                        right: "0px",
                        padding: "2px",
                        zIndex: "20",
                        overflow: "hidden",
                        background:
                          "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #FFFFFF 89.19%)",
                        borderImageSlice: "1",
                        mixBlendMode: "overlay",
                        WebkitMask:
                          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                        WebkitMaskComposite: "xor",
                        maskComposite: "exclude",
                        boxShadow: `
          0px 6.607px 13.214px 0px rgba(0, 0, 0, 0.10), 0px 19.821px 39.641px 0px rgba(0, 0, 0, 0.10), 0px 26.427px 52.855px 0px rgba(0, 0, 0, 0.15)
        `,
                      }}
                    />
                    <Box
                      sx={{
                        position: "relative",
                        zIndex: 30,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "100%",
                        fontFamily: "Inter important",
                        fontWeight: 700,
                        padding: {
                          xs: "16px",
                          md: "24px",
                          lg: "24px",
                          sm: "16px",
                        },
                      }}
                    >
                      {[
                        {
                          name: "Account",
                          icon: (
                            <img
                              src={Accout}
                              alt="account"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Wishlist",
                          icon: (
                            <img
                              src={wishlist}
                              alt="wishlist"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "History",
                          icon: (
                            <img
                              src={History}
                              alt="history"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Contact",
                          icon: (
                            <img
                              src={Contact}
                              alt="contact"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                        {
                          name: "Sign Out",
                          icon: (
                            <img
                              src={logout}
                              alt="sign out"
                              style={{ width: "24px", height: "24px" }}
                            />
                          ),
                        },
                      ].map((item, index) => (
                        <Box key={item.name}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              gap: "12px",
                              borderLeft: "1.321px solid transparent",
                              marginLeft: "8px",
                              background: "transparent",
                              transition: "background 0.3s ease",
                              cursor: "pointer",
                              paddingLeft: "8px", // Adds space between the border and content
                              "&:hover": {
                                background:
                                  "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                borderLeft:
                                  "1.321px solid rgba(255, 255, 255, 1)",
                              },
                              "&:active": {
                                background:
                                  "linear-gradient(90deg, rgba(149, 45, 189, 0.69) 0%, rgba(255, 255, 255, 0) 100%)",
                                borderLeft:
                                  "1.321px solid rgba(255, 255, 255, 1)",
                              },
                            }}
                            onClick={() => handleMenuClickProfile(item.name)}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Inter",
                                fontSize: {
                                  xs: "16px",
                                  md: "18.499px",
                                }, // Adjust font size for different screen sizes
                                fontWeight: 500,
                                color:
                                  item.name === "Sign Out"
                                    ? "rgba(255, 93, 239, 1) "
                                    : "rgba(255, 255, 255, 1)",
                                paddingTop: "0px",
                                paddingBottom: "0px",
                              }}
                            >
                              {item.name}
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: "auto", // Pushes the icon to the right
                                color:
                                  item.name === "Sign Out"
                                    ? "rgba(219, 0, 126, 1)"
                                    : "rgba(255, 255, 255, 1)",
                              }}
                            >
                              {item.icon}
                            </Box>
                          </Box>
                          {index < 4 && (
                            <Box
                              sx={{
                                borderRadius: "13.214px",
                                background:
                                  "radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%)",
                                height: "1.321px",
                                alignSelf: "stretch",
                                margin: {
                                  xs: "4px 8px",
                                  md: "8px 16px",
                                }, // Adjust margin for different screen sizes
                              }}
                            />
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Container>
          <Grid
            container
            justifyContent="center"
            spacing={5}
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
                xxl: "flex",
              },
            }}
          >
            <Grid
              item
              md={2}
              lg={2}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <img
                src={logo}
                alt="logo"
                onClick={() => handleButtonClick("/")}
                style={{
                  top: "20px",
                  position: "relative",
                  marginLeft: "-100px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid
              item
              md={10}
              lg={10}
              sx={{ display: "flex", justifyContent: "flex-start" }}
            >
              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    position: "relative",
                    height: "44px",
                    top: "30px",
                    width: "10px",
                    borderRadius: "99px",
                    mixBlendMode: "initial",
                    background: "rgba(154, 135, 166, 0.54)",
                    boxShadow: `
                  0px 1px 0px 0px rgba(0, 0, 0, 0.05),
                  0px 4px 4px 0px rgba(0, 0, 0, 0.05),
                  0px 10px 10px 0px rgba(0, 0, 0, 0.10)
                `,
                    backdropFilter: "blur(10px)",
                  }}
                >
                  <Box
                    style={{
                      position: "absolute",
                      overflow: "hidden",
                      padding: "1.1px",
                      borderRadius: "99px",
                      width: "100px",
                      height: "44px",
                      borderColor: "transparent",
                      background:
                        "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), linear-gradient(5.22deg, rgba(255, 255, 255, 0) 33.61%, #C7ADE9 89.19%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                    }}
                  />

                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12, xl: 12, lg: 12 }}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ paddingLeft: "10px", paddingRight: "10px" }}
                  >
                    <Grid item xs={1} lg={1} md={1} sm={1}>
                      <Box
                        sx={{ paddingTop: "6px" }}
                        onClick={() => handleButtonClick("/")}
                      >
                        <img
                          src={GroupImage}
                          alt="GroupImage"
                          style={{
                            width: "31px",
                            height: "31px",
                            cursor: "pointer",
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={8} lg={8} md={8} sm={8}>
                      <Box
                        sx={{
                          width: "392px",
                          height: "32px",
                          gap: "20px",
                          display: "flex",
                        }}
                      >
                        <Button
                          onClick={() => handleButtonClick("/homePage")}
                          sx={{
                            ...buttonStyle,
                            ...(activeButton === "/homePage"
                              ? activeButtonStyle
                              : {}),
                          }}
                        >
                          Home
                        </Button>
                        <Button
                          onClick={() => handleButtonClick("/movies")}
                          sx={{
                            ...buttonStyle,
                            ...(activeButton === "/movies"
                              ? activeButtonStyle
                              : {}),
                          }}
                        >
                          Movies
                        </Button>
                        <Button
                          onClick={() => handleButtonClick("/tvSeriesPage")}
                          sx={{
                            ...buttonStyle,
                            ...(activeButton === "/tvSeriesPage"
                              ? activeButtonStyle
                              : {}),
                          }}
                        >
                          TV Series
                        </Button>
                        <Button
                          onClick={() => handleButtonClick("/livetv")}
                          sx={{
                            ...buttonStyle,
                            ...(activeButton === "/livetv"
                              ? activeButtonStyle
                              : {}),
                          }}
                        >
                          Live TV
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={3} lg={3} md={3} sm={3}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "center",
                        }}
                      >
                        {["/homePage", "/movies", "/tvSeriesPage"].includes(
                          activeButton
                        ) ? (
                          <>
                            <Button
                              style={{
                                width: "32px",
                                height: "32px",
                                display: "flex",
                                justifyContent: "end",
                              }}
                              onClick={() => {
                                // Handle search icon click
                              }}
                            >
                              <img src={search} alt="searchIcon" />
                            </Button>
                            <Button
                              style={{
                                height: "45px",
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                padding: "0 !important",
                              }}
                              onClick={handleProfileClick}
                            >
                              <img src={profile} alt="profile" />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              style={{
                                ...buttonStyle,
                                width: "72px",
                                background: "transparent",
                                boxShadow: "none",
                              }}
                            >
                              Log in
                            </Button>
                            <Button
                              style={{
                                ...buttonStyle,
                                width: "83px",
                                padding:
                                  "var(--Buttonvertical-padding) var(--Buttonhorizontal-padding)",
                                gap: "var(--Buttongap)",
                                borderRadius: "99px",
                                border: "1px solid transparent",
                                fontFamily: "Inter",
                                background:
                                  "linear-gradient(to left,#00000080,#00000080) padding-box,linear-gradient(360deg,#3B2E40,#5A4E6C) border-box",
                                boxShadow:
                                  "0px 10px 10px 0px #0000001A, 0px 4px 4px 0px #0000000D, 0px 1px 0px 0px #0000000D",
                                fontSize: "14px",
                                borderImage:
                                  "linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)),linear-gradient(35.22deg, rgba(255, 255, 255, 0) 33.61%, #7950aa 89.19%)",
                                textTransform: "initial",
                                backdropFilter: "blur(10px)",
                                marginTop: "2px",
                              }}
                            >
                              Sign in
                            </Button>
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid md={2} lg={2}></Grid>
          </Grid>
        </Container>
      </AppBar>

      <AuthDialog
        open={open}
        handleCloseDialog={handleCloseDialog}
        handleSend={handleSend}
        phone={phone}
        setPhone={setPhone}
        code={code}
        handleCloseCode={handleCloseCode}
        verificationCode={verificationCode}
        otp={otp}
        setOtp={setOtp} // Pass setOtp here
        handleInputChange={handleInputChange}
        verifyOtp={verifyOtp}
        resendTimer={resendTimer}
        handleResendCode={handleResendCode}
        login={login}
      />
    </>
  );
};

export default Header;
