import axiosInstance from '../../axiosInstance';
import { apiEndpoint } from "../../components/data/config";


  
  export const web_landingpage = async () => {
    const config = {
      method: "get",
      url: `${apiEndpoint}/api/web_landing_page`,
     
    };
    return axiosInstance(config); // Use axiosInstance instead of axios
  };

