import React, { useEffect, useState } from "react";
import { Divider, Grid, Typography } from "@mui/material";

import { home_web } from "./homeState";
import { Link, useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';

const Series = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [hoverStates, setHoverStates] = useState({});
  // const handleClick = (content_id, content_type_id) => {
  //   console.log(content_id, content_type_id, "dataa");
  //   navigate(
  //     `/videoPlayer?content_id=${content_id}&content_type_id=${content_type_id}`
  //   );
  // };

  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };


  const handleClick = (content_id, content_type_id) => {
    // Construct the query string
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;
    
    // Encrypt the query string
    const encryptedQuery = encryptQueryString(queryString);

    // Navigate to the new page with the encrypted query string
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };

  useEffect(() => {
    home_web()
      .then((res) => {
        console.log(res, "resss");
        // Assuming the response structure is correct, extract the list of images
        const data = res.data;
        if (data && data.length > 0) {
          const tvSection = data.find(
            (section) => section.title === "Tv Series"
          );
          if (tvSection && tvSection.list) {
            setImages(tvSection.list);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  const handleHover = (index, isHovered) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [index]: isHovered,
    }));
  };
  return (
    <div>
      <Grid
        sx={{
          position: "relative",

          paddingTop: "4rem",
          paddingBottom: "4rem",

          paddingLeft: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
          paddingRight: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
        }}
      >
        {/* <Grid classes="content_gradient_sub"></Grid>
        <Grid classes="recent_gradient"></Grid> */}

        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="subTitle1"> Tv Series</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Link to="/tvSeriesPage" style={{ textDecoration: "none" }}>
              <Typography variant="subTitle2">View All</Typography>
            </Link>
          </Grid>
        </Grid>
        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
          }}
        />

        <Grid
          container
          // spacing={{ xs: 2, sm: 6, md: 1 }}
          rowSpacing={{ xs: 2, sm: 6, md: 5 }}
          columnSpacing={{ xs: 2, sm: 1, md: 1, lg: 1.5 }}
          columns={{ xs: 4, sm: 8, md: 14, lg: 14 }}
          pt={5}
        >
          <Grid classes="content_gradient_subgradinet"></Grid>

          {images.map((image, index) => (
            <Grid item xs={12} sm={2} md={2} lg={2} key={index}>
              <div
                style={{
                  position: "relative",
                  overflow: "hidden",
                  cursor: "pointer",
                  borderRadius: "8px",
                  width: "100%",
                  height: "0",
                  paddingBottom: "150.25%", // This maintains a 16:9 aspect ratio
                }}
                onMouseEnter={() => handleHover(index, true)}
                onMouseLeave={() => handleHover(index, false)}
                onClick={() =>
                  handleClick(image.content_id, image.content_type_id)
                }
              >
                <img
                  src={image.thumbnail}
                  alt={`Thumbnail ${index}`}
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    objectFit: "cover",
                    transition: "transform 0.3s ease-in-out",
                    transform: hoverStates[index] ? "scale(1.05)" : "scale(1)", // Slight zoom
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "100%", // Full height of the container
                    // background: "linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%)", // Black gradient
                    background:
                      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 100%)", // Custom gradient

                    color: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end", // Align content to the bottom
                    padding: "0.5rem",
                    opacity: hoverStates[index] ? 1 : 0,
                    transition:
                      "opacity 0.3s ease-in-out, background 0.3s ease-in-out",

                    pointerEvents: "none", // Prevents the overlay from blocking clicks
                    boxSizing: "border-box", // Ensure padding does not affect height calculation
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "8px", // Small screens
                        sm: "8px", // Medium screens
                        md: "8px", // Large screens
                        lg: "12px",
                        xl: "20px",
                      },
                      lineHeight: {
                        xs: "8px", // Small screens
                        sm: "16px", // Medium screens
                        md: "30px", // Large screens
                        lg: "34px",
                        xl: "34px",
                      },

                      color: "white",
                      padding: 0,
                      margin: 0,
                      fontFamily: "inter !important",
                      textOverflow: "ellipsis", // Handle text overflow
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide overflowed text
                    }}
                  >
                    {image.content_title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        xs: "8px", // Small screens
                        sm: "6px", // Medium screens
                        md: "6px", // Large screens
                        lg: "10px",
                        xl: "12px",
                      },
                      lineHeight: {
                        xs: "8px", // Small screens
                        sm: "8px", // Medium screens
                        md: "6px", // Large screens
                        lg: "8px",
                        xl: "20px",
                      },
                      padding: 0,
                      margin: "0",
                      fontFamily: "inter !important",
                      color: "white",
                      // textOverflow: "ellipsis", // Handle text overflow
                      // whiteSpace: "nowrap", // Prevent text wrapping
                      // overflow: "hidden", // Hide overflowed text

                      display: "-webkit-box", // Enable flexbox for truncation
                      WebkitLineClamp: 3, // Limit to 3 lines
                      WebkitBoxOrient: "vertical", // Set box orientation
                      overflow: "hidden",
                    }}
                  >
                    {image.content_description}
                  </Typography>
                  {/* <Typography sx={{  fontSize: {
xs: "14px", // Small screens
sm: "15px", // Medium screens
md: "16px", // Large screens
},
      
      
      
      color: "white", padding: 0, margin: 0 ,textAlign:"end",fontFamily:"inter !important"}}>
      Duration: 28m
    </Typography> */}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default Series;
