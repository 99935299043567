// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './searchSlice';
import authReducer from './authSlice';
import videoReducer from './videoSlice'; // Import the video reducer

const store = configureStore({
  reducer: {
    search: searchReducer,
    auth: authReducer,
    video: videoReducer, // Add video reducer to the store
  },
});

export default store;
