import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "./carousels.css";
import { Link, useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axios from "axios";
import DialogImage from "../../pages/images/dialoglogo.png";
import googleicons from "../../pages/images/buttongoogle.png";
import AppleBuuton from "../../pages/images/Button Apple.png";
import {
  AppBar,
  Checkbox,
  Grid,
  FormControlLabel,
  Box,
  InputBase,
  TextField,
  Button,
} from "@mui/material";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../../fireBase/setup"; // Import the initialized auth object
import debounce from "lodash.debounce";
import axiosInstance from "../../axiosInstance";
import { savePlaybackHistory } from "../../components/layout/Footer/profile/profileState";

// import AppleComputers from '../images/Apple display.png'

import { Container, Typography } from "@mui/material";
import { web_landingpage } from "./landingState";

const Carousels = () => {
  const swiperRef = useRef(null);

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleCloseCode = () => {
    setCode(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [resendTimer, setResendTimer] = useState(55);

  // Effect to handle countdown
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);
  //Profile

  const navigate = useNavigate();

  // import AppleComputers from '../images/Apple display.png'

  const [carousel, setCarousel] = useState(null);

  useEffect(() => {
    web_landingpage()
      .then((res) => {
        console.log(res);
        const data = res.data;
        if (data && data.length > 0) {
          const desiredSection = data.find(
            (section) => section.template_type === "carousel"
          );
          if (desiredSection) {
            setCarousel(desiredSection);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  if (!carousel) return null;

  const handleClick = (content_id, content_type_id) => {
    const token = localStorage.getItem("auth_token"); // Check if token exists in localStorage

    if (token) {
      // User is logged in, navigate to the video player
      navigate(
        `/videoPlayer?content_id=${content_id}&content_type_id=${content_type_id}`
      );
    } else {
      // User is not logged in, show alert and stay on the page
      setOpen(true);
    }
  };
  const login = async () => {
    const provider = new GoogleAuthProvider();

    try {
      // Trigger Google Sign-In popup
      const result = await signInWithPopup(auth, provider);

      // Get user info from Google result
      const user = result.user;
      const token = await user.getIdToken(); // Firebase token if needed for future use

      const userData = {
        name: user.displayName,
        email: user.email,
        phone_number: user.phoneNumber,
        birth_date: "1990-01-01", // Example birth date, change as required
      };

      // Post user data to your custom backend API
      const response = await axios.post(
        "https://backend.charismainfotainment.com/api/app_social_login",
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Optional: if you want to pass the Firebase token
          },
        }
      );

      if (response.status === 200) {
        // Check the structure of response.data
        console.log("Login response data:", response.data);

        // Access the token from the response data
        const { token: backendToken } = response.data.data; // Adjust based on actual response structure

        if (backendToken) {
          // Store the token in localStorage
          localStorage.setItem("auth_token", backendToken);
          console.log("Token stored in local storage:", backendToken);

          // Handle successful login response
          setOpen(false);
          navigate("/homePage");
          // alert("Login is successful");
        } else {
          console.error("No token found in response data.");
        }
      } else {
        // Handle non-200 status
        console.error("Failed to login", response.status, response.data);
      }
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
    }
  };

  return (
    <div>
      <div className="container">
        <Typography
          variant="h4"
          sx={{
            marginBottom: "5rem",
          }}
        >
          {/* Don't Miss Latest Gospel & <br />
          Prophecies to Pray */}
          {carousel.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter !important",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "32px",
            textAlign: "center",
            color: "rgba(255, 255, 255, 1)",
            marginTop: "-75px",
          }}
        >
          {carousel.title2}
          {/* The Biggest Edification platform for end time Generation */}
        </Typography>
        <div
          style={{
            width: "450px",
            height: "400px",
            justifyContent: "center",
            display: "flex",
            left: "-5%",
            gap: "0px",
            marginTop: "10rem",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(180px)",
            position: "absolute",
          }}
        ></div>

        <Container></Container>

        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          ref={swiperRef}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 500,
            modifier: 2.5,
            slideShadows: true,
            crossFade: true,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
          breakpoints={{
            0: {
              slidesPerView: 0,
              centeredSlides: true,
            },
            768: {
              slidesPerView: 3,
              centeredSlides: true,
            },
          }}
        >
          {carousel.list.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image}
                alt={`slide_image_${index + 1}`}
                className="custom-slide-img"  // Apply the custom class here
                // style={{
                //   borderRadius: "34.05px !important",
                // }}
                // onClick={() => handleClick(image.content_id, image.content_type_id)}
              />
            </SwiperSlide>
          ))}

          <div className="slider-controler">
            <div
              className="swiper-button-prev slider-arrow"
              onClick={handlePrev}
            >
              <ion-icon
                name="arrow-back-outline"
                style={{ color: "white" }}
              ></ion-icon>
            </div>
            <div
              className="swiper-button-next slider-arrow"
              onClick={handleNext}
            >
              <ion-icon
                name="arrow-forward-outline"
                style={{ color: "white" }}
              ></ion-icon>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </div>
      <React.Fragment>
        <Grid item xs={2} sm={4} md={4}></Grid>
        <Grid item xs={10} sm={4} md={4}>
          <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleCloseDialog}
            sx={{
              "& .MuiDialog-paper": {
                // width: "457px",
                width: {
                  xs: "100%",
                  sm: "300px",
                  md: "300px",
                  lg: "360px",
                  xl: "457px",
                },
                height: {
                  xs: "100%",
                  sm: "300px",
                  md: "600px",
                  lg: "490px",
                  xl: "600.72px",
                },
                // height: "600.72px",
                borderRadius: "34.93px",
                borderWidth: "2.33px 0px 0px 0px",
                borderStyle: "solid",
                background:
                  "linear-gradient(181.35deg, rgba(140, 115, 209, 0.5) 1.15%, rgba(179, 177, 200, 0.2) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
                boxShadow: "0px 4.66px 4.66px 0px #00000040",
                position: "relative", // Allows `top` and `left` to take effect
                backdropFilter: "blur(40.749961853027344px)", // Applies a blur effect
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
                opacity: 1, // Adjust opacity to ensure visibility
                overflow: "hidden",
                padding: {
                  xs: "100%",
                  sm: "1rem",
                  md: "1rem",
                  lg: "1rem",
                  xl: "3rem",
                },
                top: {
                  xs: "100%",
                  sm: "1rem",
                  md: "1rem",
                  lg: "3rem",
                  xl: "0",
                },
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                borderRadius: "34.93px",
                // width: "460px",
                width: {
                  xs: "100%",
                  sm: "300px",
                  md: "300px",
                  lg: "360px",
                  xl: "460px",
                },
                // height: "590px",
                height: {
                  xs: "100%",
                  sm: "300px",
                  md: "00px",
                  lg: "490px",
                  xl: "590px",
                },
                top: "-1px",
                left: "-1px",
                right: "-1px",
                padding: "3px",
                zIndex: "20",
                overflow: "hidden",
                background:
                  "linear-gradient(142.14deg, #C892FF 2.97%, rgba(0, 0, 0, 0) 51.41%)",
                borderImageSlice: "1",
                mixBlendMode: "overlay",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
                boxShadow: "0px 4.66px 4.66px 0px #00000040",
              }}
            />
            <Box
              sx={{
                position: "relative",
                zIndex: 30,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center", // Center content horizontally
                height: "100%",
                fontFamily: "Inter important",
                fontWeight: 700,
                gap: "1rem", // Add gap between elements for spacing
              }}
            >
              <img
                src={DialogImage}
                alt="dialog"
                style={{
                  width: "50%",
                  height: "auto",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center", // Center text within the dialog title
                  width: "100%", // Ensures full width for text alignment
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    // fontSize: "30px",
                    fontWeight: 300,
                    lineHeight: "33.82px",
                    letterSpacing: "-0.07em",
                    textAlign: "center",
                    color: "#F4F4F4",
                    padding: "0",
                    fontSize: {
                      xs: "28px",
                      sm: "24px",
                      md: "24px",
                      lg: "30px",
                      xl: "30px",
                    },
                  }}
                >
                  Welcome to Evangel
                </Typography>
              </DialogTitle>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    // marginBottom: "14px",
                    fontFamily: "Inter",
                    fontSize: "11.27px",
                    fontWeight: "400",
                    lineHeight: "16.91px",
                    color: "#FFDBFF",
                  }}
                >
                  Enter your Mobile number
                </Typography>
                <TextField
                  placeholder="Mobile number"
                  variant="outlined"
                  fullWidth
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  sx={{
                    display: "flex",
                    height: "40px", // Increased height for better spacing
                    padding: "9.865px 11.274px",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "7.046px",
                    borderRadius: "26.036px",
                    backgroundColor: "#FFF",
                    alignSelf: "stretch",
                    "& .MuiInputBase-root": {
                      padding: "0", // Removes default padding from TextField input
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "transparent", // Removes the default border
                      },
                      "&:hover fieldset": {
                        borderColor: "transparent", // Removes hover border
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "transparent", // Removes focus border
                      },
                    },
                  }}
                  InputProps={{
                    sx: {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%", // Ensures input field stretches to full height
                    },
                  }}
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        width: "11.1px",
                        height: "11.1px",
                        borderRadius: "3.17px",
                        border: "0.79px solid #CCCCCC",
                        padding: "0", // Remove padding around checkbox
                        marginLeft: "16px",
                        // marginTop: "10px",
                      }}
                      disableRipple // Removes the ripple effect
                      checkedIcon={
                        <span
                          style={{
                            display: "flex",
                            backgroundColor: "#CCCCCC",
                            borderRadius: "3.17px",
                            border: "0.79px solid #CCCCCC",
                            opacity: "0",
                          }}
                        />
                      }
                      icon={
                        <span
                          style={{
                            display: "flex",
                            borderRadius: "3.17px",
                            border: "0.79px solid #CCCCCC",
                            opacity: "1",
                          }}
                        />
                      }
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontFamily: "Inter",
                        fontSize: "12.87px",
                        fontWeight: "500",
                        lineHeight: "14.09px",
                        // textAlign: "center",
                        color: "#888888",
                        // marginTop: "10px",
                      }}
                    >
                      Remember me
                    </Typography>
                  }
                  sx={{
                    // marginTop: "1rem",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem", // Add spacing between the checkbox and label
                  }}
                />

                <DialogActions
                  sx={{
                    width: "100%", // Ensures full width for button alignment
                    flexDirection: "column", // Stack buttons vertically
                    gap: "0.5rem", // Add space between buttons
                    // marginTop: "2rem",
                    marginTop: {
                      xs: "100%",
                      sm: "1rem",
                      md: "1rem",
                      lg: "0rem",
                      xl: "1rem",
                    },
                    padding: "0px",
                  }}
                >
                  <Button
                    //  onClick={handlePhoneSignIn}
                    sx={{
                      width: "100%",
                      height: "40px",
                      padding: "9.865px 11.274px",
                      gap: "10.77px",
                      borderRadius: "26.04px",
                      opacity: 1,
                      boxShadow: "0px 10.77px 32.31px 0px #1B0C57",
                      background:
                        "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
                      color: "#fff",
                      fontSize: "13.02px",
                      lineHeight: "15.75px",
                      border: "1.08px solid transparent",
                      textTransform: "inherit",
                    }}
                    // onClick={handleCodeOpen}
                  >
                    SignIn
                  </Button>
                  <Button
                    onClick={() => login()}
                    // onClick={handleClose}
                    sx={{
                      width: "100%",
                      height: "40px",
                      padding: "9.865px 11.274px",
                      gap: "10.77px",
                      borderRadius: "26.04px",
                      opacity: 1,
                      background: "#fff",
                      color: "black",
                      textTransform: "inherit",
                      fontSize: "13.02px",
                      lineHeight: "15.75px",
                      "&:hover": {
                        background: "#fff", // Maintain the same background color on hover
                        color: "black", // Maintain the same text color on hover
                        boxShadow: "none", // Remove any shadow changes on hover
                      },
                    }}
                  >
                    <img src={googleicons} alt="googleicons" />
                    Sign In With Google
                  </Button>

                  {/* <GoogleLogin
  onSuccess={credentialResponse => {
    console.log(credentialResponse);
  }}
  onError={() => {
    console.log('Login Failed');
  }}
/>; */}
                  <Button
                    onClick={handleClose}
                    sx={{
                      width: "100%",
                      height: "40px",
                      padding: "9.865px 11.274px",
                      gap: "10.77px",
                      borderRadius: "26.04px",
                      opacity: 1,
                      background: "black",
                      color: "white",
                      textTransform: "inherit",
                      fontSize: "13.02px",
                      lineHeight: "15.75px",
                      "&:hover": {
                        background: "black", // Maintain the same background color on hover
                        color: "white", // Maintain the same text color on hover
                        boxShadow: "none", // Remove any shadow changes on hover
                      },
                    }}
                  >
                    <img src={AppleBuuton} alt="AppleBuuton" />
                    Sign In With Apple
                  </Button>
                </DialogActions>
              </DialogContent>
            </Box>
          </Dialog>

          <Dialog
            fullScreen={false}
            open={code} // Correctly using the `code` state to control visibility
            onClose={handleCloseCode} // Correct handler for closing the dialog
            sx={{
              "& .MuiDialog-paper": {
                width: "457px",
                // height: "582.72px",
                height: "600.72px",
                borderRadius: "34.93px",
                borderWidth: "2.33px 0px 0px 0px",
                borderStyle: "solid",
                background:
                  "linear-gradient(181.35deg, rgba(140, 115, 209, 0.5) 1.15%, rgba(179, 177, 200, 0.2) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
                boxShadow: "0px 4.66px 4.66px 0px #00000040",
                position: "relative", // Allows `top` and `left` to take effect
                backdropFilter: "blur(40.749961853027344px)", // Applies a blur effect
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
                opacity: 1, // Adjust opacity to ensure visibility
                overflow: "hidden",
                padding: "3rem", // Add padding around the entire dialog content

                "@media only screen and (min-width: 1024px) and (max-width: 1800px)":
                  {
                    marginTop: "6rem",

                    padding: "0px 20px",
                    width: "457px",
                    height: "582.72px",
                  },

                "@media only screen and (min-width: 320px) and (max-width: 600px)":
                  {
                    marginTop: "6rem",

                    width: "350px",
                    height: "582.72px",
                  },
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                borderRadius: "34.93px",
                width: "460px",
                height: "100%",
                top: "-1px",
                left: "-1px",
                right: "-1px",
                padding: "3px",
                zIndex: "20",
                overflow: "hidden",
                background:
                  "linear-gradient(142.14deg, #C892FF 2.97%, rgba(0, 0, 0, 0) 51.41%)",
                borderImageSlice: "1",
                mixBlendMode: "overlay",
                WebkitMask:
                  "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                WebkitMaskComposite: "xor",
                maskComposite: "exclude",
                boxShadow: "0px 4.66px 4.66px 0px #00000040",

                "@media only screen and (min-width: 320px) and (max-width: 600px)":
                  {
                    width: "350px",
                    height: "582.72px",
                  },
              }}
            />
            <Box
              sx={{
                position: "relative",
                zIndex: 30,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center", // Center content horizontally
                height: "100%",
                fontFamily: "Inter important",
                fontWeight: 700,
                gap: "1rem", // Add gap between elements for spacing
              }}
            >
              <img
                src={DialogImage} // Replace with actual image path
                alt="dialog"
                style={{
                  width: "50%",
                  height: "auto",
                }}
              />
              <DialogTitle
                sx={{
                  textAlign: "center", // Center text within the dialog title
                  width: "100%", // Ensures full width for text alignment
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "30px",
                    fontWeight: 300,
                    lineHeight: "33.82px",
                    letterSpacing: "-0.07em",
                    textAlign: "center",
                    color: "#F4F4F4",
                  }}
                >
                  Welcome to Evangel
                </Typography>
              </DialogTitle>
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography
                  sx={{
                    marginBottom: "14px",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: "700",
                    lineHeight: "29px",
                    color: "#FFF",
                    textAlign: "center",
                  }}
                >
                  Enter Verification Code
                </Typography>

                {/* Verification Code Input Fields */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    justifyContent: "center",
                    marginBottom: "1rem",
                  }}
                >
                  {verificationCode.map((code, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      // value={code}
                      // onChange={(e) => handleInputChange(index, e.target.value)}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP"
                      type="text"
                      maxLength={1}
                      style={{
                        width: "35px",
                        height: "42px",
                        // padding: "8px 0px",
                        textAlign: "center",
                        fontSize: "16px",
                        border: "none",
                        borderBottom: "1px solid rgba(255, 255, 255, 0.47)",
                        // boxShadow: "0px 30px 30px 0px rgba(0, 0, 0, 0.15)",
                        outline: "none",
                        backgroundColor: "transparent",
                        color: "#FFF",
                        display: "flex",
                        alignItems: "center",
                        // backdropFilter: "blur(30px)",
                      }}
                    />
                  ))}
                </Box>

                {/* Countdown Timer */}
                <Typography
                  // onClick={resendTimer === 0 ? handleResendCode : null} // Allow click only when timer is 0
                  sx={{
                    textAlign: "center",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    color: resendTimer === 0 ? "#FF69B4" : "#FFF", // Change color to pink when clickable
                    cursor: resendTimer === 0 ? "pointer" : "default", // Change cursor when clickable
                    "&:hover": {
                      textDecoration: resendTimer === 0 ? "underline" : "none", // Add underline on hover if clickable
                    },
                  }}
                >
                  {resendTimer > 0
                    ? `Resend code in ${resendTimer} s`
                    : "Resend OTP"}
                </Typography>

                <DialogActions
                  sx={{
                    width: "100%", // Ensures full width for button alignment
                    flexDirection: "column", // Stack buttons vertically
                    gap: "0.5rem", // Add space between buttons
                    marginTop: "2rem",
                    padding: "0px",
                  }}
                >
                  <Button
                    // // onClick={handleCodeOpen}
                    // onClick={handleOtpVerification}
                    sx={{
                      width: "100%",
                      height: "40px",
                      padding: "9.865px 11.274px",
                      gap: "10.77px",
                      borderRadius: "26.04px",
                      opacity: 1,
                      boxShadow: "0px 10.77px 32.31px 0px #1B0C57",
                      background:
                        "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
                      color: "#fff",
                      fontSize: "13.02px",
                      lineHeight: "15.75px",
                      border: "1.08px solid transparent",
                      textTransform: "inherit",
                      backdropFilter: "blur(5.385599613189697px)",
                    }}
                  >
                    Verify
                  </Button>
                </DialogActions>
              </DialogContent>
            </Box>
          </Dialog>
        </Grid>
        <Grid item xs={2} sm={4} md={4}></Grid>
      </React.Fragment>
    </div>
  );
};

export default Carousels;
