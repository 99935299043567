import React, { useState, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, TextField, FormControlLabel, Checkbox } from '@mui/material';
// import DialogImage from "../../pages/images/dialogboxlogo.png";
import DialogImage from "../pages/images/dialogboxlogo.png";
import googleicons from "../pages/images/buttongoogle.png";

const AuthDialog = ({
  open, 
  handleCloseDialog, 
  handleSend, 
  phone, 
  setPhone, 
  code, 
  handleCloseCode, 
  verificationCode, 
  otp, 
  setOtp, // Added to manage otp state
//   handleInputChange, 
  verifyOtp, 
  resendTimer, 
  handleResendCode,
  login,
}) => {
    const inputRefs = useRef([]);

    const focusNext = (index) => {
        if (index < otp.length - 1) {
          inputRefs.current[index + 1]?.focus();
        }
      };
    
      // Function to focus the previous input field
      const focusPrevious = (index) => {
        if (index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
      };
    
      // Handle input change and focus next or previous input
      const handleInputChange = (index, e) => {
        const value = e.target.value;
    
        if (value.length === 1) {
          // Update OTP array and move focus to next input
          const newOtp = [...otp];
          newOtp[index] = value;
          setOtp(newOtp);
          focusNext(index);
        } else if (value.length === 0) {
          // Update OTP array and move focus to previous input
          const newOtp = [...otp];
          newOtp[index] = '';
          setOtp(newOtp);
          focusPrevious(index);
        }
      };
  return (
    <React.Fragment>
      {/* First Dialog */}
     <Dialog
    fullScreen={false}
    open={open}
    onClose={handleCloseDialog}
    sx={{
      "& .MuiDialog-paper": {
        // width: "457px",
        width: {
          xs: "90%",
          sm: "320px",
          md: "320px",
          lg: "360px",
          xl: "400px",
        },
        height: {
          xs: "60%",
          sm: "450px",
          md: "450px",
          lg: "490px",
          // xl: "600.72px",
          xl: "520px",
        },
        // height: "600.72px",
        borderRadius: "34.93px",
        borderWidth: "2.33px 0px 0px 0px",
        // borderStyle: "solid",
        background:
          "linear-gradient(181.35deg, rgba(140, 115, 209, 0.5) 1.15%, rgba(179, 177, 200, 0.2) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
        boxShadow: "0px 4.66px 4.66px 0px #00000040",
        position: "relative", // Allows `top` and `left` to take effect
        backdropFilter: "blur(40.749961853027344px)", // Applies a blur effect
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
        opacity: 1, // Adjust opacity to ensure visibility
        overflow: "hidden",
        padding: {
          xs: "16px",
          sm: "1rem",
          md: "1rem",
          lg: "1rem",
          xl: "3rem",
        },
        top: {
          xs: "1%",
          sm: "1rem",
          md: "1rem",
          lg: "3rem",
          xl: "0",
        },
      },
    }}
  >
    <Box
      sx={{
        position: "absolute",
        borderRadius: "34.93px",
        // width: "460px",
        width: {
          xs: "100%",
          sm: "320px",
          md: "320px",
          lg: "360px",
          xl: "400px",
        },
        // height: "590px",
        height: {
          xs: "100%",
          sm: "450px",
          md: "450px",
          lg: "490px",
          // xl: "590px",
          xl: "520px",
        },
        top: "-1px",
        left: "-1px",
        right: "-1px",
        padding: "3px",
        zIndex: "20",
        overflow: "hidden",
        background:
          "linear-gradient(142.14deg, #C892FF 2.97%, rgba(0, 0, 0, 0) 51.41%)",
        borderImageSlice: "1",
        mixBlendMode: "overlay",
        WebkitMask:
          "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
        boxShadow: "0px 4.66px 4.66px 0px #00000040",
      }}
    />
    <Box
      sx={{
        position: "relative",
        zIndex: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", // Center content horizontally
        // height: "100%",
        fontFamily: "Inter important",
        fontWeight: 700,
        gap: "1rem", // Add gap between elements for spacing
      }}
    >
      <img
        src={DialogImage}
        alt="dialog"
        style={
          {
            // width: "50%",
            // height: "auto",
          }
        }
      />
      <DialogTitle
        sx={{
          textAlign: "center", // Center text within the dialog title
          width: "100%", // Ensures full width for text alignment
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            // fontSize: "30px",
            fontWeight: 300,
            lineHeight: "33.82px",
            letterSpacing: "-0.07em",
            textAlign: "center",
            color: "#F4F4F4",
            padding: "0",
            fontSize: {
              xs: "28px",
              sm: "24px",
              md: "24px",
              lg: "30px",
              xl: "30px",
            },
          }}
        >
          Welcome to Evangel
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div id="recaptcha-container"></div> {/* Add reCAPTCHA container here */}
        <Typography
          sx={{
            // marginBottom: "14px",
            fontFamily: "Inter",
            fontSize: "11.27px",
            fontWeight: "400",
            lineHeight: "16.91px",
            color: "#FFDBFF",
          }}
        >
          Enter your Mobile number
        </Typography>
        {/* <!-- Make sure you have this in your HTML or component --> */}


        <TextField
          placeholder="Mobile number"
          variant="outlined"
          fullWidth
          value={phone}
          // onChange={handlePhoneNumberChange}
          onChange={(e) => setPhone(e.target.value)}
          sx={{
            display: "flex",
            height: "40px", // Increased height for better spacing
            padding: "9.865px 11.274px",
            justifyContent: "center",
            alignItems: "center",
            gap: "7.046px",
            borderRadius: "26.036px",
            backgroundColor: "#FFF",
            alignSelf: "stretch",
            "& .MuiInputBase-root": {
              padding: "0", // Removes default padding from TextField input
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent", // Removes the default border
              },
              "&:hover fieldset": {
                borderColor: "transparent", // Removes hover border
              },
              "&.Mui-focused fieldset": {
                borderColor: "transparent", // Removes focus border
              },
            },
          }}
          InputProps={{
            sx: {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%", // Ensures input field stretches to full height
            },
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              sx={{
                width: "11.1px",
                height: "11.1px",
                borderRadius: "3.17px",
                border: "0.79px solid #CCCCCC",
                padding: "0", // Remove padding around checkbox
                marginLeft: "16px",
                // marginTop: "10px",
              }}
              disableRipple // Removes the ripple effect
              checkedIcon={
                <span
                  style={{
                    display: "flex",
                    backgroundColor: "#CCCCCC",
                    borderRadius: "3.17px",
                    border: "0.79px solid #CCCCCC",
                    opacity: "0",
                  }}
                />
              }
              icon={
                <span
                  style={{
                    display: "flex",
                    borderRadius: "3.17px",
                    border: "0.79px solid #CCCCCC",
                    opacity: "1",
                  }}
                />
              }
            />
          }
          label={
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "12.87px",
                fontWeight: "500",
                lineHeight: "14.09px",
                // textAlign: "center",
                color: "#888888",
                // marginTop: "10px",
              }}
            >
              Remember me
            </Typography>
          }
          sx={{
            // marginTop: "1rem",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem", // Add spacing between the checkbox and label
          }}
        />

        <DialogActions
          sx={{
            width: "100%", // Ensures full width for button alignment
            flexDirection: "column", // Stack buttons vertically
            gap: "0.5rem", // Add space between buttons
            // marginTop: "2rem",
            marginTop: {
              xs: "16px",
              sm: "1rem",
              md: "1rem",
              lg: "0rem",
              xl: "1rem",
            },
            padding: "0px",
          }}
        >
          <Button
            onClick={handleSend}
            //  onClick={handlePhoneSignIn}
            sx={{
              width: "100%",
              height: "40px",
              padding: "9.865px 11.274px",
              gap: "10.77px",
              borderRadius: "26.04px",
              opacity: 1,
              boxShadow: "0px 10.77px 32.31px 0px #1B0C57",
              background:
                "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
              color: "#fff",
              fontSize: "13.02px",
              lineHeight: "15.75px",
              border: "1.08px solid transparent",
              textTransform: "inherit",
            }}
          // onClick={handleCodeOpen}
          >
            SignIn
          </Button>
          <Button
            onClick={() => login()}
            // onClick={handleClose}
            sx={{
              width: "100%",
              height: "40px",
              padding: "9.865px 11.274px",
              gap: "10.77px",
              borderRadius: "26.04px",
              opacity: 1,
              background: "#fff",
              color: "black",
              textTransform: "inherit",
              fontSize: "13.02px",
              lineHeight: "15.75px",
              "&:hover": {
                background: "#fff", // Maintain the same background color on hover
                color: "black", // Maintain the same text color on hover
                boxShadow: "none", // Remove any shadow changes on hover
              },
            }}
          >
            <img src={googleicons} alt="googleicons" />
            Sign In With Google
          </Button>


        </DialogActions>
      </DialogContent>
    </Box>
  </Dialog>
      {/* Second Dialog for Code Verification */}
      <Dialog
    fullScreen={false}
    open={code} // Correctly using the `code` state to control visibility
    onClose={handleCloseCode} // Correct handler for closing the dialog

    sx={{
      "& .MuiDialog-paper": {
        // width: "457px",
        width: {
          xs: "90%",
          sm: "320px",
          md: "320px",
          lg: "360px",
          xl: "400px",
        },
        height: {
          xs: "60%",
          sm: "450px",
          md: "450px",
          lg: "490px",
          xl: "520px",
        },
        // height: "600.72px",
        borderRadius: "34.93px",
        borderWidth: "2.33px 0px 0px 0px",
        // borderStyle: "solid",
        background:
          "linear-gradient(181.35deg, rgba(140, 115, 209, 0.5) 1.15%, rgba(179, 177, 200, 0.2) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
        boxShadow: "0px 4.66px 4.66px 0px #00000040",
        position: "relative", // Allows `top` and `left` to take effect
        backdropFilter: "blur(40.749961853027344px)", // Applies a blur effect
        WebkitMaskComposite: "xor",
        maskComposite: "exclude",
        opacity: 1, // Adjust opacity to ensure visibility
        overflow: "hidden",
        padding: {
          xs: "16px",
          sm: "1rem",
          md: "1rem",
          lg: "1rem",
          xl: "3rem",
        },
        top: {
          xs: "1%",
          sm: "1rem",
          md: "1rem",
          lg: "3rem",
          xl: "0",
        },
      },
    }}
  >
    <Box
     sx={{
      position: "absolute",
      borderRadius: "34.93px",
      // width: "460px",
      width: {
        xs: "100%",
        sm: "420px",
        md: "320px",
        lg: "360px",
        xl: "400px",
      },
      // height: "590px",
      height: {
        xs: "100%",
        sm: "450px",
        md: "450px",
        lg: "490px",
        xl: "520px",
      },
      top: "-1px",
      left: "-1px",
      right: "-1px",
      padding: "3px",
      zIndex: "20",
      overflow: "hidden",
      background:
        "linear-gradient(142.14deg, #C892FF 2.97%, rgba(0, 0, 0, 0) 51.41%)",
      borderImageSlice: "1",
      mixBlendMode: "overlay",
      WebkitMask:
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      WebkitMaskComposite: "xor",
      maskComposite: "exclude",
      boxShadow: "0px 4.66px 4.66px 0px #00000040",
    }}
    />
    <Box
      sx={{
        position: "relative",
        zIndex: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center", // Center content horizontally
        height: "100%",
        fontFamily: "Inter important",
        fontWeight: 700,
        gap: "1rem", // Add gap between elements for spacing
      }}
    >
      <img
         src={DialogImage} // Replace with actual image path
        alt="dialog"
        style={{
          width: "50%",
          height: "auto",
        }}
      />
      <DialogTitle
        sx={{
          textAlign: "center", // Center text within the dialog title
          width: "100%", // Ensures full width for text alignment
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
          
            fontSize: {
              xs: "29px",
              sm: "29pxx",
              md: "29px",
              lg: "30px",
              xl: "30px",
            },
            fontWeight: 300,
            lineHeight: "33.82px",
            letterSpacing: "-0.07em",
            textAlign: "center",
            color: "#F4F4F4",
            
          }}
        >
          Welcome to Evangel
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            marginBottom: "14px",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: "29px",
            color: "#FFF",
            textAlign: "center",
            padding:0,
          }}
        >
          Enter Verification Code
        </Typography>

        {/* Verification Code Input Fields */}
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          {verificationCode.map((code, index) => (
            <input
              // key={index}
              // id={`otp-input-${index}`}
              // value={code}
              // onChange={(e) => handleInputChange(index, e.target.value)}
              // onChange={(e) => setOtp(e.target.value)}
              key={index}
        //    ref={el => (inputRefs.current[index] = el)}
        // ref={el => (inputRefs.current[index] = el)}

        //       id={`otp-input-${index}`}
        //       value={otp[index]}
        //       onChange={(e) => handleInputChange(index, e)}.

        ref={el => (inputRefs.current[index] = el)} // Attach inputRefs
        value={otp[index]}
        onChange={(e) => handleInputChange(index, e)} // Handle input change

              type="text"
              maxLength={1}
              style={{
                width: "35px",
                height: "42px",
                // padding: "8px 0px",
                textAlign: "center",
                fontSize: "16px",
                border: "none",
                borderBottom: "1px solid rgba(255, 255, 255, 0.47)",
                // boxShadow: "0px 30px 30px 0px rgba(0, 0, 0, 0.15)",
                outline: "none",
                backgroundColor: "transparent",
                color: "#FFF",
                display: "flex",
                alignItems: "center",
                // backdropFilter: "blur(30px)",
              }}
            />
          ))}
        </Box>

        {/* Countdown Timer */}
        <Typography
          onClick={resendTimer === 0 ? handleResendCode : null} // Allow click only when timer is 0
          sx={{
            textAlign: "center",
            fontFamily: "Inter",
            fontSize: "14px",
            color:
              resendTimer === 0
                ? "rgba(253, 145, 255, 1)"
                : "rgba(253, 145, 255, 1)", // Change color to pink when clickable
            cursor: resendTimer === 0 ? "pointer" : "default", // Change cursor when clickable
            "&:hover": {
              textDecoration: resendTimer === 0 ? "none" : "none", // Add underline on hover if clickable
            },
          }}
        >
          {resendTimer > 0
            ? `Resend code in ${resendTimer} s`
            : "Resend OTP"}
        </Typography>

        <DialogActions
          sx={{
            width: "100%", // Ensures full width for button alignment
            flexDirection: "column", // Stack buttons vertically
            gap: "0.5rem", // Add space between buttons
            // marginTop: "2rem",
            marginTop: {
              xs: "2rem",
              sm: "1rem",
              md: "0",
              lg: "2rem",
              xl: "0",
            },
            padding: "0px",
          }}
          onClick={verifyOtp}
        >
          <Button
            // // onClick={handleCodeOpen}
            onClick={verifyOtp}
            sx={{
              width: "100%",
              height: "40px",
              padding: "9.865px 11.274px",
              gap: "10.77px",
              borderRadius: "26.04px",
              opacity: 1,
              boxShadow: "0px 10.77px 32.31px 0px #1B0C57",
              background:
                "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
              color: "#fff",
              fontSize: "13.02px",
              lineHeight: "15.75px",
              border: "1.08px solid transparent",
              textTransform: "inherit",
              backdropFilter: "blur(5.385599613189697px)",
            }}
          >
            Verify
          </Button>
        </DialogActions>
      </DialogContent>
    </Box>
  </Dialog>
    </React.Fragment>
  );
};

export default AuthDialog;
