import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Iphones from "../images/iPhones.png";

import playButton from "../images/Vector (1).png";

const card = () => {
  return (
    <div
      style={{
        position: "relative",
        paddingTop: "3rem",
        paddingBottom: "5rem",
      }}
    >
      {/* Blurred container */}
      <Grid classes="Banner_button_gradient"></Grid>

      {/* <Button
      sx={{
        display: {
          xs: "none",
          sm: "flex",
          md: "flex",
          lg: "flex",
          xl: "flex",
        },
      }}
        variant="contained1"
 
      >
        <img
          src={playButton}
          alt="playButton"
          style={{ marginRight: "10px" }}
        />
        Watch
      </Button>

      <Button
      sx={{
        display: {
          xs: "flex",
          sm: "none",
          md: "none",
          lg: "none",
          xl: "none",
        },
      }}
        variant="contained2"
 
      >
        <img
          src={playButton}
          alt="playButton"
          style={{ marginRight: "10px" }}
        />
        Watch
      </Button>
                                      */}



      <Grid classes="content_gradient_sub"></Grid>

      <Grid classes="card_gadient_1"></Grid>
      <Grid classes="card_gadient_2"></Grid>

      {/* <Grid classes="card_gadient_3"></Grid> */}
      {/* new added gradient above  */}
      {/* <Grid classes="card_gadient_4"></Grid> */}

      <Container>
        <Typography variant="h4">Don't Miss Latest Gospel &</Typography>
        <Typography variant="h4">Prophecies to Pray</Typography>
        <Box>
          <Typography variant="h5">
            The Biggest Edification platform for end time Generation
          </Typography>
        </Box>

        <br />
        <Grid
          container
          spacing={{ xs: 6, md: 6, sm: 2 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item md={3} sm={4} xs={4} xl={3} lg={3}>
            <Grid
              container
              direction="column"
              alignItems="flex-end"
              spacing={{ xs: 6, md: 8, sm: 6 }}
            >
              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    height: "346px",
                    borderRadius: "38px",
                    position: "relative",
                    background:
                      "linear-gradient(181.35deg, rgba(80, 76, 129, 0.5) 1.15%, rgba(179, 177, 200, 0.216098) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
                    WebkitMaskComposite: "xor",
                    maskComposite: "exclude",
                    width: {
                      xs: "100%",
                      sm: "80%",
                      md: 280,
                      lg: "321px",
                      xl: "321px",
                    },
                    marginRight: {
                      md: "40px",
                    },
                    "@media (min-width: 889px) and (max-width: 1000px)": {
                      width: "216px",
                      marginRight: "102px",
                      height: "350px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      borderRadius: "40px",
                      height: "100%",
                      top: "-2px",
                      left: "-2px",
                      right: "-2px",
                      padding: "2px",
                      zIndex: "20",
                      overflow: "hidden",
                      background:
                        "linear-gradient(181.1deg, #FFFFFF 0.22%, rgba(33, 13, 54, 0.26) 99.56%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                      boxShadow: '-0.79px 0.79px 0.79px -1.57px rgba(255, 255, 255, 0.35) inset',

                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 30,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                      fontFamily: "Inter important",
                      fontWeight: 700,
                    }}
                  >
                    <Typography variant="h6"></Typography>
                    <Typography variant="h7">
                    The gospel is the power of God for salvation (Rom 1:16)
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item>
                <Box
                  sx={{
                    margin: "auto",

                    height: "346px",
                    borderRadius: "38px",
                    position: "relative",
                    background:
                      "linear-gradient(181.35deg, rgba(80, 76, 129, 0.5) 1.15%, rgba(179, 177, 200, 0.216098) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
                    WebkitMaskComposite: "xor",
                    maskComposite: "exclude",
                    width: {
                      xs: "100%",
                      sm: "80%",
                      md: 280,
                      lg: "321px",
                      xl: "321px",
                    },
                    marginRight: {
                      // xs:"100%",
                      // sm:"100%",
                      md: "40px",
                      // lg:'auto',
                      // xl:'auto',
                    },
                    "@media (min-width: 889px) and (max-width: 1000px)": {
                      width: "216px",
                      marginRight: "102px",
                      height: "350px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      borderRadius: "40px",
                      // width: "325px",

                      height: "350px",
                      top: "-2px",
                      left: "-2px",
                      right: "-2px",
                      padding: "2px",
                      zIndex: "20",
                      overflow: "hidden",
                      background:
                        "linear-gradient(181.1deg, #FFFFFF 0.22%, rgba(33, 13, 54, 0.26) 99.56%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 30,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center", // Center vertically
                      // alignItems: "center", // Center horizontally
                      height: "100%", // Make sure the container box takes up full height
                      fontFamily: "Inter important",
                      fontWeight: 700,
                      // textAlign: "center",
                    }}
                  >
                    <Typography variant="h6"></Typography>
                    <Typography variant="h7">
                    And this gospel of the kingdom will be preached in all the world as a witness to all the nations, and then the end will come. (Mat 24:14)
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6} sm={4} xs={12} xl={6}>
            <Grid item>
              <Box
                sx={{
                  margin: "auto",

                  //  width: "470px",
                  width: {
                    xs: 376,
                    sm: 354,
                    md: 380,
                    lg: "470px",
                    xl: "470px",
                  },
                  height: "764px",
                  borderRadius: "30px",
                  position: "relative",
                  background:
                    "linear-gradient(181.35deg, rgba(80, 76, 129, 0.5) 1.15%, rgba(179, 177, 200, 0.216098) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
                  WebkitMaskComposite: "xor",
                  maskComposite: "exclude",

                  "@media (min-width: 320px) and (max-width: 374px)": {
                    width: "300px",
                  },
                  "@media (min-width: 600px) and (max-width: 730px)": {
                    width: "332px",
                  },
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    borderRadius: "32px",
                    //  width: "474px",
                    width: {
                      xs: 380,
                      sm: 358,
                      md: 384,
                      lg: "474px",
                      xl: "474px",
                    },

                    height: "764px",
                    // top: "-2px",
                    top: {
                      xs: "-2x",
                      sm: "-2px",
                      md: "-2px",
                      lg: "-2px",
                      xl: "-2px",
                    },
                    left: "-2px",
                    right: "-2px",
                    padding: "2px",
                    zIndex: "20",
                    overflow: "hidden",
                    background:
                      "linear-gradient(181.1deg, #FFFFFF 0.22%, rgba(33, 13, 54, 0.26) 99.56%)",
                    borderImageSlice: "1",
                    mixBlendMode: "overlay",
                    WebkitMask:
                      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                    WebkitMaskComposite: "xor",
                    maskComposite: "exclude",
                    "@media (min-width: 320px) and (max-width: 374px)": {
                      width: "304px",
                    },
                    "@media (min-width: 600px) and (max-width: 730px)": {
                      width: "335px",
                    },
                  }}
                />
                <Box
                  sx={{
                    position: "relative",
                    // zIndex: 30,
                    paddingTop: "2.1rem",
                    paddingLeft: "2.5rem",
                    paddingRight: "2.5rem",
                    display: "flex",
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    height: "100%", // Make sure the container box takes up full height
                  }}
                >
                  <img
                    src={Iphones}
                    alt="Iphones"
                    style={{
                      alignItems: "center",
                      "@media (min-width: 320px) and (max-width: 374px)": {
                        width: "20px",
                        display: "flex",
                        justifyContent: "center", // Center horizontally
                        alignItems: "center", // Center vertically
                        height: "100%", // Make sure the container box takes up full height
                      },
                      "@media (min-width: 600px) and (max-width: 730px)": {
                        minWidth: "300px",
                        height: "100%",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            md={3}
            sm={4}
            xs={12}
            xl={3}
            // sx={{ marginLeft: "-69px" }}
          >
            <Grid
              container
              direction="column"
              alignItems="flex-start"
              spacing={{ xs: 6, md: 8, sm: 6 }}
            >
              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    // width: "321px",
                    width: {
                      xs: "100%",
                      sm: "80%",
                      md: 280,
                      lg: "321px",
                      xl: "321px",
                    },
                    marginLeft: {
                      // xs:"100%",
                      // sm:"100%",
                      md: "-70px",
                      // lg:'auto',
                      // xl:'auto',
                    },
                    height: "346px",
                    borderRadius: "38px",
                    position: "relative",
                    background:
                      "linear-gradient(181.35deg, rgba(80, 76, 129, 0.5) 1.15%, rgba(179, 177, 200, 0.216098) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
                    WebkitMaskComposite: "xor",
                    maskComposite: "exclude",
                    "@media (min-width: 889px) and (max-width: 1000px)": {
                      width: "216px",
                      marginLeft: "-40px",
                      height: "350px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      borderRadius: "40px",
                      // width: "325px",
                      width: {
                        // xs:"100%",
                        // sm:"100%",
                        md: 284,
                        lg: "325px",
                        xl: "325px",
                      },
                      height: "350px",
                      top: "-2px",
                      left: "-2px",
                      right: "-2px",
                      padding: "2px",
                      zIndex: "20",
                      overflow: "hidden",
                      background:
                        "linear-gradient(181.1deg, #FFFFFF 0.22%, rgba(33, 13, 54, 0.26) 99.56%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                      "@media (min-width: 889px) and (max-width: 1000px)": {
                        width: "216px",
                        marginLeft: "2px",
                        height: "350px",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 30,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center", // Center vertically

                      height: "100%", // Make sure the container box takes up full height
                      fontFamily: "Inter important",
                      fontWeight: 700,
                    }}
                  >
                    <Typography variant="h6"></Typography>
                    <Typography variant="h7">
                    And He said to them, “Go into all the world and preach the gospel to every creature. (Mark 16:15)
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item>
                <Box
                  sx={{
                    margin: "auto",
                    // width: "321px",
                    width: {
                      xs: "100%",
                      sm: "80%",
                      md: 280,
                      lg: "321px",
                      xl: "321px",
                    },
                    marginLeft: {
                      md: "-70px",
                    },
                    height: "346px",
                    borderRadius: "38px",
                    position: "relative",
                    background:
                      "linear-gradient(181.35deg, rgba(80, 76, 129, 0.5) 1.15%, rgba(179, 177, 200, 0.216098) 56.66%, rgba(255, 255, 255, 0) 98.91%)",
                    WebkitMaskComposite: "xor",
                    maskComposite: "exclude",
                    "@media (min-width: 889px) and (max-width: 1000px)": {
                      width: "216px",
                      marginLeft: "-40px",
                      height: "350px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      borderRadius: "40px",
                      // width: "325px",
                      width: {
                        md: 284,
                        lg: "325px",
                        xl: "325px",
                      },
                      height: "350px",
                      top: "-2px",
                      left: "-2px",
                      right: "-2px",
                      padding: "2px",
                      zIndex: "20",
                      overflow: "hidden",

                      background:
                        "linear-gradient(143.57deg, #FFFFFF 2.42%, rgba(33, 13, 54, 0.26) 78.77%)",
                      borderImageSlice: "1",
                      mixBlendMode: "overlay",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      maskComposite: "exclude",
                      "@media (min-width: 889px) and (max-width: 1000px)": {
                        width: "216px",
                        marginLeft: "2px",
                        height: "350px",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      zIndex: 30,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center", // Center vertically
                      // alignItems: "center", // Center horizontally
                      height: "100%", // Make sure the container box takes up full height
                      fontFamily: "Inter important",
                      fontWeight: 700,
                      // textAlign: "center",
                    }}
                  >
                    <Typography variant="h6"></Typography>
                    <Typography variant="h7">
                    Therefore go and make disciples of all nations, baptizing them in the name of the Father and of the Son and of the Holy Spirit, and teaching them to obey everything I have commanded you. And surely I am with you always, to the very end of the age. (Mat 28:19-20)
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default card;
