import axios from "axios";
import { apiEndpoint } from "../../components/data/config";
import axiosInstance from '../../axiosInstance';

export const view_Series = async () => {
  const config = {
    method: "get",
    url: `${apiEndpoint}/api/app_home_viewall/3`,
    headers: {
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};









// import axiosInstance from '../../axiosInstance';
// import { apiEndpoint } from "../../components/data/config";

// export const view_movies = async () => {
//   const config = {
//     method: 'get',
//     url: `${apiEndpoint}/api/app_home_viewall/3`,
//   };
//   return axiosInstance(config); // Use axiosInstance instead of axios
// };