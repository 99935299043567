// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyBj2qY9nZRjUCgkp6CtRmo0XaQbI61m5OQ",
//   authDomain: "web-application-authentication.firebaseapp.com",
//   projectId: "web-application-authentication",
//   storageBucket: "web-application-authentication.appspot.com",
//   messagingSenderId: "559142951287",
//   appId: "1:559142951287:web:6e07d63ebc50e339396972"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);



// firebaseConfig.js
// import { initializeApp } from 'firebase/app';
// import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: "AIzaSyBj2qY9nZRjUCgkp6CtRmo0XaQbI61m5OQ",
//     authDomain: "web-application-authentication.firebaseapp.com",
//     projectId: "web-application-authentication",
//     storageBucket: "web-application-authentication.appspot.com",
//     messagingSenderId: "559142951287",
//     appId: "1:559142951287:web:6e07d63ebc50e339396972"
// };

// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);

// export { auth };




// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";

// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyC2xICWsJSrnXs9sWPntvie6DmfkPz5TDY",
//   authDomain: "project-a017d.firebaseapp.com",
//   projectId: "project-a017d",
//   storageBucket: "project-a017d.appspot.com",
//   messagingSenderId: "192086996842",
//   appId: "1:192086996842:web:0357af89ffa7742b9d9713"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const auth= getAuth(app)




// // Ensure this in your setup file
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";

// const firebaseConfig = {
//   apiKey: "AIzaSyC2xICWsJSrnXs9sWPntvie6DmfkPz5TDY",
//   authDomain: "project-a017d.firebaseapp.com",
//   projectId: "project-a017d",
//   storageBucket: "project-a017d.appspot.com",
//   messagingSenderId: "192086996842",
//   appId: "1:192086996842:web:0357af89ffa7742b9d9713"
// };

// const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app);

// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyASbqMEMDR_SEXTEzPoWaUwcLUm0Q9QYoU",
  authDomain: "evangel-stream.firebaseapp.com",
  projectId: "evangel-stream",
  storageBucket: "evangel-stream.appspot.com",
  messagingSenderId: "837911234958",
  appId: "1:837911234958:web:ff1674d0d2391796fe3cdf",
  measurementId: "G-RZ118XP06Z",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Optionally initialize Firebase Analytics
if (typeof window !== "undefined") {
  getAnalytics(app);
}

// Export the Firebase Auth service to be used across the app
export const auth = getAuth(app);
